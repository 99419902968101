import { useContext } from 'react'

import { useDownloadFileMutation } from '../../../../hooks'
import TransactionsExportDocumentIcon from '@images/icons/transaction-export-icon.svg?react'
import fileDownload from 'js-file-download'
import moment from 'moment'

import { endpoints, useGetMerchantQuery } from 'mmfintech-backend-api'
import { GlobalContext, fixDateOnly, tr } from 'mmfintech-commons'
import { StatementPeriodTypeEnum } from 'mmfintech-commons-types'
import { Button, ErrorDisplay } from 'mmfintech-portal-commons'

import { StatementHistoryWrapper } from './cardSettingsModals.styled'

interface StatementHistoryProps {
  cardDetails?: any
}

const StatementHistory = ({ cardDetails }: StatementHistoryProps) => {
  const { modalHide } = useContext(GlobalContext)

  const { data: merchant } = useGetMerchantQuery()
  const { createdOn } = merchant || {}

  const [handleDownload, { isLoading: balanceStatementFetching, error: balanceStatementError }] =
    useDownloadFileMutation({
      endpointName: 'balancestatementPDF/download'
    })

  const lastMonthDate = () => {
    const date = new Date()
    date.setMonth(date.getMonth() - 1)
    return date
  }

  const lastThreeMonthsDate = () => {
    const date = new Date()
    date.setMonth(date.getMonth() - 3)
    return date
  }

  const handleSubmit = async (startPeriod: Date) => {
    if (balanceStatementFetching) return
    const data = {
      accountId: cardDetails.accountId,
      period: {
        periodType: StatementPeriodTypeEnum.CUSTOM,
        startDate: fixDateOnly(startPeriod),
        endDate: fixDateOnly(Date.now())
      }
    }

    try {
      const res = await handleDownload({ url: endpoints.transactions.getStatementPDF(), method: 'POST', body: data })
      if (res) {
        fileDownload(res, 'balance-statement.pdf', 'application/pdf')
        modalHide()
      }
    } catch (_err) {}
  }

  return (
    <StatementHistoryWrapper>
      <span className='title'>{tr('FRONTEND.CARDS.SETTINGS.MODAL.STATEMENT_HISTORY.TITLE', 'Statement history')}</span>
      <div
        className='statement-history-element'
        onClick={() => handleSubmit(new Date(createdOn || '2022-01-01T00:00:00Z'))}>
        <div className='statement-history-period'>
          <div className='statement-history-title'>
            {tr('FRONTEND.CARDS.SETTINGS.STATEMENT_HISTORY.MODAL.ALL_TIME', 'All time')}
          </div>
          <div className='statement-history-period-value'>
            {moment(new Date(createdOn || '2022-01-01T00:00:00Z')).format('DD/MM/YYYY')}
            {' - '}
            {tr('FRONTEND.CARDS.SETTINGS.STATEMENT_HISTORY.MODAL.PRESENT', 'Present')}
          </div>
        </div>
        <TransactionsExportDocumentIcon />
      </div>

      <div className='statement-history-element' onClick={() => handleSubmit(lastThreeMonthsDate())}>
        <div className='statement-history-period'>
          <div className='statement-history-title'>
            {tr('FRONTEND.CARDS.SETTINGS.STATEMENT_HISTORY.MODAL.THREE_MONTHS', 'Last 3 full months')}
          </div>
          <div className='statement-history-period-value'>
            {moment(lastThreeMonthsDate()).format('DD/MM/YYYY')}
            {' - '}
            {moment(Date.now()).format('DD/MM/YYYY')}
          </div>
        </div>
        <TransactionsExportDocumentIcon />
      </div>

      <div className='statement-history-element' onClick={() => handleSubmit(lastMonthDate())}>
        <div className='statement-history-period'>
          <div className='statement-history-title'>
            {tr('FRONTEND.CARDS.SETTINGS.STATEMENT_HISTORY.MODAL.THIS_MONTH', 'This month only')}
          </div>
          <div className='statement-history-period-value'>
            {moment(lastMonthDate()).format('DD/MM/YYYY')}
            {' - '}
            {moment(Date.now()).format('DD/MM/YYYY')}
          </div>
        </div>
        <TransactionsExportDocumentIcon />
      </div>

      <Button text={tr('FRONTEND.BUTTONS.CANCEL', 'Close')} className='close' onClick={() => modalHide()} />

      <ErrorDisplay error={balanceStatementError} />
    </StatementHistoryWrapper>
  )
}

export default StatementHistory
