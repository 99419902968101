import styled from 'styled-components'

export const CoreTabsWrapper = styled.div`
  display: inline-flex;
  position: relative;
  height: 5.6rem;
  overflow: hidden;
  cursor: pointer;
  user-select: none;
  background-color: transparent; // or white
  font-weight: 600;
  font-size: 1.6rem;
  line-height: 2.08rem;

  &.disabled {
    opacity: 0.1;
    cursor: auto;
  }

  p {
    pointer-events: none;
  }

  .tab-item {
    color: #4d5055;
    padding: 6px 16px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      color: #2b313a;
    }
  }

  .selected {
    z-index: 4;
    color: #ef4444;
  }

  .on-first-load {
    z-index: 1;
    width: '100%';
    border-bottom: solid 2px #ef4444;
  }

  .tab-item.selected {
    z-index: 4;
    color: #ef4444;
  }

  .animated-background {
    position: absolute;
    bottom: 0;
    z-index: 1;
    border-bottom: solid 2px #ef4444;
    transition: all 0.3s ease-in-out;
    transform: translateX(-50%);
  }
`
