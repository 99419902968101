import styled from 'styled-components'

export const RedeemVoucherModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  gap: 10.2rem;
  padding: 4rem 4rem 2rem;

  .redeem-voucher-modal-content {
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
    .redeem-voucher-title {
      font-size: 2.4rem;
      font-weight: 600;
      text-align: center;
      margin-bottom: 3rem;
    }
  }
  .redeem-voucher-modal-actions {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
`
