import { useMemo } from 'react'
import Skeleton from 'react-loading-skeleton'

import styled from 'styled-components'

import { useGetAggregatedBalanceTotalQuery } from 'mmfintech-backend-api'
import { formatMoney, monthBackDate, todayDate } from 'mmfintech-commons'

import { devices } from '../constants'

import totalDepositsIcon from '../images/dashboard-deposits-sum.png'
import totalExpensesIcon from '../images/dashboard-expense-sum.png'

export function TotalSpendRow() {
  const dateFrom = useMemo(() => monthBackDate().toISOString(), [])
  const dateTo = useMemo(() => todayDate().toISOString(), [])

  const { data: totalSpendRowData, isFetching } = useGetAggregatedBalanceTotalQuery({ dateFrom, dateTo })
  const { currency, totalDeposits, totalExpenses } = totalSpendRowData || {}

  return (
    <TotalSpendWrapper className='totalsWrapper'>
      <div className='spend-item'>
        <img src={totalDepositsIcon} alt='total-deposits-icon' />
        <div className='spend-item-content'>
          <span className='spend-item-label'>Deposits</span>
          <span className='spend-item-amount'>
            {isFetching ? <Skeleton /> : formatMoney(totalDeposits || 0, currency || 'EUR')}
          </span>
        </div>
      </div>
      <div className='spend-item'>
        <img src={totalExpensesIcon} alt='total-expenses-icon' />
        <div className='spend-item-content expenses'>
          <span className='spend-item-label'>Expenses</span>
          <span className='spend-item-amount'>
            {isFetching ? <Skeleton /> : formatMoney(totalExpenses || 0, currency || 'EUR')}
          </span>
        </div>
      </div>
    </TotalSpendWrapper>
  )
}

const TotalSpendWrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  gap: 1rem;
  @media ${devices.xl} {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1rem;
  }

  .spend-item {
    display: flex;
    .spend-item-content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-left: 1rem;

      .spend-item-label {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }

      .spend-item-amount {
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }
    }
    @media (max-width: 767px) {
      padding: 0 1rem;
      border: 1px solid rgba(233, 233, 233, 1);
      border-radius: 1.5rem;
      img {
        width: 5rem;
        height: 5rem;
      }
      .spend-item-content {
        .spend-item-label {
          font-size: 1.2rem;
        }
        .spend-item-amount {
          font-size: 1.6rem;
        }
      }
    }
  }

  .expenses {
    margin-left: 2rem;
  }
`
