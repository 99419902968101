import { useContext, useEffect, useState } from 'react'

import { MerchantAccountTypeEnum } from 'mmfintech-commons-types'
import { useGetMerchantQuery } from 'mmfintech-backend-api'
import { GlobalContext, tr } from 'mmfintech-commons'

import ToastSuccess from '@images/icons/toast-success.svg?react'
import WelcomeIcon2 from '@images/logo.svg?react'

import { Success2FaStyled, WelcomeContent2Styled } from './WelcomeContent2.styled'

function isSameDayAndMonth(createdOn) {
  const createdOnDate = new Date(createdOn)

  const today = new Date()

  return createdOnDate.getMonth() === today.getMonth() && createdOnDate.getDate() === today.getDate()
}

type TWelcomeContent2Props = {
  isSuccess2Fa: boolean
}

export const WelcomeContent2 = ({ isSuccess2Fa }: TWelcomeContent2Props) => {
  const { data: merchant } = useGetMerchantQuery()
  const { accountType, createdOn } = merchant || {}
  const { modalHide } = useContext(GlobalContext)

  if (accountType !== MerchantAccountTypeEnum.PROSPECT || !isSameDayAndMonth(createdOn)) {
    modalHide()
  }

  return (
    <WelcomeContent2Styled>
      <div className='logo'>
        <WelcomeIcon2 />
      </div>
      <div className='title'>
        {isSuccess2Fa
          ? tr('FRONTEND.WELCOME.MODAL.STEP_2.TITLE_1', 'Great! Your account is now well protected!')
          : tr('FRONTEND.WELCOME.MODAL.STEP_2.TITLE_2', 'We are happy that you registered with us!')}
      </div>
      <p className='description'>
        {tr(
          'FRONTEND.WELCOME.MODAL.STEP_2.DESCRIPTION_1',
          'The final step to easily start depositing, sending and exchanging money, is to complete the onboarding!'
        )}
      </p>
    </WelcomeContent2Styled>
  )
}

export const Success2Fa = ({ isSuccess2Fa }: { isSuccess2Fa: boolean }) => {
  const [showSuccess, setShowSuccess] = useState(false)

  useEffect(() => {
    let timer

    if (isSuccess2Fa) {
      setShowSuccess(true)

      timer = setTimeout(() => {
        setShowSuccess(false)
      }, 2000)
    } else {
      setShowSuccess(false)
    }

    return () => {
      if (timer) {
        clearTimeout(timer)
      }
    }
  }, [isSuccess2Fa])

  if (isSuccess2Fa)
    return (
      <Success2FaStyled>
        {showSuccess && (
          <div className='success-2fa-content'>
            <ToastSuccess />
            <div className='success-2fa-text'>
              <span className='title-success'>
                {tr('FRONTEND.WELCOME.MODAL.STEP_2.SUCCESS_1', 'Two-factor authentication method changed!')}
              </span>
            </div>
          </div>
        )}
      </Success2FaStyled>
    )
}
