import { useContext } from 'react'

import BalanceStatementModal from '../BalanceStatementModal'
import TransactionsExportModal from '../TransactionsExportModal'
import { Button } from 'mmfintech-portal-commons'
import { TransactionExportButtons } from '../styled/transactions.styled'

import { useMerchantAccounts } from 'mmfintech-backend-api'
import { GlobalContext, isValidArray, tr } from 'mmfintech-commons'

import TransactionsExportDocumentIcon from '../../../images/icons/transactions-export-document.svg?react'

export interface TransactionsExportButtonsInterface {
  accountId: number
  from: Date
  to: Date
}

export function TransactionsExportButtons({ accountId, from, to }: TransactionsExportButtonsInterface) {
  const { modalShow } = useContext(GlobalContext)
  const { accounts: paymentAccounts } = useMerchantAccounts()

  const getAccount = () => {
    if (isValidArray(paymentAccounts)) {
      return paymentAccounts.find(account => account.id === accountId)
    }
  }

  const handleBalanceStatementClick = () => {
    modalShow({
      options: { size: 'auto', closeOnClickOutside: false, closeOnEscape: false },
      content: <BalanceStatementModal account={getAccount()} accounts={paymentAccounts} dateFrom={from} dateTo={to} />
    })
  }

  const handleExportClick = () => {
    modalShow({
      options: { size: 'auto', closeOnClickOutside: false, closeOnEscape: false },
      content: <TransactionsExportModal account={getAccount()} accounts={paymentAccounts} dateFrom={from} dateTo={to} />
    })
  }

  return (
    <TransactionExportButtons>
      <Button
        color='round-primary'
        icon={<TransactionsExportDocumentIcon />}
        text={tr('FRONTEND.TRANSACTIONS.BALANCE_STATEMENT_BUTTON', 'Balance Statement')}
        onClick={handleBalanceStatementClick}
        data-test='button-balance-statement'
      />
      <Button
        color='round-primary'
        icon={<TransactionsExportDocumentIcon />}
        text={tr('FRONTEND.TRANSACTIONS.EXPORT_CSV', 'Export CSV')}
        onClick={handleExportClick}
        data-test='button-export-csv'
      />
    </TransactionExportButtons>
  )
}
