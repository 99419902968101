import { fixDateOnly, monthBackDate, tr } from 'mmfintech-commons'

import SpendingLimitBarUser from '../../requests/SpendingLimitBarUser'
import TransactionsSection from '../elements/TransactionsSection'
import { AccountOverviewList } from '../../account/AccountOverviewList'
import { DashboardTitle, DashboardWrapper } from './DashboardStaff.styled'

export const DashboardStaff = () => (
  <>
    <DashboardTitle className='title'>{tr('FRONTEND.DASHBOARD.TITLE', 'Overview')}</DashboardTitle>

    <DashboardWrapper>
      <div className='dashboard-nav'>
        <SpendingLimitBarUser />
      </div>
      <AccountOverviewList />
      <TransactionsSection
        filter={{
          from: fixDateOnly(monthBackDate()),
          to: fixDateOnly(new Date()),
          page: 0,
          size: 5,
          sort: 'reqTime-desc,id-desc'
        }}
      />
    </DashboardWrapper>
  </>
)
