import { tr } from 'mmfintech-commons'

import HintIcon from '../../../images/icons/calendar.svg?react'
import { EmptyVoucherPageWrapper } from './emptyVouchevPage.styled'

export const EmptyVoucherPage = ({ active }) => {
  return (
    <EmptyVoucherPageWrapper>
      <HintIcon />
      {active ? (
        <p>{tr('FRONTEND.VOUCHERS.EMPTY_VOUCHER_PAGE_ACTIVE', `You don’t have any Active vouchers`)}</p>
      ) : (
        <p>{tr('FRONTEND.VOUCHERS.EMPTY_VOUCHER_PAGE_HISTORY', `You don’t have any History vouchers`)}</p>
      )}
    </EmptyVoucherPageWrapper>
  )
}
