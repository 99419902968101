import styled from 'styled-components'

import { tablePadding } from '../../../components/styled/table.styled'
import DownloadIcon from '../../../images/icons/invoice-download.svg'
import ImageOverdue from '../../../images/icons/invoice-overdue.svg'
import PreviewIcon from '../../../images/icons/invoice-preview.svg'

export const InvoiceListWrapper = styled.div`
  .overdue {
    color: #ee2b2b;
    padding-left: 2.2rem;
    //prettier-ignore
    background: url("${ImageOverdue}") 0 0 no-repeat;
  }

  .select-wrapper {
    display: inline-block;
  }

  .data-table-row {
    background-color: ${({ theme }) => theme.dataTable.body.backgroundColor};
    border-radius: ${({ theme }) => theme.containerBorderRadius};
    box-shadow: ${({ theme }) => theme.dataTable.body.dropShadow};
  }

  .invoice-viewed-cell {
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
    flex-flow: row nowrap;
    color: #939ea7;
    padding-bottom: 3rem;

    svg {
      margin-right: 0.8rem;
    }
  }

  .invoice-amount-cell {
    color: ${({ theme }) => theme.contentWrapper.textColor};
    font-weight: 600;
    text-align: right;
  }

  .invoice-status-cell {
    text-align: center;

    .invoice-status-wrap,
    .select-wrapper {
      width: 100%;
    }
  }

  .invoice-preview-cell {
    margin: 0 auto;

    .invoice-preview-button {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-flow: row nowrap;
      cursor: pointer;

      &.is-disabled {
        cursor: not-allowed !important;
      }
    }
  }

  .invoice-edit-cell {
    .button {
      font-size: 1.6rem;
      font-weight: normal;
      width: 100%;
    }
  }

  .invoice-send-cell {
    width: 4rem;
    text-align: center;
  }

  .invoice-hidden {
    display: none;
  }

  .invoices-pagination {
    padding: 0;
  }

  @media (min-width: 600px) {
    .invoice-viewed-cell {
      padding-bottom: 1rem;
    }

    .invoice-status-cell {
      //padding-top: 1rem;

      .invoice-status-wrap,
      .select-wrapper,
      .paid {
        width: 24rem;
      }

      .invoice-status-wrap,
      .paid {
        margin: 0 auto;
      }
    }

    .invoice-edit-cell {
      .button {
        max-width: 24rem;
        margin: 0 auto;
      }
    }
  }

  @media (min-width: 1200px) {
    flex-direction: column;

    .buttons {
      padding: 2.5rem 0 0;
    }

    .col-spacing {
      padding-left: 2.2rem;
    }

    .data-table-row {
      background: transparent;
      box-shadow: none;
      border-radius: 0;
      min-height: 6rem;

      .data-table-cell {
        font-size: 1.2rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.5rem;
      }

      &.editable {
        cursor: pointer;
      }
    }

    .invoice-viewed-cell {
      padding-bottom: 0;
      text-align: center;
      width: 4rem;

      svg {
        margin: 0 auto;
      }

      .invoice-viewed-text {
        display: none;
      }
    }

    .invoice-status-cell {
      .invoice-status-wrap,
      .select-wrapper,
      .paid {
        width: 12rem;
      }
    }

    .invoice-preview-cell {
      width: 4.5rem;

      .invoice-preview-text {
        display: none;
      }

      .invoice-preview-button {
        justify-content: center;
        width: auto;
      }
    }

    .invoice-edit-cell {
      display: none !important;
    }

    .invoice-hidden {
      display: table-cell;
    }

    .invoices-pagination {
      padding: 3rem 0 0;
    }
  }

  ${tablePadding}
`

export const StatusStatic = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding-left: 0;

  height: 3rem;
  width: 12rem;
  border-radius: 56px;

  font-family: inherit;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem;

  &.paid {
    background: rgba(68, 204, 0, 0.1);
    border: 1px solid #44cc00;
    color: #44cc00;
  }

  &.canceled {
    background: rgba(167, 179, 190, 0.1);
    border: 1px solid #a7b3be;
    color: #a7b3be;
  }
`

export const IconPreview = styled.span`
  height: 3.5rem;
  width: 3rem;
  cursor: pointer;
  display: inline-block;
  //prettier-ignore
  background: url("${PreviewIcon}") center center no-repeat;
`

export const IconDownload = styled.span`
  height: 3.5rem;
  width: 4rem;
  cursor: pointer;
  display: inline-block;
  //prettier-ignore
  background: url("${DownloadIcon}") center center no-repeat;
`
