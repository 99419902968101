import styled from 'styled-components'

export const PreselectedAmountWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 1.2rem;
  overflow-x: scroll;
  scrollbar-width: none;
  // padding: 0 3rem;

  .preselected-amount {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: fit-content;
    padding: 1rem 1.6rem;
    gap: 1rem;
    border-radius: 0.4rem;
    border: 1px solid #85a0ad4d;
    cursor: pointer;

    span {
      font-size: 1.4rem;
    }

    &:hover {
      background-color: #85a0ad4d;
    }

    &.selected {
      background-color: black;
      border-color: black;

      span {
        color: white;
      }
    }
  }
`
