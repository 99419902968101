import { useContext } from 'react'

import { cutString, formatDate, formatMoney, GlobalContext, tr } from 'mmfintech-commons'
import { isOwnerOrAdministrator, selectCurrentUserRole, useAppSelector } from 'mmfintech-backend-api'

import { Button, DataTable } from 'mmfintech-portal-commons'
import { RecipientDetailsModal } from '../recipients'

import { CustomerRoleEnum, ExpenseView } from 'mmfintech-commons-types'

import Checkmark from '../../images/icons/checkmark.svg?react'
import ClearIcon from '../../images/icons/x-mark.svg?react'

type RequestsTableRowProps = {
  transaction: ExpenseView
  handleApprove: (requestId: any, sourceAccountId: any) => void
  handleReject: (requestId: any) => void
}

const { Row, Cell } = DataTable

export function RequestTableRow({ transaction, handleApprove, handleReject }: RequestsTableRowProps) {
  const {
    requestId,
    username,
    description,
    createdOn,
    amount,
    currency,
    status,
    sourceAccountId,
    bankAccountOut,
    cryptoAddressOut,
    externalWalletAccountOut
  } = transaction || {}

  const { sidebarRightShow, sidebarRightHide }: any = useContext(GlobalContext)
  const customerRole = useAppSelector(selectCurrentUserRole)

  const handleDetails = () => {
    sidebarRightShow({
      options: { closeOnClickOutside: true, size: 'extra-large' },
      content: (
        <RecipientDetailsModal
          recipient={{ ...bankAccountOut, ...cryptoAddressOut, ...externalWalletAccountOut }}
          onClose={sidebarRightHide}
        />
      )
    })
  }
  return (
    <Row data-test='table-row'>
      <Cell className='no-border' data-test='name' caption='Name'>
        {username}
      </Cell>
      <Cell className='no-border' data-test='description' caption='Description'>
        {description ? cutString(description, 50) : 'N/A'}
      </Cell>
      <Cell className='no-border' data-test='date' caption='Date'>
        {formatDate(createdOn)}
      </Cell>
      <Cell className='no-border' data-test='amount' caption='Amount'>
        {formatMoney(amount, currency)}
      </Cell>
      <Cell className='small no-border' data-test='recipient' caption='Recipient'>
        {bankAccountOut.name ? cutString(bankAccountOut.name, 50) : 'N/A'}
      </Cell>
      <Cell className='recipient-wrapper no-border'>
        <Button
          type='button'
          color='round-secondary'
          className='recipient-details'
          data-test='recipient-details'
          text={tr('FRONTEND.USERS.EXPENSE_MANAGEMENT.REQUESTS.TABLE.BUTTONS.DETAILS', 'Details')}
          onClick={handleDetails}
        />
      </Cell>
      <Cell className='action-buttons no-border'>
        {status === 'PENDING' ? (
          <>
            {isOwnerOrAdministrator(customerRole) && (
              <Button
                className='approve'
                type='button'
                color='round-secondary'
                text={tr('FRONTEND.USERS.EXPENSE_MANAGEMENT.REQUESTS.TABLE.BUTTONS.APPROVE', 'Approve')}
                onClick={() => handleApprove(requestId, sourceAccountId)}
              />
            )}
            <Button
              className='reject'
              type='button'
              color='round-secondary'
              text={
                customerRole === CustomerRoleEnum.STAFF
                  ? tr('FRONTEND.USERS.EXPENSE_MANAGEMENT.REQUESTS.TABLE.BUTTONS.CANCEL', 'Cancel')
                  : tr('FRONTEND.USERS.EXPENSE_MANAGEMENT.REQUESTS.TABLE.BUTTONS.DECLINE', 'Decline')
              }
              onClick={() => handleReject(requestId)}
            />
          </>
        ) : (
          <div className='status'>
            <span className='icon'>{status !== 'DECLINED' ? <Checkmark /> : <ClearIcon />}</span>
            {status}
          </div>
        )}
      </Cell>
    </Row>
  )
}
