// noinspection DuplicatedCode
import { memo, useEffect, useState } from 'react'
import Select, { components } from 'react-select'

import cn from 'classnames'
import isEqual from 'lodash.isequal'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { getInvoiceStatusName, invoiceStatuses, prepareInvoiceStatusOptions } from 'mmfintech-backend-api'

function InvoiceStatus(props) {
  const { id, name, disabled, value, overdue, onChange, className, ...rest } = props

  const [selectedOption, setSelectedOption] = useState(null)
  const [translatedOptions, setTranslatedOptions] = useState([])

  const getBackground = status =>
    status === invoiceStatuses.OPENED && overdue
      ? '#F5E3E3' // 'rgba(255, 49, 49, 0.1)'
      : status === invoiceStatuses.PAID
      ? '#e3f2de' // 'rgba(68, 204, 0, 0.1)'
      : status === invoiceStatuses.CANCELED
      ? 'rgba(167, 179, 190, 0.1)'
      : '#fffbeb' // 'rgba(255, 206, 50, 0.1)'

  const getColor = status =>
    status === invoiceStatuses.OPENED && overdue
      ? '#ff3131'
      : status === invoiceStatuses.PAID
      ? '#44cc00'
      : status === invoiceStatuses.CANCELED
      ? '#a7b3be'
      : '#ffce32'

  useEffect(() => {
    const options =
      value === invoiceStatuses.OPENED
        ? [invoiceStatuses.PAID, invoiceStatuses.CANCELED]
        : value === invoiceStatuses.CANCELED
        ? [invoiceStatuses.OPENED, invoiceStatuses.PAID]
        : []
    setTranslatedOptions(prepareInvoiceStatusOptions(options))

    setSelectedOption({
      ...selectedOption,
      label: getInvoiceStatusName(selectedOption?.value)
    })
    // eslint-disable-next-line
  }, [value])

  useEffect(() => {
    setSelectedOption({ value, label: getInvoiceStatusName(value, overdue) })
  }, [value, overdue])

  const handleChange = selectedOption => {
    onChange && onChange(selectedOption?.value)
  }

  const customStyles = {
    control: base => ({
      ...base,
      minHeight: '3rem',
      cursor: 'pointer',
      textAlign: 'center',
      borderRadius: '56px',
      border: `1px solid ${getColor(selectedOption?.value)}`,
      boxShadow: 'none',
      backgroundColor: getBackground(selectedOption?.value),
      color: getColor(selectedOption?.value)
    }),
    valueContainer: base => ({
      ...base,
      justifyContent: 'center'
    }),
    singleValue: base => ({
      ...base,
      width: '100%',
      color: 'inherit',
      fontSize: '1.2rem',
      fontWeight: '400',
      lineHeight: '1.5rem'
    }),
    menu: provided => ({
      ...provided,
      ...{
        border: 'none !important',
        backgroundColor: '#ffffff !important'
      }
    }),
    option: (provided, { isFocused }) => ({
      ...provided,
      cursor: 'pointer',
      fontSize: '1.2rem',
      ...{
        fontWeight: '400',
        color: isFocused ? '#ffffff' : '#131E3D',
        backgroundColor: isFocused ? '#131E3D' : 'transparent'
      }
    }),
    dropdownIndicator: base => ({
      ...base,
      cursor: 'pointer',
      padding: '0 8px',
      color: getColor(selectedOption?.value)
    })
  }

  const addDataTest = (Component, dataTest) =>
    function (props) {
      return <Component {...props} innerProps={{ ...props.innerProps, 'data-test': dataTest }} />
    }

  return (
    <Wrapper className='select-wrapper'>
      <Select
        {...rest}
        id={id}
        name={name}
        isDisabled={disabled}
        isSearchable={false}
        className={cn('invoice-status', className)}
        placeholder=''
        options={translatedOptions}
        value={selectedOption}
        styles={customStyles}
        onChange={handleChange}
        components={{
          IndicatorSeparator: () => null,
          Control: addDataTest(components.Control, selectedOption?.value?.toLowerCase())
        }}
        menuPlacement='auto'
      />
    </Wrapper>
  )
}

export default memo(InvoiceStatus, (prevProps, nextProps) => isEqual(prevProps, nextProps))

InvoiceStatus.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
  overdue: PropTypes.bool,
  onChange: PropTypes.func,
  className: PropTypes.string,
  disabled: PropTypes.bool
}

const Wrapper = styled.div`
  height: 4rem;
  width: 16rem;
`
