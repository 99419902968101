import styled from 'styled-components'

export const MenuWrapper = styled.div`
  position: relative;

  color: #131e3d;
  font-size: 1.4rem;
  font-weight: 500;

  &.header {
    margin-left: 5rem;
  }

  .icon-letter {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-right: 1rem;

    width: 3rem;
    height: 3rem;
    border-radius: 5rem;
    background-color: #699cfc;

    color: white;
    font-size: 1.4rem;
    font-weight: 500;

    &.active {
      background-color: #3cd1a3;
    }
  }
`

export const MenuContainer = styled.div`
  border: 1px solid #e9e9e9;
  border-radius: 1rem;
  cursor: pointer;
  height: 5rem;
  min-width: 20rem;
  padding: 1rem 1.5rem 1rem 1rem;

  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  gap: 1.5rem;
`

export const MenuDropDown = styled.div`
  z-index: 1001;
  position: absolute;
  top: 6rem;
  left: 0;

  min-width: 27rem;
  padding: 1rem;

  background: #ffffff;
  border-radius: 1rem;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);

  display: none;
  &.opened {
    display: block;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;

    & > li {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 1.5rem;
      height: 5rem;
      padding: 0 1rem;
      color: #454a54;
      white-space: nowrap;

      :hover {
        background: #e7ebee;
        border-radius: 0.5rem;
      }
    }
  }
`
