import styled from 'styled-components'

import { CommonFlexColumn } from '../../../../components/jetonStyled'

export const AccountSectionWrapper = styled.div`
  width: 100%;
  align-self: center;

  ${CommonFlexColumn(2)}

  justify-content: space-between;

  @media (min-width: 768px) {
    flex-flow: row nowrap;
  }

  .buttons-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;
    width: 100%;

    flex: 0;

    @media (min-width: 767px) {
      display: flex;
      flex-flow: row nowrap;
      /* grid-template-columns: repeat(4, 1fr); */
    }

    .button {
      height: 50px;
      box-shadow: 0px 0px 25px rgba(46, 53, 56, 0.1);
      border-radius: 8px;

      color: #85a0ad;
      font-size: 1.4rem;
      font-weight: 400;
      line-height: 1.7rem;
      text-transform: capitalize;
      padding: 0 1.5rem;

      .icon-left {
        margin-right: 0.5rem;
      }

      @media (min-width: 767px) {
        width: 14rem;
        padding: 1.3rem 4rem 1.3rem 1.5rem;

        &:nth-of-type(3) {
          width: 16rem;
        }
        &:nth-of-type(4) {
          width: 15rem;
        }
      }
    }

    #button-tooltip {
      border-radius: 8px;

      font-family: 'Inter', sans-serif;
      font-size: 1rem;
      font-style: normal;
      font-weight: 400;
      line-height: 100%;

      * {
        text-align: left;
      }
    }
  }
`

export const BalanceContainer = styled.div`
  flex: 1;
  align-self: flex-start;
  display: flex;
  flex-flow: column nowrap;

  .label {
    font-size: 12px;
    font-family: Roboto;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .balance {
    color: #131e3d;
    font-family: inherit;
    font-style: normal;
    font-size: 2.4rem;
    font-weight: 600;
    line-height: 3rem;
    margin-top: 0.5rem;

    @media (min-width: 768px) {
      font-size: 3.2rem;
      font-weight: 600;
      line-height: 3.9rem;
    }
  }
`
