import styled from 'styled-components'

import RightIcon from '../../images/icons/arrow-right-white.svg'
import HamburgerMenuIcon from '../../images/icons/hamburger-menu.svg'
import { CommonFlexColumn, CommonFlexRow } from '../jetonStyled'

export const Hamburger = styled.div`
  padding: 2rem;

  div.title {
    color: #131e3d;
  }

  a {
    margin-bottom: 0;
    padding: 1.6rem 2.5rem;

    &.active-tab {
      border-bottom: none;
    }
  }
`

export const HamburgerIcon = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  position: relative;
  padding: 0;
  z-index: 10;
  border: none;
  cursor: pointer;
  background: transparent;

  :focus {
    outline: none;
  }

  svg:hover > * {
    fill: #bdbdbd;
  }

  .menu-icon {
    width: 2.5rem;
    height: 2.5rem;
    //prettier-ignore
    background: url("${HamburgerMenuIcon}") center center no-repeat;
  }
`
export const HamburgerMenuWrapper = styled.nav<{ open: boolean; maxMobileWidth: number }>`
  padding: 2rem 2rem 4rem 2rem;
  position: absolute;
  top: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  z-index: 99;

  ${CommonFlexColumn(2)}

  background: #ffffff;
  display: ${({ open }) => (open ? 'flex' : 'none')};
  .upper {
    ${CommonFlexRow(2)}
    justify-content: space-between;
    align-items: center;
    a {
      padding: 0;
      img {
        padding: 0 !important;
      }
    }
  }
  .items-container {
    flex: 1;
    margin-top: 2rem;
    width: 100%;
    ${CommonFlexColumn(1)};
    a {
      ${CommonFlexRow()}
      justify-content: space-between;

      width: 100%;
      font-weight: 400;
      font-size: 1.8rem;
      line-height: 2.2rem;
      height: auto;
      text-decoration: none;
      border-bottom: 1px solid rgba(237, 238, 239, 1);

      &::after {
        content: ' ';
        //prettier-ignore
        background: url("${RightIcon}") center center no-repeat;
        width: 15px;
        height: 10px;
      }
    }
  }
  .action-button {
    flex: 0;
    button {
      background-color: #06f;
      color: #ffffff;
      font-weight: 400;
    }
  }
`
