import styled from 'styled-components'

export const ModalWrapper = styled.div<{ isInModal: boolean }>`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;

  width: 100%;
  max-width: 50rem;
  position: relative;
  margin: auto;
  background-color: #ffffff;
  border-radius: 1rem;

  padding: ${({ isInModal }) => (isInModal ? '0' : '4rem 4.2rem 3rem')};

  .buttons {
    width: 100%;
  }
`
