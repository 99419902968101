import styled from 'styled-components'

export const StatusWrapper = styled.div`
  align-items: center;
  display: flex;

  span {
    display: flex;
    max-width: 30px;
    justify-content: center;
    width: 1.2rem;
    height: 1.2rem;
    margin-right: 5px;
    border-radius: 50%;

    &.pending,
    &.redemption_in_progress,
    &.initial {
      background-color: #f6c227;
    }

    &.scheduled {
      background-color: #1b44a0;
    }

    &.cancelled,
    &.expired,
    &.failed,
    &.pending_cancellation,
    &.cancelled {
      background-color: #f73b3b;
    }

    &.processed,
    &.redeemed,
    &.confirmed,
    &.active,
    &.paid {
      background-color: #3cd1a3;
    }
  }

  p {
    text-transform: capitalize;
    margin: 0;

    &.small {
      font-size: 1.2rem;
    }

    &.medium {
      font-size: 1.4rem;
      font-weight: 500;
    }
  }
`
