import { useEffect } from "react"
import ReactTooltip from "react-tooltip"

import { SavingsPlanSummaryOut } from "mmfintech-commons-types"

import PlanCard from "./PlanCard"
import { SavingPlansContainer, SavingPlansTitle, SavingPlansWrapper } from "./styled/savingPlans.styled"

interface SavingPlanProps {
    title: string,
    plans: SavingsPlanSummaryOut[],
    type: 'crypto' | 'money'
}

const SavingPlans = ({ title = '', plans = [], type }: SavingPlanProps) => {

    useEffect(() => {
        ReactTooltip.rebuild()
    }, [])

    return (
        <SavingPlansWrapper className={type}>
            <SavingPlansTitle>
                {title}
            </SavingPlansTitle>
            <SavingPlansContainer>
                {plans.map((fiatPlan, index) => {
                    return <PlanCard key={index + fiatPlan.currency} currency={fiatPlan.currency} reward={fiatPlan.reward} minAmount={fiatPlan.minAmount} type={type} />
                })}
            </SavingPlansContainer>
            <ReactTooltip className='tooltip-wrapper' id='plan-tooltip' effect="solid" place='bottom' arrowColor='transparent' multiline />
        </SavingPlansWrapper>
    )
}

export default SavingPlans