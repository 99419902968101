import { useContext } from 'react'

import { Button } from 'mmfintech-portal-commons'
import { UnlockedFeatureModalWrapper } from './styled/unlockedFeatureModal'

import { GlobalContext, tr } from 'mmfintech-commons'
import { isMerchantProspect, useGetMerchantQuery } from 'mmfintech-backend-api'

import UnlockedDepositIcon from '../images/icons/unlocked-deposit.svg?react'
import UnlockedExchangeIcon from '../images/icons/unlocked-exchange.svg?react'

type UnlockedFeatureModalProps = {
  type: 'deposit' | 'exchange'
}

const modalType = (type: string, isProspect: boolean) => {
  if (type === 'deposit') {
    return {
      title: tr('FRONTEND.HINTS.UNLOCKED_DEPOSITS.TITLE', 'Deposit'),
      body: isProspect
        ? 'You can deposit in crypto. Just follow through Deposit.'
        : tr(
            'FRONTEND.HINTS.UNLOCKED_DEPOSITS.BODY',
            'You can deposit in both fiat and crypto. Just follow through Deposit.'
          ),
      img: <UnlockedDepositIcon />
    }
  }
  return {
    title: tr('FRONTEND.HINTS.UNLOCKED_EXCHANGE.TITLE', 'Exchange'),
    body: tr(
      'FRONTEND.HINTS.UNLOCKED_EXCHANGE.BODY',
      'You can exchange your fiat or crypto holdings into other fiat currencies.'
    ),
    img: <UnlockedExchangeIcon />
  }
}

export function UnlockedFeatureModal({ type }: UnlockedFeatureModalProps) {
  const { modalHide } = useContext(GlobalContext)
  const { data: merchant } = useGetMerchantQuery()

  if (!modalType(type, isMerchantProspect(merchant))) {
    return null
  }
  const { title, body, img } = modalType(type, isMerchantProspect(merchant))

  return (
    <UnlockedFeatureModalWrapper>
      <div className='content'>
        {img}
        <div className='splitter' />
        <h3>{title}</h3>
        <p>{body}</p>
      </div>
      <Button text='Close' onClick={modalHide} />
    </UnlockedFeatureModalWrapper>
  )
}
