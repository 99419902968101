import React, { useContext, useEffect, useRef, useState } from 'react'
import { NavLink, useHistory } from 'react-router-dom'

import { api, paths, useLazyCardLogoutQuery, useLazyLogoutQuery } from 'mmfintech-backend-api'
import { GlobalContext, tr, useWindowSize } from 'mmfintech-commons'

import ActivitiesMenu from './ActivitiesMenu'
import { MerchantSwitchMenu } from './MerchantSwitchMenu'
import { ActivityButton, HeaderIconsWrapper } from './styled/HeaderIcons.styled'
import { DropDownMenuContainer } from './styled/userDropDownMenu.styled'

import { useAppDispatch } from '../hooks'
import { useDropDownMenu } from 'mmfintech-commons'

import ActivitiesIcon from '../images/icons/activities-icon.svg?react'
import ProfileIcon from '../images/icons/profile-icon.svg?react'
import SettingsIcon from '../images/icons/settings-icon.svg?react'

interface HeaderIconsProps {
  UserMenu: React.FC<{ onClick: () => void; className: string }>
  maxWidth: number
}

function HeaderIcons({ UserMenu, maxWidth }: HeaderIconsProps) {
  const dispatch = useAppDispatch()
  const history = useHistory()
  const menuRef = useRef<HTMLInputElement>(null)
  const { sidebarRightShow, sidebarRightHide } = useContext<any>(GlobalContext)

  const [visibleActivities, setVisibleActivities] = useState(false)
  const [visibleSettings, setVisibleSettings] = useState(false)
  const [width] = useWindowSize()
  const [logout] = useLazyLogoutQuery()
  const [cardLogout] = useLazyCardLogoutQuery()

  const { visible, hideDropDown, toggleDropDown } = useDropDownMenu()

  const toggleDropdowns = (type: 'settings' | 'activities') => {
    if (type === 'settings') {
      toggleDropDown()
      setVisibleActivities(false)
    } else {
      setVisibleActivities(prevVisibility => !prevVisibility)
      hideDropDown()
    }
  }
  const handleLogoutClick = async (e: React.SyntheticEvent) => {
    e.preventDefault()
    try {
      await logout().unwrap()
      await cardLogout().unwrap()
      dispatch(api.util.resetApiState())
    } catch {}
  }

  const handleProfileRedirect = () => {
    setVisibleSettings(false)
    setVisibleActivities(false)
    history.push(paths.profile())
  }

  useEffect(() => {
    const body = document.getElementsByTagName('body').item(0)
    if (body) {
      if (visibleActivities) {
        body.classList.add('menu-opened')
      } else {
        body.classList.remove('menu-opened')
      }
    }
    // eslint-disable-next-line
  }, [visibleActivities])

  useEffect(() => {
    const checkIfClickedOutside = (e: any) => {
      if (menuRef.current && !menuRef.current.contains(e.target)) {
        setVisibleSettings(false)
        setVisibleActivities(false)
      }
    }
    document.addEventListener('mousedown', checkIfClickedOutside)
    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside)
    }
    // eslint-disable-next-line
  }, [visibleActivities, visibleSettings])

  const handleOpenSidebarRight = () => {
    sidebarRightShow({
      content: <ActivitiesMenu onClose={sidebarRightHide} />,
      options: { size: 'extra-large', closeOnClickOutside: true }
    })
  }
  return (
    <HeaderIconsWrapper ref={menuRef}>
      <MerchantSwitchMenu className='header' />
      <ProfileIcon onClick={() => handleProfileRedirect()} title='User profile' />
      <div className='settings-wrapper'>
        <SettingsIcon onClick={() => toggleDropdowns('settings')} title='Settings' />
        {visible && (
          <DropDownMenuContainer className='settings'>
            <UserMenu
              onClick={() => {
                toggleDropDown()
              }}
              className='menu-item selector'
            />
            {width >= maxWidth && (
              <NavLink to='#' className='menu-item logout' onClick={handleLogoutClick}>
                {tr('FRONTEND.HEADER.LOGOUT', 'Log Out')}
              </NavLink>
            )}
          </DropDownMenuContainer>
        )}
      </div>
      <ActivityButton onClick={handleOpenSidebarRight}>
        <div>
          <ActivitiesIcon title='Activities' />
        </div>
        <span> Activity </span>
      </ActivityButton>
    </HeaderIconsWrapper>
  )
}

export default HeaderIcons
