import styled from 'styled-components'

export const WithdrawModalWrapper = styled.div`
  display: grid;
  width: 58rem;
  padding: 4.5rem 2.5rem;
  @media (min-width: 400px) {
    padding: 4rem 6rem;
  }
  @media (max-width: 600px) {
    width: 100%;
  }
  .title {
    display: block;
    text-align: center;
    margin-bottom: 2rem;
    color: #000000;
    font-size: 2.4rem;
    font-weight: 600;
    line-height: 2.9rem;
    @media (max-width: 600px) {
      font-size: 1.8rem;
    }
  }
  .disclaimer-text {
    text-align: center;
    font-size: 1.6rem;
    line-height: 1.9rem;
    font-weight: 400;
    @media (max-width: 600px) {
      font-size: 1.4rem;
    }
  }
  .buttons-container {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;

    width: 100%;
    margin-top: 4rem;
    button:nth-of-type(2) {
      margin-top: 1rem;
      color: #131E3D;
    }

    .button {
      max-width: 40rem;
    }
  }
  .countdown-wrapper>*{
    margin: 0;
    text-align: center;
  }
  .err-msg-wrapper{
    justify-content: center;
  }
`

export const Countdown = styled.div`
  text-align: right;
  margin-top: 1.4rem;

  color: #939ea7;
  font-family: inherit;
  font-size: 1.6rem;
  font-style: normal;
  font-weight: normal;
  line-height: 3.6rem;

  &.expired {
    color: #ee2b2b;
  }
  &.allowed {
    color: #34a518;
  }

  span {
    display: block;
    font-style: normal;
    font-weight: 500;
    font-size: 1.2rem;
    line-height: 1.5rem;
  }
`
