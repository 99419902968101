import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import fileDownload from 'js-file-download'

import { useGetMerchantQuery } from 'mmfintech-backend-api'

import { useDownloadFileMutation } from '../../../../hooks'
import { Line, LineWrapper } from '../styled/conversationModal.styled'
import FileComponent from './FileComponent'

import StaffIcon from '../../../../images/icons/staff-icon.svg?react'

function ConversationLine({
  isAdminMessage,
  content,
  type
}: {
  isAdminMessage: boolean
  content: string
  type: 'TEXT' | 'DOCUMENT'
}) {
  const { data: merchant } = useGetMerchantQuery()
  const dispatch = useDispatch()
  const [handleDownload] = useDownloadFileMutation({
    endpointName: 'kybdocument/download'
  })

  const [documentContent, setDocumentContent] = useState<Blob>()

  const handlePrepareDocument = async () => {
    const res = await handleDownload({ url: `/kyb/documents/download/file/${content}`, method: 'GET' })
    if (res) setDocumentContent(res)
  }

  useEffect(() => {
    if (type === 'DOCUMENT') {
      handlePrepareDocument()
    }
  }, [type, content, dispatch])

  const handleDownloadPdf = () => {
    if (documentContent) {
      fileDownload(documentContent, 'Document', 'application/pdf')
    }
  }

  const { name: merchantName } = merchant || {}
  const initials = merchantName?.match(/\b\w/g)?.join('')?.toUpperCase()
  return (
    <LineWrapper isAdminMessage={isAdminMessage}>
      {isAdminMessage ? <StaffIcon className='staff-icon' /> : <div className='initials'>{initials}</div>}
      <Line isAdminMessage={isAdminMessage}>
        <div className='line-title'>{isAdminMessage ? 'JetonBank' : merchantName}</div>
        <div className='content'>
          {type === 'DOCUMENT' ? (
            documentContent?.type.includes('image') ? (
              <img src={URL.createObjectURL(documentContent)} alt='uploaded-document' />
            ) : (
              <FileComponent
                name='File'
                iconStroke='#ffffff'
                wrapperStyles={{ cursor: 'pointer' }}
                onClick={handleDownloadPdf}
              />
            )
          ) : (
            content
          )}
        </div>
      </Line>
    </LineWrapper>
  )
}

export default ConversationLine
