import React, { memo } from 'react'

import cn from 'classnames'
import isEqual from 'lodash.isequal'

import { SidebarInnerRightContainer, SidebarInnerRightWrapper } from './sidebarRight.styled'

interface SidebarInnerRightProps {
  content: any
  options: {
    closeIconPosition?: 'default'
    closeOnClickOutside?: boolean
    closeOnEscape?: boolean
    hideCloseIcon?: boolean
    scrollable?: boolean
    maxWidth?: number
    onClose?: () => void
    transparent?: boolean
    size?: 'auto' | 'small' | 'medium' | 'large' | 'extra-large' | 'horizontal' | 'centered'
  }
  visible: boolean
}

const SidebarInnerRight: React.FC<SidebarInnerRightProps> = ({ content, options = {}, visible }) => {
  return (
    <SidebarInnerRightWrapper visible={visible}>
      <SidebarInnerRightContainer
        visible={visible}
        isCompletelyClosed={!visible && !content}
        data-test='modal-container'
        className={cn({
          'size-auto': options?.size === 'auto',
          'size-large': options?.size === 'large',
          'size-small': options?.size === 'small',
          'size-extra-large': options?.size === 'extra-large',
          centered: options?.size === 'centered',
          horizontal: options?.size === 'horizontal',
          transparent: options?.transparent === true
        })}>
        {content}
      </SidebarInnerRightContainer>
    </SidebarInnerRightWrapper>
  )
}

export default memo(SidebarInnerRight, (prevProps, nextProps) => isEqual(prevProps, nextProps))
