import { FC } from 'react'
import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar'

import styled from 'styled-components'

import { useWindowSize } from 'mmfintech-commons'

import { CommonFlexColumn } from '../../../../components/jetonStyled'
import { breakpoints, devices } from '../../../../constants'
import { useOnboardingProgress } from '../../../../hooks'
import RadialSeparators from '../RadialSeparators'

export const OnboardingProgress: FC<{ noMargin?: boolean; isBanner?: boolean }> = ({ noMargin, isBanner }) => {
  const { currentProgress } = useOnboardingProgress()
  const [width] = useWindowSize()
  const isMobile = width < breakpoints.xl
  return (
    <ProgressWrapper noMargin={noMargin} isBanner={isBanner}>
      <CircularProgressbarWithChildren
        value={currentProgress}
        strokeWidth={isMobile ? 9 : 12}
        styles={buildStyles({
          backgroundColor: '#699CFC',
          strokeLinecap: 'butt',
          trailColor: '#3CD1A3'
        })}>
        <RadialSeparators
          count={4}
          style={{
            background: '#ffff',
            width: '11px',
            marginLeft: '-1px',
            // This needs to be equal to props.strokeWidth
            height: `${12}%`
          }}
        />
      </CircularProgressbarWithChildren>
      <Fraction progress={currentProgress} />
    </ProgressWrapper>
  )
}

const Fraction: FC<{ progress: number }> = ({ progress }) => {
  const numerator = progress / 25
  const denominator = 4

  return (
    <div className='fraction'>
      <div>
        <span>{numerator}</span>
        <span>
          {'/'}
          {denominator}
        </span>
      </div>
      <p>Complete</p>
    </div>
  )
}

const ProgressWrapper = styled.div<{ noMargin?: boolean; isBanner?: boolean }>`
  position: relative;

  width: 10rem;
  height: auto;
  margin: ${({ noMargin }) => (noMargin ? 0 : '-4rem auto auto')};

  background-color: #fff;
  border-radius: 55%;
  @media ${devices.xl} {
    width: 15rem;
    height: 15rem;
  }

  padding: 10px;
  .fraction {
    position: absolute;
    top: 50%; /* position the top  edge of the element at the middle of the parent */
    left: 50%; /* position the left edge of the element at the middle of the parent */

    transform: translate(-50%, -50%);
    ${CommonFlexColumn()}
    align-items: center;
    color: #699cfc;

    font-weight: 600;
    span:first-of-type {
      font-size: 2.1rem;
    }
    span:last-of-type {
      font-size: 1.7rem;
    }
    p {
      display: none;
    }
    @media ${devices.xl} {
      span:first-of-type {
        font-size: 3.5rem;
      }
      span:last-of-type {
        font-size: 2.8rem;
      }
      p {
        font-size: 1.8rem;
        font-weight: 400;
        margin: 0;
      }
    }
  }
`
