import { useContext } from 'react'

import { useChangeTwoFactorMutation } from 'mmfintech-backend-api'
import { Button, ErrorDisplay } from 'mmfintech-portal-commons'
import { GlobalContext, tr } from 'mmfintech-commons'

import { GoogleAuthenticatorInfo } from '../GoogleAuthenticatorInfo'

import { GoogleAuthenticatorInfoModalStyled } from './GoogleAuthenticatorInfoModal.styled'

export const GoogleAuthenticatorInfoModal = () => {
  const [change2fa, { error: change2faError }] = useChangeTwoFactorMutation()
  const { modalHide } = useContext(GlobalContext)

  const handleSubmit = e => {
    if (e) e.preventDefault()

    change2fa({
      twoFactor: 'TOTP'
    })
  }

  return (
    <GoogleAuthenticatorInfoModalStyled>
      <GoogleAuthenticatorInfo isNewUser={false} />
      <div className='google-authenticator-modal-actions'>
        <ErrorDisplay error={change2faError} />
        <Button text={tr('FRONTEND.WELCOME.MODAL.GET.STARTED.ENABLE', 'Enable now')} onClick={handleSubmit} />
        <Button
          text={tr('FRONTEND.WELCOME.MODAL.GET.STARTED.SKIP', 'Skip for now')}
          color='secondary'
          onClick={modalHide}
        />
      </div>
    </GoogleAuthenticatorInfoModalStyled>
  )
}
