import styled from 'styled-components'

import { devices } from '../../constants'

export const Wrapper = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: center;

  margin: 0;
  padding: 0;
  /* min-height: 100vh; */
  overflow: hidden;
  overflow-y: auto;
  position: relative;

  font-family: ${({ theme }) => theme.fontName}, sans-serif;
  background-color: #ffffff;

  @media ${devices.xl} {
    background-color: ${({ theme }) => theme.contentWrapper.backgroundColor};
  }
  /* 
  @media (min-width: 900px) {
    grid-row-gap: ${({ theme }) => theme.contentWrapper.gridRowGap2};
  }

  @media (min-width: 1175px) {
    grid-template-rows: ${({ theme }) => theme.header.height} auto;
    grid-row-gap: ${({ theme }) => theme.contentWrapper.gridRowGap3};
  } */

  /* @media (max-width: 767px) {
    background-color: #ffffff;
  } */

  & > div:first-of-type {
    animation: fadein 0.75s;
  }

  .fluid & {
    grid-row-gap: 0;

    @media (min-width: 900px) {
      grid-row-gap: 0;
    }

    @media (min-width: 1175px) {
      grid-template-rows: 9rem auto;
      grid-row-gap: 0;
    }
  }

  //todo inherit RoundSquareContainer in /components/styled
  .round-square-container {
    background: #ffffff;
    border-radius: ${({ theme }) => theme.containerBorderRadius};
    box-shadow: ${({ theme }) => theme.containerDropShadow};
    color: #131e3d;
    padding: 3rem 5rem;

    @media (max-width: 1024px) {
      padding: 2rem 3rem;
    }
  }

  .label-image-link {
    display: inline-flex;
    flex-flow: row nowrap;
    align-items: center;

    color: #a3b8c2;
    font-family: inherit;
    font-size: 1.4rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.7rem;
    cursor: pointer;

    .icon,
    img,
    svg {
      margin-right: 0.8rem;
    }
  }

  .input-hint {
    color: #a3b8c2;
    font-size: 1.2rem;
    font-style: italic;
    margin: -0.5rem 0 2rem;
  }

  .trs-2 {
    -webkit-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
  }

  #info-tooltip {
    color: #ffffff !important;
    background: #2f3032 !important;
  }

  [data-test='phone-input-phone'] {
    div:not(.flag-dropdown):not(input):not(.arrow) {
      border: none;
      padding: 0;
      .react-tel-input {
        display: flex;
        flex-flow: row-reverse nowrap;
        gap: 1rem;
        input {
          border: 1px solid #dcdfe5 !important;
          border-radius: 1rem;
          padding-left: 2rem !important;
        }
        .flag-dropdown {
          position: initial;
          border: 1px solid #dcdfe5 !important;
          border-radius: 1rem;
          padding: 1rem;
          width: 10rem !important;
          .selected-flag {
            width: 100%;
          }
          .arrow {
            left: 45px;
          }
        }
      }
    }
  }
`

export const ContentContainer = styled.div`
  display: flex;
  flex-grow: 1;
  max-width: auto;
  justify-content: center;
  // max-width: ${props => `${2048 - props.theme.sidebar.width}px`};
  overflow: hidden;
  margin: 0 auto;
`
