import styled from 'styled-components'

export const AccountsPaneWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 4rem;
  .your-accounts-text {
    font-family: 'Roboto';
    color: #131e3d;
    font-weight: 400;
    font-size: 1.8rem;
    line-height: 1.5rem;
    margin-bottom: 3rem;
  }
  .view-all-link {
    text-align: center;
    text-decoration: none;
    color: #707881;
    font-weight: 400;
    font-size: 1.2rem;
    line-height: 1.5rem;
  }
`

export const AccountLabelWrapper = styled.div`
  border-bottom: 1px solid #d3d7de;
  cursor: pointer;

  padding-bottom: 1.5rem;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;

  .left {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    gap: 1rem;
    .name-wrapper {
      display: flex;
      flex-flow: column nowrap;
      .label {
        font-family: 'Roboto';
        color: #131e3d;
        font-weight: 700;
        font-size: 12px;
        line-height: 1rem;
      }
      .name {
        font-family: 'Roboto';
        font-weight: 400;
        font-size: 1.4rem;
        line-height: 1.5rem;
        margin-top: 0.3rem;
        color: #131e3d;

        line-break: anywhere;
      }
      .currency-icon {
        border: 1px solid #fff;
      }
    }
  }
  .balance {
    font-weight: 600;
    font-size: 1.4rem;
    line-height: 1.7rem;
    white-space: nowrap;

    color: #131e3d;
  }
`
