import styled from 'styled-components'

export const CardsScreenWrapper = styled.div`
  width: 100%;
  height: 100%;
  max-width: 140rem;
  padding: 0 2.4rem;
  margin: auto;

  .cards-screen-container {
    width: 100%;
    flex-direction: row;
    display: flex;
    gap: 2.4rem;

    .cards-screen-left-section {
      flex: 1;
      max-width: 95rem;
      margin: 0 auto;

      .transaction-section-wrapper {
        margin-top: 2.4rem;

        .transaction-section-header {
          padding: 1.6rem 2.4rem;
        }
      }
    }

    .cards-screen-right-section {
      max-width: 45rem;
      width: 30%;

      .deposit-right-side {
        width: unset;
      }

      @media (max-width: 1280px) {
        display: none;
      }
    }
  }
`
