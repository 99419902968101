import { ToastBar, Toaster, toast } from 'react-hot-toast'

import styled from 'styled-components'

import { CommonFlexRow } from './jetonStyled'

import ToastSuccess from '../images/icons/toast-success.svg?react'
import ToastClose from '../images/icons/toaster-close.svg?react'

function CustomToaster() {
  return (
    <Toaster
      gutter={10}
      position='top-right'
      containerStyle={{
        top: '10rem',
        zIndex: '10010'
      }}
      toastOptions={{
        success: {
          style: {
            backgroundColor: '#3CD1A3'
          },
          icon: <ToastSuccess />
        },
        error: {
          style: {
            backgroundColor: '#F73B3B'
          }
        }
      }}>
      {t => (
        <ToastBar
          toast={t}
          style={{
            display: 'block',
            borderRadius: '10px',
            color: '#ffffff',
            padding: '1.7rem',
            width: '100%',
            maxWidth: '400px'
          }}>
          {({ icon, message }) => (
            <ContentWrapper>
              <div className='icon'>{icon}</div>
              <div className='content'>
                <span>{message}</span>
              </div>
              <div onClick={() => toast.dismiss(t.id)} className='close-icon'>
                <ToastClose />
              </div>
            </ContentWrapper>
          )}
        </ToastBar>
      )}
    </Toaster>
  )
}
export default CustomToaster

const ContentWrapper = styled.div`
  ${CommonFlexRow(1)}
  justify-content: space-between;
  align-items: center;

  .content {
    flex: 1;
    display: flex;
    flex-flow: column nowrap;
    font-size: 1.4rem;
    span > div {
      margin: 0;
      justify-content: initial;
    }
  }
  .close-icon {
    cursor: pointer;
  }
`
