import styled from 'styled-components'

export const InactiveCardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  height: 28rem;
  border-radius: 1.6rem;
  background-color: white;

  margin-top: 2.5rem;
  padding: 2.4rem;

  .inactive-card-message {
    font-weight: 500;
    font-size: 1.4rem;
    line-height: 1.68rem;
    width: 30rem;
    color: black;
    text-align: center;
    padding-top: 4rem;
  }
`
