import styled from 'styled-components'

export const GoogleAuthenticatorInfoWrapper = styled.div`
  margin: 0 auto;

  .title {
    font-size: 2.4rem;
    font-weight: 400;
    line-height: 2.8rem;
    text-align: center;
    color: #131e3d;
  }

  .logo {
    display: flex;
    justify-content: center;
    margin: 16px 0;
  }

  .title-old {
    font-size: 2.4rem;
    font-weight: 600;
    line-height: 2.8rem;
    text-align: center;
    color: #131e3d;
  }

  .description {
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 1.8rem;
    text-align: center;
    margin-bottom: 16px;
  }

  .why-ga {
    display: flex;
    flex-direction: column;
    gap: 16px;

    .why-ga-title {
      font-size: 1.8rem;
      font-weight: 400;
      line-height: 2.1rem;
      text-align: center;
      color: #131e3d;
    }

    .why-ga-content {
      display: flex;
      flex-direction: column;
      gap: 16px;

      .why-ga-content-item {
        display: flex;
        gap: 8px;

        svg {
          min-width: 24px;
        }

        p {
          font-size: 1.2rem;
          font-weight: 400;
          line-height: 1.8rem;
          text-align: left;
          margin: 0;
        }
      }
    }

    .why-ga-description {
      font-size: 1.2rem;
      font-weight: 400;
      line-height: 1.8rem;
      text-align: left;
      color: #000000;
    }
  }
`
