import { useState } from "react"

import { tr } from "mmfintech-commons"

import InvestmentsTableCurrent from "./InvestmentsTableCurrent"
import InvestmentsTableHistory from "./InvestmentsTableHistory"
import { InvestmentTab, InvestmentTabsWrapper, InvestmentsSectionTitle, InvestmentsSectionWrapper } from "./styled/investmentsSection.styled"

const InvestmentsSection = ({ title = '' }) => {

    const [isActiveInvestments, setIsActiveInvestments] = useState(true)

    return (
        <InvestmentsSectionWrapper>
            <InvestmentsSectionTitle>
                {title || tr('FRONTEND.SAVINGS.CURRENT_INVESTMENTS.TITLE', 'Your Current Investments')}
            </InvestmentsSectionTitle>
            <InvestmentTabsWrapper>
                <InvestmentTab active={isActiveInvestments} onClick={() => setIsActiveInvestments(true)} data-test='active-investments-tab'>
                    {tr('FRONTEND.SAVINGS.CURRENT_INVESTMENTS.BUTTON.ACTIVE', 'Active')}
                </InvestmentTab>
                <InvestmentTab active={!isActiveInvestments} onClick={() => setIsActiveInvestments(false)} data-test='all-investments-tab'>
                    {tr('FRONTEND.SAVINGS.CURRENT_INVESTMENTS.BUTTON.HISTORY', 'History')}
                </InvestmentTab>
            </InvestmentTabsWrapper>
            {isActiveInvestments ? <InvestmentsTableCurrent /> : <InvestmentsTableHistory />}
        </InvestmentsSectionWrapper>
    )
}

export default InvestmentsSection