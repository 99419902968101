import { invoiceStatuses, useLazyGetInvoicesQuery } from 'mmfintech-backend-api'
import { isValidArray, isValidString, usePaginationQuery } from 'mmfintech-commons'

const useInvoicesFilter = () => {
  const [fetchInvoices, { invoices, invoicesError, invoicesFetching }] = useLazyGetInvoicesQuery({
    selectFromResult: ({ data, isFetching, error }) => ({
      invoices: isValidArray(data?.content) ? data.content : [],
      invoicesFetching: isFetching,
      invoicesError: error
    })
  })

  const pagination = usePaginationQuery({
    rowsPerPage: 6,
    reload: async (params, onSuccess) => {
      const { formValues, ...rest } = params
      const filter = {
        sort: 'date-desc,id-desc',
        ...rest,
        ...(formValues
          ? {
              ...(formValues?.getValue('status') === 'OVERDUE' ? { isOverdue: true } : null),
              ...(formValues?.getValue('status') === invoiceStatuses.OPENED ? { isOverdue: false } : null),
              ...(isValidString(formValues?.getValue('status')) && formValues?.getValue('status') !== 'OVERDUE'
                ? { status: formValues?.getValue('status') }
                : null),
              ...(isValidString(formValues?.getValue('payerName'))
                ? { payerName: `%${formValues?.getValue('payerName')}%` }
                : null),
              ...(isValidString(formValues?.getValue('invoiceNumber'))
                ? { invoiceNumber: `%${formValues?.getValue('invoiceNumber')}%` }
                : null),
              ...(isValidString(formValues?.getValue('ownInvoiceNumber'))
                ? { ownInvoiceNumber: `%${formValues?.getValue('ownInvoiceNumber')}%` }
                : null)
            }
          : {})
      }
      try {
        const response = await fetchInvoices(filter).unwrap()

        if (response) {
          onSuccess(data)
        }
      } catch (_err) {}
    }
  })

  // const delayedReload = useDelayedExecute({
  //   name: 'delayedInvoicesReload',
  //   onExecute: pagination.reload
  // })

  // useEffect(() => {
  //   if (firstUpdate.current) {
  //     // stop the initial trigger, cause this causes two API calls
  //     firstUpdate.current = false
  //     return
  //   }
  //   if (!isMobile) {
  //     delayedReload.execute()
  //   }
  // }, [])

  return {
    pagination,
    invoices,
    invoicesFetching,
    invoicesError
  }
}

export default useInvoicesFilter
