import styled from 'styled-components'

export const LastActionWrapper = styled.div`
  .action-container {
    display: flex;
    flex-direction: column;
    background: #fff;
    border-radius: 1rem;
    padding: 1.2rem;
    min-height: 29rem;
    overflow: hidden;
  }

  .action-container-inner-bg::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 45%);
    pointer-events: none;
  }

  .action-container-inner-bg {
    display: flex;
    position: relative;
    width: 100%;
    flex: 1;
    align-items: center;
    border-radius: 0.8rem;
    background: repeating-linear-gradient(
      to right,
      rgba(143, 209, 142, 0.71),
      rgba(143, 209, 142, 0.71) 2px,
      #fff 2px,
      #fff 4px
    );
    background-size: 100% 20px;
  }

  .amount {
    font-size: 2.4rem;
    font-family: Roboto;
    font-style: normal;
    font-weight: 600;
    line-height: 2.8rem;
    letter-spacing: -0.05rem;
    text-align: center;
  }

  .action-container-inner-content {
    flex: 1;
    z-index: 1;
    display: flex;
    flex-direction: column;

    height: 100%;
    gap: 2.5rem;
    justify-content: center;
    align-items: center;
  }

  h3 {
    font-size: 18px;
    font-family: Roboto;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: capitalize;
  }
`
