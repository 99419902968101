import styled from 'styled-components'

import { breakpoints } from '../../../constants'

export const SavingPlansWrapper = styled.div`
    max-width: 820px;

    .tooltip-wrapper {
        border-radius: 1rem;
    }

    &.crypto{
        max-width: 550px;
    }
    
    @media (max-width: 1520px) {
        max-width: unset;
    }

    @media (max-width: 575px) {
      width: 100%;
    }
`

export const SavingPlansTitle = styled.div`
    color: #8F96A3;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;

    margin: 1rem 0;
`

export const SavingPlansContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: 1rem;
    flex-wrap: wrap;

    @media (max-width: ${breakpoints.xl}px) {
        flex-wrap: wrap;
    }
`
