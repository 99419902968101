import styled from 'styled-components'

export const PlanCardWrapper = styled.div`
    width: 260px;
    height: 190px;
    border-radius: 1.3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #FFFFFF;
    box-sizing: content-box;
    border: 3px solid #F6F7F8;

    :hover{
        border: 3px solid #3CD1A34D;
        box-shadow: 0px 4px 10px 0px #000E2B0F;
        
        >*{
            border: 1px solid #3CD1A3;
        }
    }

    @media (max-width: 575px) {
      width: 100%;
    }
`

export const PlanCardBox = styled.div`
    height: 100%;
    width: 100%;
    border-radius: 1rem;
    padding: 1.6rem;
    border: 1px solid #F6F7F8;
`

export const PlanCardHeader = styled.div`
    width: 100%;
    font-size: 14px;
    font-weight: 500;

    padding-bottom: 2rem;

    border-bottom: 1px solid #F4F5F6;

    display: flex;
    justify-content: space-between;

    .currency-wrapper {
        display: flex;
        gap: 1rem;
        align-items: center;
    }

    .invest-button {

        color: #FFFFFF;
        width: 8.5rem;
        height: 3rem;
        border-radius: 8px;
        font-size: 12px;
        font-weight: 400;

        :hover, :focus {
            color: #FFFFFF;
        }
    }

    .invest-button.money {
        background: #699cfc;

        :hover {
            background: #508bfb;
        }
    }

    .invest-button.crypto {

        background: #3cd1a3;

        :hover {
            background: #2fc697;
        }
    }
`

export const PlanCardContent = styled.div`
    padding: 1.3rem 0;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .content-label{
        font-size: 12px;
        font-weight: 400;
        color: #8F96A3;
        display: flex;
        align-items: center;
        gap: 1rem;
        
        .info-icon {
            width: 12px;
            height: 12px;
            cursor: pointer;
        }
    }

    .content-text{
        font-size: 14px;
        margin-left: 3.4rem;
    }

`
