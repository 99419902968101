import { useLocation } from 'react-router-dom'
import { useContext, useEffect } from 'react'
import cn from 'classnames'

import { ChallengePurposeEnum, LoginStatusEnum, TwoFactorTypeEnum } from 'mmfintech-commons-types'
import {
  formatPhoneNumber,
  GlobalContext,
  isValidFunction,
  OtpContext,
  tr,
  useValidUntilTimer
} from 'mmfintech-commons'
import { globalSettings, paths, useAuth, useOtpQry } from 'mmfintech-backend-api'
import { Button, ErrorDisplay, Spinner } from 'mmfintech-portal-commons'

import { WelcomeModal } from '../views/dashboard/elements/WelcomeModal'
import { GoogleAuthenticator } from './GoogleAuthenticator'

import { Countdown, ResendMessage, VerificationContainer } from './styled/otp.styled'

type OtpProps = {
  onCancel?: () => void
}

export const Otp = ({ onCancel }: OtpProps) => {
  const {
    code,
    codeSize,
    attempts,
    challenge,
    handleCodeChanged,
    handleCancel,
    handleResend,
    verifyOtpError,
    verifyOtpFetching,
    resendOtpFetching,
    resendTimer
  } = useOtpQry({
    autoSubmit: true
  })
  const { pathname } = useLocation()
  const { modalShow } = useContext(GlobalContext)

  const userStatus = useAuth()
  const timer = useValidUntilTimer()
  const { setOtpOnError, setOtpOnSuccess } = useContext(OtpContext)

  const { sentTo, twoFactorType, challengePurpose, totpSecretKeyUri } = challenge || {}

  useEffect(() => {
    if (totpSecretKeyUri) {
      setOtpOnSuccess(() => (response: any) => {
        if (response && totpSecretKeyUri && pathname === paths.dashboard() && challengePurpose === 'CHANGE_2FA_TOTP') {
          setTimeout(() => {
            modalShow({
              header: tr('FRONTEND.WELCOME.MODAL.HEADING', 'Welcome to Jetonbank!'),
              options: {
                size: 'medium',
                transparent: true,
                closeOnClickOutside: false,
                closeOnEscape: false
              },
              content: <WelcomeModal currentStep={3} isSuccess2Fa={true} />
            })
          }, 0)
        }
      })
    }

    return () => {
      setOtpOnSuccess(null)
      setOtpOnError(null)
    }
  }, [totpSecretKeyUri])

  const internalCancel = () => {
    isValidFunction(onCancel) && onCancel()
    handleCancel()
  }

  const prepareTitle = () => {
    if (twoFactorType === TwoFactorTypeEnum.SMS) {
      if (userStatus === LoginStatusEnum.LOGGED_IN) {
        return tr('FRONTEND.VERIFY_OTP.TITLE_VERIFY_SMS', 'SMS Verification')
      }
      return tr('FRONTEND.VERIFY_OTP.TITLE_SMS', 'SMS Confirmation')
    }
    if (twoFactorType === TwoFactorTypeEnum.TOTP) {
      if (userStatus === LoginStatusEnum.LOGGED_IN) {
        return tr('FRONTEND.VERIFY_OTP.TITLE_VERIFY_TOTP', 'Google Authenticator verification')
      }
      return tr('FRONTEND.VERIFY_OTP.TITLE_TOTP', 'Google Authenticator confirmation')
    }

    if (userStatus === LoginStatusEnum.SECOND_FACTOR || userStatus === LoginStatusEnum.LOGGED_IN) {
      return tr('FRONTEND.VERIFY_OTP.TITLE_VERIFY_EMAIL', 'Email verification')
    }
    return tr('FRONTEND.VERIFY_OTP.TITLE_EMAIL', 'Email confirmation')
  }

  return (
    <VerificationContainer data-test='otp-verification-dialog'>
      <div className='title'>{prepareTitle()}</div>
      {totpSecretKeyUri ? (
        <GoogleAuthenticator totpSecretKeyUri={totpSecretKeyUri} />
      ) : (
        <div className='subtitle'>
          {twoFactorType === TwoFactorTypeEnum.TOTP
            ? tr('FRONTEND.VERIFY_OTP.TOTP_TARGET_LABEL', 'Please, enter the verification code:')
            : tr('FRONTEND.VERIFY_OTP.TARGET_LABEL', "Please, enter the verification code that we've sent to:")}
          <span>{twoFactorType === TwoFactorTypeEnum.SMS ? formatPhoneNumber(sentTo) : sentTo}</span>
        </div>
      )}

      <ErrorDisplay error={verifyOtpError} />

      {resendOtpFetching || verifyOtpFetching ? (
        <Spinner />
      ) : (
        <>
          <input
            id='otp'
            type='tel'
            className={cn('input', {
              complete: code?.length === codeSize,
              wrong: code?.length === codeSize && verifyOtpError != null
            })}
            autoComplete='off'
            onChange={e => handleCodeChanged(e.target.value)}
            value={code}
            maxLength={codeSize}
            autoFocus
            placeholder={tr('FRONTEND.VERIFY_OTP.ENTER_CODE', 'Enter Code')}
            disabled={timer.expired || attempts >= globalSettings.otpMaxAttempts}
            data-test='tel-input'
          />

          {challengePurpose !== ChallengePurposeEnum.CHANGE_2FA_TOTP && twoFactorType !== TwoFactorTypeEnum.TOTP && (
            <ResendMessage>
              {attempts < globalSettings.otpMaxAttempts ? (
                <>
                  {tr('FRONTEND.VERIFY_OTP.NOT_RECEIVED', "Haven't received it?")}
                  <span onClick={handleResend} className={cn({ disabled: !resendTimer.expired })}>
                    {resendTimer.expired
                      ? tr('FRONTEND.VERIFY_OTP.RESEND_CODE', 'Re-send code')
                      : resendTimer.remainingTime}
                  </span>
                </>
              ) : (
                tr('FRONTEND.VERIFY_OTP.NO_MORE_ATTEMPTS', 'No more verification attempts.')
              )}
            </ResendMessage>
          )}

          <Button
            type='button'
            color='secondary'
            text={tr('FRONTEND.BUTTONS.CANCEL', 'Cancel')}
            disabled={resendOtpFetching || verifyOtpFetching}
            onClick={internalCancel}
            data-test='button-cancel'
          />
        </>
      )}

      {attempts >= globalSettings.otpMaxAttempts || resendOtpFetching || verifyOtpFetching ? null : (
        <Countdown isExpired={timer.expired}>{timer.formattedTime}</Countdown>
      )}
    </VerificationContainer>
  )
}
