import styled from 'styled-components'

import { breakpoints } from '../../constants'
import { CommonFlexColumn, CommonFlexRow } from '../jetonStyled'

export const HeaderWrapper = styled.div<{ visible: boolean }>`
  display: ${({ visible }) => (visible ? 'grid' : 'none')};
  grid-template-columns: repeat(2, 1fr);
  margin: 0;

  width: 100%;
  height: 100px;
  background-color: #fff;

  .overlay {
    z-index: 10;
    background: rgba(96, 96, 96, 0.5);
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
  }

  a {
    white-space: nowrap;
  }

  .header-left {
    ${CommonFlexRow()}
    justify-content: space-between;
  }

  @media (max-width: ${breakpoints.xl}px) {
    grid-template-columns: 1fr;
    height: fit-content;
    width: 100%;
  }
`

export const HeaderContainer = styled.nav`
  ${CommonFlexRow()}
  align-items: center;
  justify-content: initial;
  gap: 2rem;
  .brand-logo {
    display: block;
    text-align: inherit;
  }

  @media (max-width: ${breakpoints.xl}px) {
    width: 100%;
    justify-content: space-between;
  }
`

export const HeaderInnerContainer = styled.div<{ bordered?: boolean }>`
  justify-self: flex-end;
  ${CommonFlexRow()}

  @media(max-width: 400px) {
    ${CommonFlexColumn()}
  }

  @media (max-width: ${breakpoints.xl}px) {
    width: 100%;
    border-top: ${props => (props.bordered ? '1px solid #e2e4e9' : 'none')};
    & > * {
      border-bottom: ${props => (props.bordered ? '1px solid #e2e4e9' : 'none')};
    }
  }
`
