import {
  useBuyVoucherMutation,
  useBuyVouchersBulkMutation,
  useGetMerchantQuery,
  useMerchantAccounts,
  useSelectedAccount
} from 'mmfintech-backend-api'
import { isValidArray, OtpContext, tr, useFormValues } from 'mmfintech-commons'
import { Currency } from 'mmfintech-commons-types'
import { useContext, useEffect, useMemo, useState } from 'react'
import { BULK_AMOUNTS, PREPARED_AMOUNTS, voucherTypes } from './BuyVoucherTabConstants'
import cn from 'classnames'
import { Button, Checkbox, ErrorDisplay } from 'mmfintech-portal-commons'

import WalletIcon from '../../../images/icons/wallet-icon.svg?react'
import ClockIcon from '../../../images/icons/clock-icon.svg?react'
import SelectAccount from '../../../components/SelectAccount'
import InputAmount from '../../../components/InputAmount'
import { BuyCashVoucherWrapper } from './buyVoucherTab.styled'
import { AmountTabs } from '../amountTabs'

interface BuyVoucherTab {
  onSuccess: () => void
}

export const BuyVoucherTab = ({ onSuccess }: BuyVoucherTab) => {
  const { selectedAccount, selectedAccountId, selectedAccountCurrency, setSelectedAccount } = useSelectedAccount()
  const [buyVoucher, { error: buyVoucherError, isLoading: buyVoucherLoading }] = useBuyVoucherMutation({
    fixedCacheKey: 'buy-voucher-mutation'
  })
  const [buyBulkVoucher, { error: bulkError, isLoading: bulkLoading }] = useBuyVouchersBulkMutation({
    fixedCacheKey: 'buy-voucher-bulk-mutation'
  })
  const {
    activeAccounts: paymentAccounts,
    accountsFetching: paymentAccountsFetching,
    accountsError
  } = useMerchantAccounts({
    validCurrency: (currency?: Currency) => !!currency.supportedForCashVoucher
  })

  const { data: merchant } = useGetMerchantQuery(null)
  const { capabilities } = merchant || {}
  const { setOtpOnSuccess } = useContext(OtpContext)

  // states
  const [selectedVoucherType, setSelectedVoucherType] = useState('single')
  const [selectedAmountTab, setSelectedAmountTab] = useState(null)

  const formValues = useFormValues({
    sourceAccountId: { required: true },
    amount: { required: true },
    currency: { required: true },
    count: { validation: 'numeric' }
  })

  // useEffects

  useEffect(() => {
    if (selectedAccount) {
      formValues.setValue('currency', selectedAccountCurrency)
      formValues.setValue('sourceAccountId', selectedAccountId)
    }
  }, [selectedAccount])

  useMemo(() => {
    if (isValidArray(paymentAccounts) && !selectedAccount) {
      setSelectedAccount(paymentAccounts[0])
    }
  }, [paymentAccounts])

  useEffect(() => {
    if (formValues.getValue('amount')) {
      if (selectedVoucherType === 'single') {
        setSelectedAmountTab(
          PREPARED_AMOUNTS.find(prepAmount => prepAmount.toString() === formValues.getValue('amount').toString())
        )
      } else {
        setSelectedAmountTab(
          BULK_AMOUNTS.find(prepAmount => prepAmount.toString() === formValues.getValue('amount').toString())
        )
      }
    }
  }, [formValues.getValue('amount')])

  useEffect(() => {
    if (selectedAmountTab) {
      formValues.setValue('amount', selectedAmountTab)
    }
  }, [selectedAmountTab])

  // functions

  const handlePurchase = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()

    setOtpOnSuccess(() => (data: any) => {
      if (data && data?.refId) {
        onSuccess()
      }
    })

    if (formValues.areValid()) {
      try {
        selectedVoucherType === 'bulk'
          ? await buyBulkVoucher(formValues.prepare()).unwrap()
          : await buyVoucher(formValues.prepare()).unwrap()
      } catch (err) {
        formValues.handleErrors(err)
      }
    }
  }

  return (
    <BuyCashVoucherWrapper>
      <div className='cash-voucher-top-wrapper'>
        <div className='cash-voucher-top-wrapper-title'>{tr('FRONTEND.CASH_VOUCHERS.BUY.TITLE', 'Buy New')}</div>
        <div className='cash-voucher-type-buttons-wrapper'>
          <div className='cash-voucher-type-buttons-content'>
            {voucherTypes().map(({ label, value }) => {
              if (value === 'bulk' && !capabilities.enableBulkCashVouchers) {
                return null
              }
              return (
                <div
                  key={label}
                  className={cn('cash-voucher-type-button', { selected: value === selectedVoucherType })}
                  onClick={() => setSelectedVoucherType(value)}>
                  <span>{label}</span>
                  <Checkbox checked={value === selectedVoucherType} onClick={() => setSelectedVoucherType(value)} />
                </div>
              )
            })}
          </div>
          {selectedVoucherType === 'bulk' && (
            <div>
              <InputAmount
                label={tr('FRONTEND.CASH_VOUCHERS.NUMBER_LABEL', 'Number of Vouchers')}
                amount={formValues.getValue('count')}
                setAmount={value => formValues.setValue('count', value)}
                error={formValues.getError('count')}
                account={selectedAccount}
                dataTest='voucher-number-input'
              />
            </div>
          )}
        </div>
        <SelectAccount
          accounts={paymentAccounts}
          label={tr('FRONTEND.CASH_VOUCHERS.ACCOUNT_LABEL', 'Select Account')}
          selectedAccount={selectedAccount}
          setSelectedAccount={setSelectedAccount}
          error={accountsError}
          loading={paymentAccountsFetching}
          noShadow
        />
        <InputAmount
          label={tr('FRONTEND.CASH_VOUCHERS.AMOUNT_LABEL', 'Amount')}
          amount={
            selectedVoucherType === 'bulk'
              ? Number(formValues.getValue('amount')) * Number(formValues.getValue('count')) // shown total price for all the vouchers
              : formValues.getValue('amount')
          }
          setAmount={value => formValues.setValue('amount', value)}
          error={formValues.getError('amount')}
          account={selectedAccount}
          dataTest='amount-input'
          paymentOption={{
            status: 'AVAILABLE',
            minAmount: 5,
            maxAmount: selectedVoucherType === 'bulk' ? 200 : 5000
          }}
          disabled={selectedVoucherType === 'bulk'}
        />
        <AmountTabs
          amounts={selectedVoucherType === 'bulk' ? BULK_AMOUNTS : PREPARED_AMOUNTS}
          onChange={setSelectedAmountTab}
          selected={selectedAmountTab}
          currency={selectedAccount ? selectedAccount?.currencyCode : ''}
        />
        <div className='cash-voucher-announce-wrapper'>
          <div className='icon'>
            <WalletIcon />
          </div>
          <span>
            {tr(
              'FRONTEND.CASH_VOUCHER.ANNOUNCE_SUBTITLE1',
              'Once you proceed with purchasing the voucher you will be redirected to the final step on our checkout.'
            )}
          </span>
        </div>
        <div className='cash-voucher-announce-wrapper'>
          <div className='icon'>
            <ClockIcon />
          </div>
          <span>
            {tr('FRONTEND.CASH_VOUCHER.ANNOUNCE_SUBTITLE2', 'Once purchased vouchers have 1 year until expiring.')}
          </span>
        </div>
      </div>
      <div className='cash-voucher-bottom-wrapper'>
        <ErrorDisplay error={[buyVoucherError, bulkError]} />
        <div className='cash-voucher-add-button-wrapper'>
          <Button text='Continue' onClick={e => handlePurchase(e)} loading={buyVoucherLoading || bulkLoading} />
        </div>
      </div>
    </BuyCashVoucherWrapper>
  )
}
