import { useContext, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { actions, getNoInvoicesLabel, paths } from 'mmfintech-backend-api'
import { downloadFile, GlobalContext, tr, useWindowSize } from 'mmfintech-commons'
import { Button, ErrorDisplay, Preloader } from 'mmfintech-portal-commons'

import { RowsMainSection, RowsTopSection, RowsTwoSectionContainer } from '../../components/jetonStyled'
import { breakpoints } from '../../constants'
import InvoiceUpload from './InvoiceUpload'
import InvoicesFilter from './InvoicesFilterPane'
import InvoicesList from './InvoicesList'
import { InvoicesInner, InvoicesTitle } from './styled/invoices.styled'
import useInvoicesFilter from './useInvoicesFilter'

import CustomersIcon from '../../images/icons/customers-icon.svg?react'
import FilterIcon from '../../images/icons/filter-icon.svg?react'
import NewInvoiceIcon from '../../images/icons/new-icon.svg?react'
import UploadIcon from '../../images/icons/upload-invoice.svg?react'

function Invoices(props) {
  const status = props?.match.params.status

  const { modalHide, modalShow, sidebarInnerRightShow, sidebarInnerRightHide } = useContext(GlobalContext)
  const [valueStatus, setValueStatus] = useState(status || '')

  const [width] = useWindowSize()
  const history = useHistory()
  const dispatch = useDispatch()

  const { pagination, invoices, invoicesError, invoicesFetching } = useInvoicesFilter()
  const applyFilter = formValues => {
    if (formValues) {
      setValueStatus(formValues.getValue('status'))
    }
    pagination.reload({ formValues })
    modalHide()
  }

  const handleUploadInvoiceClick = () => {
    modalShow({
      options: { size: 'medium', closeOnClickOutside: false, transparent: true },
      content: <InvoiceUpload onCancel={modalHide} />
    })
  }

  const handleDownloadCsv = () => {
    downloadFile(actions.invoice.createExportToCsvLink({ status: valueStatus }))
  }

  const handleShowFilters = () => {
    modalShow({
      content: <InvoicesFilter status={status} applyFilter={applyFilter} modalHide={modalHide} />
    })
  }
  useEffect(() => {
    if (width >= breakpoints.xl) {
      sidebarInnerRightShow({
        content: <InvoicesFilter status={status} applyFilter={applyFilter} />,
        options: { size: width > breakpoints['2xl'] ? 'extra-large' : 'large' }
      })
    }
    return () => sidebarInnerRightHide()
  }, [dispatch, width])

  return (
    <RowsTwoSectionContainer size='lg'>
      <RowsTopSection>
        <InvoicesInner>
          <Button
            color='round-secondary'
            text={tr('FRONTEND.HEADER.CUSTOMERS', 'Customers')}
            onClick={() => history.push(paths.customers())}
            icon={<CustomersIcon />}
            data-test='button-customers'
          />
          {width < breakpoints.xl ? (
            <div className='filter-icon' onClick={handleShowFilters}>
              <FilterIcon />
            </div>
          ) : (
            <InvoicesTitle centered className='title'>
              {tr('FRONTEND.INVOICES.LIST.TITLE', 'Invoices')}
              {pagination.totalElements > 0 ? (
                <div className='subtitle'>
                  <span data-test='invoices-count'>{pagination.totalElements}</span>{' '}
                  {tr('FRONTEND.INVOICES.LIST.SUBTITLE', 'Invoices Total')}
                </div>
              ) : null}
            </InvoicesTitle>
          )}
          <div className='right-side'>
            <Button
              icon={<UploadIcon />}
              color='round-secondary'
              text={tr('FRONTEND.DASHBOARD.BUTTON_UPLOAD', 'Upload Invoice')}
              onClick={handleUploadInvoiceClick}
              data-test='upload-invoice-button'
            />

            <Button
              icon={<NewInvoiceIcon />}
              color='round-secondary'
              text={tr('FRONTEND.INVOICES.LIST.BUTTON_CREATE', 'Create Invoice')}
              onClick={() => {
                dispatch(actions.invoice.setInvoiceNewOrigin('invoices'))
                history.push(paths.invoices.create())
              }}
              data-test='new-button'
            />
          </div>
        </InvoicesInner>
      </RowsTopSection>
      <RowsMainSection>
        <ErrorDisplay error={invoicesError} />
        {invoicesFetching ? (
          <Preloader />
        ) : (
          <InvoicesList
            emptyMessage={getNoInvoicesLabel(valueStatus)}
            showCustomerColumn
            downloadCsv={handleDownloadCsv}
            pagination={pagination}
            invoices={invoices}
          />
        )}
      </RowsMainSection>
    </RowsTwoSectionContainer>
  )
}

export default Invoices
