import styled from 'styled-components'

export const ConfirmationOfPayeeWrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;

  padding: 4rem 4rem 3rem;
  max-width: 50rem;

  color: black;
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 1.8rem;
  text-align: center;

  .title {
    font-size: 2.4rem;
    font-weight: 600;
    margin: 1.6rem 0;
  }

  .content {
    margin: 0 2rem;
  }

  .buttons {
    margin-top: 4rem;
    width: 100%;

    display: flex;
    flex-flow: column nowrap;
    gap: 1rem;

    .button-secondary {
      //color: #131e3d;
    }
  }
`
