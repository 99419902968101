import { useContext } from "react"

import { GlobalContext, tr } from "mmfintech-commons"
import { Button, ErrorDisplay } from "mmfintech-portal-commons"
import { useCancelInvestmentMutation } from "mmfintech-backend-api"

import { Countdown, WithdrawModalWrapper } from "./styled/withdrawModal.styled"
import useCountdown from "../../hooks/useCountdown"

const WithdrawModal = ({ transactionId }: { transactionId: number }) => {

  const [withdrawInvestment, { error }] = useCancelInvestmentMutation()

  const { modalHide } = useContext(GlobalContext)
  const { seconds, allowed } = useCountdown(5)

  const handleWithdraw = async () => {
    try {
      const result = await withdrawInvestment({
        stakingId: transactionId
      }).unwrap()

      if (result) {
        modalHide()
      }
    } catch (err) { }
  }

  return (
    <WithdrawModalWrapper data-test='withdraw-modal'>
      <div className='title'>{tr('FRONTEND.SAVINGS.WITHDRAW.MODAL.HEADING', 'Withdraw')}</div>
      <div className='disclaimer-text'>
        {tr(
          'FRONTEND.SAVINGS.WITHDRAW.MODAL.TEXT',
          'If you withdraw your funds earlier than the lock-in period, you will loose all earnings so far. Do you still want to proceed?'
        )}
      </div>
      <ErrorDisplay error={error} />
      <div className='buttons-container'>
        <Button
          disabled={!allowed}
          type='submit'
          color='primary'
          text={tr('FRONTEND.SAVINGS.WITHDRAW.MODAL.BUTTON_SUBMIT', 'Yes')}
          onClick={handleWithdraw}
        />
        <Button
          color='secondary'
          text={tr('FRONTEND.SAVINGS.WITHDRAW.MODAL.BUTTON_CANCEL', 'Cancel')}
          onClick={modalHide}
        />
      </div>
      <div className='countdown-wrapper'>
        <Countdown className={allowed && 'allowed'}>{seconds()}</Countdown>
      </div>
    </WithdrawModalWrapper>
  )
}

export default WithdrawModal