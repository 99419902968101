import { useContext, useEffect, useState } from 'react'

import { GlobalContext, isValidArray, tr, useFormValues } from 'mmfintech-commons'
import { useGetConversationThreadHistoryQuery, useSendMessageMutation } from 'mmfintech-backend-api'
import { Button, ErrorDisplay, FileInput, Input, Preloader } from 'mmfintech-portal-commons'

import ConversationConfirm from './elements/ConversationConfirm'
import ConversationLine from './elements/ConversationLine'
import ConversationSuccessModal from './elements/ConversationSuccessModal'
import UploadedFile from './elements/UploadedFile'
import { ConversationModalWrapper } from './styled/conversationModal.styled'

import ConversationUploadIcon from '../../../images/icons/conversation-upload-icon.svg?react'

function ConversationModal({ threadId, type }: { threadId: number; type: string }) {
  const { modalHide, modalShow }: any = useContext(GlobalContext)
  const [showConfirm, setShowConfirm] = useState(false)
  const {
    data: conversation,
    isFetching: conversationFetching,
    error: conversationError
  } = useGetConversationThreadHistoryQuery({ conversationId: threadId })
  const [sendMessage, { isLoading: sendMessageFetching, error: sendMessageError }] = useSendMessageMutation()

  const { setValue, getValue, registerInput, prepare, areValid, handleErrors } = useFormValues({
    files: {},
    message: { required: true, validation: 'safe-string' }
  })

  const handleSendMessage = async () => {
    if (areValid()) {
      try {
        await sendMessage({
          conversationThreadId: threadId,
          ...prepare()
        }).unwrap()

        modalShow({
          options: {
            closeOnClickOutside: true,
            size: 'auto',
            overflow: 'none'
          },
          content: <ConversationSuccessModal modalHide={modalHide} />
        })
      } catch (_err) {}
    }
  }

  const handleChange = (list: File[]) => {
    if (isValidArray(list)) {
      const updatedFiles = [...Array.from(getValue('files')), ...Array.from(list)]
      setValue('files', updatedFiles)
    }
    return false
  }

  const handleRemoveFile = (index: number) => {
    const updatedFiles = [...getValue('files')]
    updatedFiles.splice(index, 1)
    setValue('files', updatedFiles)
  }

  useEffect(() => {
    handleErrors(sendMessageError)
    //eslint-disable-next-line
  }, [sendMessageError])

  return (
    <ConversationModalWrapper>
      <div className='header'>{tr(`FRONTEND.CONVERSATION.BANNER.${type}.TITLE`, type)}</div>
      <div className='conversation-pane'>
        {conversationFetching ? (
          <Preloader />
        ) : (
          isValidArray(conversation) &&
          conversation.map(
            (
              conversationLine: { isAdminMessage: boolean; content: string; type: 'TEXT' | 'DOCUMENT' },
              index: number
            ) => (
              <ConversationLine
                key={index}
                type={conversationLine?.type}
                content={conversationLine?.content}
                isAdminMessage={conversationLine?.isAdminMessage}
              />
            )
          )
        )}
      </div>
      <div className='input-pane'>
        <div className='input-section'>
          <FileInput onChange={handleChange} acceptType={['jpg', 'png', 'webp', 'jpeg', 'pdf']}>
            {({ onFileUpload }: any) => (
              <div className='upload-section'>
                <div className='files'>
                  {isValidArray(getValue('files')) &&
                    getValue('files').map((file: File, index: number) => (
                      <UploadedFile key={index} file={file} onFileRemove={() => handleRemoveFile(index)} />
                    ))}
                </div>
                <ConversationUploadIcon style={{ cursor: 'pointer' }} onClick={onFileUpload} />
              </div>
            )}
          </FileInput>
          <Input
            className='message-input'
            type='textarea'
            {...registerInput('message')}
            data-test='message-input'
            autoFocus
            placeholder='Type your answer here'
          />
        </div>
        <ErrorDisplay error={[sendMessageError, conversationError]} />
        <div className='buttons-container'>
          <Button
            color='secondary'
            text={tr('FRONTEND.BUTTONS.CANCEL', 'Cancel')}
            onClick={modalHide}
            data-test='button-cancel'
          />
          <Button
            color='primary'
            text={tr('FRONTEND.CONVERSATION.MODAL.SUBMIT.BUTTON', 'Submit')}
            onClick={() => {
              if (areValid()) {
                setShowConfirm(true)
              }
            }}
            data-test='button-submit-message'
            loading={sendMessageFetching}
          />
        </div>
      </div>
      {showConfirm && (
        <ConversationConfirm handleConfirm={handleSendMessage} hideConfirmation={() => setShowConfirm(false)} />
      )}
    </ConversationModalWrapper>
  )
}

export default ConversationModal
