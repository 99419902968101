import styled from 'styled-components'

export const DropDownMenuContainer = styled.div`
  position: absolute;
  left: -15rem;
  top: 9rem;
  width: 20rem;
  z-index: 1001;

  background: #ffffff;
  box-shadow: 0 0 25px rgba(46, 53, 56, 0.1);
  border-radius: 8px;

  padding: 1.5rem;

  &.settings {
    left: -5rem;

    display: flex;
    flex-flow: column nowrap;
    gap: 3rem;
    padding: 2rem 2.5rem;
  }

  .menu-item {
    color: #8a99a8;
    font-family: inherit;
    font-size: 1.4rem;
    font-weight: 400;

    justify-content: flex-start;

    text-decoration: none;

    &.logout {
      color: rgba(255, 255, 255, 1);
      border-radius: 4px;
      background: #06f;

      text-align: center;
      padding: 1rem;
    }
  }
`
