import { tr } from 'mmfintech-commons'
import { Button } from 'mmfintech-portal-commons'
import styled from 'styled-components'

import AllowedIcon from '../../images/icons/qualify-allowed.svg?react'
import NotAllowedIcon from '../../images/icons/qualify-not-allowed.svg?react'

function QualifyModal({ modalHide }) {
  return (
    <QualifyModalWrapper data-test='referral-how-to-qualify-modal'>
      <div className='heading'>{tr('FRONTEND.REFERRAL.QUALIFY.TITLE', 'How to Qualify?')}</div>
      <div className='content-wrapper'>
        <div>
          <AllowedIcon />
        </div>
        <div className='content'>
          {tr('FRONTEND.REFERRAL.QUALIFY.LINE1', 'Your friends need to sign up with the unique invitation link.')}
        </div>
      </div>
      <div className='content-wrapper'>
        <div>
          <AllowedIcon />
        </div>
        <div className='content'>
          {tr('FRONTEND.REFERRAL.QUALIFY.LINE2', 'You both need to complete your onboarding (if it is applicable).')}
        </div>
      </div>
      <div className='content-wrapper'>
        <div>
          <AllowedIcon />
        </div>
        <div className='content'>
          {tr('FRONTEND.REFERRAL.QUALIFY.LINE3', 'Your referral rewards will be paid out monthly.')}
        </div>
      </div>
      <div className='content-wrapper'>
        <div>
          <AllowedIcon />
        </div>
        <div className='content'>
          {tr(
            'FRONTEND.REFERRAL.QUALIFY.LINE4',
            "The percentage for the rewards calculation is tier based, depending on your friends' monthly transactional volumes."
          )}
        </div>
      </div>
      <div className='content-wrapper'>
        <div>
          <NotAllowedIcon />
        </div>
        <div className='content'>
          {tr(
            'FRONTEND.REFERRAL.QUALIFY.LINE5',
            "Any transfer between Jetonbank accounts doesn't count toward this program."
          )}
        </div>
      </div>
      {/* <div className='content termsAndConditions'>
        <a href={KingdomReferralTermsAndConditions} rel='noreferrer' target='_blank'>
          {tr(
            'FRONTEND.REFERRAL.QUALIFY.TERMS_AND_CONDITIONS',
            'The Kingdom Bank referral program Terms and Conditions'
          )}
        </a>
      </div> */}
      <Button
        className='close-button'
        text={tr('FRONTEND.REFERRAL.QUALIFY.CLOSE_BUTTON', 'Close')}
        onClick={modalHide}
      />
    </QualifyModalWrapper>
  )
}

export default QualifyModal

const QualifyModalWrapper = styled.div`
  width: 100%;
  max-width: 57rem;
  padding: 4rem;
  display: flex;
  flex-flow: column nowrap;

  border-radius: 8px;
  @media (max-width: 565px) {
    max-width: 35rem;
    min-width: 35rem;
    padding: 2rem;
  }
  .heading {
    font-size: 2.4rem;
    font-weight: 400;
    padding-bottom: 2rem;

    text-align: center;
    @media (max-width: 565px) {
      font-size: 3rem;
      padding: 1rem;
    }
  }
  .content-wrapper {
    display: flex;
    align-items: flex-start;
    margin: 1rem 0;
    .content {
      font-size: 1.6rem;
      padding: 0 1rem;
      @media (max-width: 565px) {
        font-size: 1.6rem;
      }
    }
  }
  .close-button {
    width: 100%;
    max-width: 40rem;

    align-self: center;
    margin: 2rem 0 1rem 0;
  }
  .termsAndConditions {
    font-size: 1.4rem;
    text-align: center;
    margin-top: 2rem;
  }
`
