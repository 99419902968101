import { StepperWrapper } from './Stepper.style'

type TStepperProps = {
  className?: string
  currentStep: number
  maxStep: {
    step: number
    label: string
  }[]
}

export const Stepper = ({ className = '', currentStep, maxStep }: TStepperProps) => {
  return (
    <StepperWrapper className={className}>
      {maxStep.map(({ step, label }) => {
        return (
          <div key={step} className={`single-step-wrapper ${step === currentStep ? 'active' : ''} `}>
            <div className='--step'>
              <div>{step}</div>
            </div>
            {label && <div className='--label'>{label}</div>}
          </div>
        )
      })}
    </StepperWrapper>
  )
}
