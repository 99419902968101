import styled from 'styled-components'

import { breakpoints } from '../../constants'
import { tablePadding } from '../../components/styled/table.styled'

export const RecipientsContainer = styled.div`
  ${tablePadding}
  .checkbox-cell {
    width: 4rem;
    text-align: center;
  }

  .view-button-cell {
    margin-bottom: 0;
    .button {
      width: 100%;
    }
  }

  @media (min-width: 750px) {
    .view-button-cell {
      .button {
        max-width: 24rem;
        margin: 0 auto;
      }
    }
  }

  @media (min-width: ${breakpoints.xl}px) {
    .data-table-body {
      .data-table-row {
        &:hover {
          .arrow-right-cell {
            svg {
              visibility: visible;
            }
          }
        }
      }
    }

    .view-button-cell {
      display: none;
    }

    .arrow-right-cell {
      background: #fff;
      svg {
        visibility: hidden;
      }
      span {
        display: flex;
        align-items: center;
        height: 100%;
      }
    }
  }

  .account-details {
    font-size: 1.4rem;
    line-height: 2rem;

    @media (min-width: 750px) {
      text-align: right;
    }
    @media (min-width: 1200px) {
      text-align: left;
    }
  }

  .row {
    .label {
      margin-right: 0.6rem;
    }
    .value {
      word-wrap: break-word;
    }
  }

  @media (min-width: ${breakpoints.xl}px) {
    .second-line {
      .row {
        display: inline-block;

        :after {
          content: '|';
          color: #939ea7;
          padding: 0 0.8rem;
        }

        :last-of-type:after {
          content: '';
        }
      }
    }
  }
`
export const RecipientsInner = styled.div`
  position: relative;
  width: 100%;
  margin: 0 auto;
  .button-container {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    visibility: hidden;
    &.visible {
      visibility: visible;
    }
  }
  [data-test='recipients-title'] {
    text-align: center;
  }
`
