import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

import { paths } from 'mmfintech-backend-api'
import { useWindowSize } from 'mmfintech-commons'

import { breakpoints } from '../constants'

const isPathExcluded = (pathname: string) => {
  const excludedPaths = [
    paths.login(),
    paths.signUp(),
    paths.checkEmail(),
    paths.passwordReset(),
    paths.forgotPassword(),
    paths.changePasswordEmail(),
    paths.handlePasswordReset(),
    paths.handlePasswordResetInvited(),
    '/invoice/'
  ]

  return excludedPaths.some(path => pathname.includes(path))
}

const hasSpecialPathPattern = (pathname: string) => {
  const specialPatterns = [
    '/invoice/',
    '/account/recipients/',
    '/invoices/add',
    '/invoices/edit',
    '/security',
    '/user-management',
    '/profile',
    '/send',
    '/deposit',
    '/exchange',
    '/developer'
  ]

  return specialPatterns.some(pattern => pathname.substring(0, pattern.length) === pattern)
}

const useHideHeaders = () => {
  const [visible, setVisible] = useState(false)
  const location = useLocation()
  const [width] = useWindowSize()

  useEffect(() => {
    const { pathname } = location

    let stateVisibility = true // default to true

    if (isPathExcluded(pathname)) {
      stateVisibility = false
    } else if (width < breakpoints.xl && hasSpecialPathPattern(pathname)) {
      stateVisibility = false
    }

    if (stateVisibility !== visible) {
      setVisible(stateVisibility)
    }
  }, [location.pathname, visible, width])

  return visible
}

export default useHideHeaders
