import styled from 'styled-components'

export const WelcomeContent2Styled = styled.div`
  max-width: 652px;
  margin: 0 auto;

  .logo {
    margin-bottom: 4.8rem;
    display: flex;
    justify-content: center;
  }

  .title {
    font-size: 1.2rem;
    font-weight: 500;
    line-height: 1.5rem;
    text-align: center;
    color: #000000;
    margin-bottom: 16px;
  }

  .description {
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 1.5rem;
    text-align: left;
    color: #000000;
  }
`

export const Success2FaStyled = styled.div`
  height: 50px;

  .success-2fa-content {
    padding: 13px;
    display: flex;
    gap: 10px;
    border-radius: 10px;
    background-color: #3cd1a3;
    align-items: center;
    width: 100%;

    .success-2fa-text {
      display: grid;

      .title-success {
        font-size: 1.4rem;
        font-weight: 400;
        line-height: 1.6rem;
        color: white;
      }
    }
  }
`
