import { useEffect, useState } from 'react'

import moment from 'moment'

import { Button, Input } from 'mmfintech-portal-commons'

import { checkSingleValue, fixDateOnly, isValidArray, packObject, tr, useFilter } from 'mmfintech-commons'
import {
  getTransactionStatusOptions,
  useCurrencies,
  useMerchantAccounts,
  usePaymentMethods
} from 'mmfintech-backend-api'

import SelectAccount from '../../../components/SelectAccount'
import { FilterButtons, FilterWrapper, FiltersContainer } from './styled/transactionsFilter.styled'

import CloseIcon from '../../../images/icons/icon-close-transparent.svg?react'
import CalendarIcon from '../../../images/icons/filter-calendar.svg?react'

const TransactionsFilter = ({ initialFilter, applyFilter, modalHide = null }) => {
  const [selectedAccount, setSelectedAccount] = useState(null)

  const { currencyOptionsSimple } = useCurrencies()
  const { paymentMethodOptions } = usePaymentMethods()
  const { accounts: paymentAccounts } = useMerchantAccounts()

  const filter = useFilter(
    {
      ...initialFilter,
      from: new Date(initialFilter.from),
      to: new Date(initialFilter.to)
    },
    ['reference', 'description', 'customerEmail'],
    ['from', 'to', 'statuses']
  )

  const prepareFilter = () =>
    packObject({
      ...filter.prepare(),
      ...(filter.get('statuses') ? { statuses: filter.get('statuses').split(',') } : null),
      from: fixDateOnly(filter.get('from')),
      to: fixDateOnly(filter.get('to'))
    })

  const handleApply = () => {
    applyFilter(prepareFilter())
    typeof modalHide === 'function' && modalHide()
  }

  const handleClearAll = () => {
    filter.reset()
    filter.set('from', moment().add(-1, 'month').toDate() as any)
    filter.set('to', moment().toDate() as any)
    filter.set('paymentMethods', [])
    setSelectedAccount(null)
    applyFilter({
      from: fixDateOnly(moment().add(-1, 'month').toDate()),
      to: fixDateOnly(moment().toDate())
    })
    typeof modalHide === 'function' && modalHide()
  }

  const getCurrencyOptions = () => [
    { value: '', label: tr('FRONTEND.TRANSACTIONS.FILTER.ALL_CURRENCIES', 'All') },
    ...currencyOptionsSimple
  ]

  const handleSelectAccount = (account: any) => {
    const { id } = account || {}
    setSelectedAccount(account)
    filter.set('accountId', id)
  }

  useEffect(() => {
    if (isValidArray(paymentAccounts) && !selectedAccount) {
      const selectedAccountId = Number(filter.get('accountId'))
      if (selectedAccountId) {
        const find = paymentAccounts.find(account => account.id === selectedAccountId)
        setSelectedAccount(find)
      }
    }
  }, [paymentAccounts])

  return (
    <FilterWrapper>
      <FiltersContainer>
        <div className='top-row'>
          <h3>{tr('FRONTEND.TRANSACTIONS.FILTER.TITLE', 'Filters')}</h3>
          {typeof modalHide === 'function' && <CloseIcon onClick={modalHide} />}
        </div>

        <SelectAccount
          showAllAccounts
          accounts={paymentAccounts}
          label={tr('FRONTEND.TRANSACTIONS.FILTER.ACCOUNT', 'Account')}
          selectedAccount={selectedAccount}
          setSelectedAccount={handleSelectAccount}
        />

        <div className='multiple-inputs mt-2'>
          <Input
            key='from'
            name='from'
            type='date'
            maxDate={new Date(filter.get('to'))}
            label={tr('FRONTEND.TRANSACTIONS.FILTER.FROM_DATE', 'From date')}
            value={filter.get('from')}
            onChange={(_: string, value: any) => filter.set('from', value)}
            data-test='filter-from-date'
            icon={<CalendarIcon />}
            hideErrorLine
          />

          <Input
            id='to'
            name='to'
            type='date'
            minDate={new Date(filter.get('from'))}
            maxDate={moment().toDate()}
            label={tr('FRONTEND.TRANSACTIONS.FILTER.TO_DATE', 'To date')}
            {...filter.registerInput('to')}
            data-test='filter-to-date'
            icon={<CalendarIcon />}
            hideErrorLine
          />
        </div>

        <div className='multiple-inputs'>
          <Input
            id='transaction_id'
            name='id'
            type='number'
            label={tr('FRONTEND.TRANSACTIONS.FILTER.TRANSACTION_ID', 'ID')}
            {...filter.registerInput('id')}
            data-test='filter-id'
            hideErrorLine
            forceLabel
          />

          <Input
            id='reference'
            name='reference'
            type='text'
            label={tr('FRONTEND.TRANSACTIONS.FILTER.REFERENCE', 'Reference')}
            {...filter.registerInput('reference')}
            data-test='filter-reference'
            hideErrorLine
            forceLabel
          />
        </div>

        <Input
          id='foreign_id'
          name='foreignTransactionId'
          type='text'
          label={tr('FRONTEND.TRANSACTIONS.FILTER.FOREIGN_ID', 'Foreign ID')}
          {...filter.registerInput('foreignTransactionId')}
          data-test='filter-foreign-id'
          hideErrorLine
          forceLabel
          styles={styles}
        />

        <Input
          id='description'
          name='description'
          type='text'
          label={tr('FRONTEND.TRANSACTIONS.FILTER.CUSTOMER_EMAIL', 'Customer email')}
          {...filter.registerInput('description')}
          data-test='filter-customer'
          hideErrorLine
          forceLabel
          styles={styles}
        />

        <Input
          id='payment_methods'
          name='paymentMethods'
          type='select'
          className='payment-methods'
          label={tr('FRONTEND.TRANSACTIONS.FILTER.PAYMENT_METHOD', 'Payment method')}
          {...filter.registerInput('paymentMethods')}
          options={paymentMethodOptions}
          data-test='filter-payment-method'
          hideErrorLine
          isMultiSelect
          isSearchable={false}
          forceLabel
        />

        <div className='multiple-inputs'>
          <Input
            id='statuses'
            name='statuses'
            type='select'
            label={tr('FRONTEND.TRANSACTIONS.FILTER.STATUS', 'Status')}
            {...filter.registerInput('statuses')}
            options={[
              { value: '', label: tr('FRONTEND.TRANSACTIONS.FILTER.STATUS_ALL', 'All statuses') },
              ...getTransactionStatusOptions()
            ]}
            data-test='filter-status'
            hideErrorLine
            isSearchable={false}
            forceLabel
          />

          <Input
            id='currency'
            name='currency'
            type='select'
            label={tr('FRONTEND.TRANSACTIONS.FILTER.CURRENCY', 'Currency')}
            {...filter.registerInput('currency')}
            options={getCurrencyOptions()}
            data-test='filter-currency'
            hideErrorLine
            forceLabel
          />
        </div>

        <div className='multiple-inputs'>
          <Input
            id='from_amount'
            name='fromAmount'
            type='text'
            label={tr('FRONTEND.TRANSACTIONS.FILTER.FROM_AMOUNT', 'From amount')}
            value={filter.get('fromAmount')}
            onChange={(name: string, value: string) => {
              filter.set(name, checkSingleValue(value, { validation: 'float' }))
            }}
            data-test='filter-from-amount'
            hideErrorLine
            forceLabel
          />

          <Input
            id='to_amount'
            name='toAmount'
            type='text'
            label={tr('FRONTEND.TRANSACTIONS.FILTER.TO_AMOUNT', 'To amount')}
            value={filter.get('toAmount')}
            onChange={(name: string, value: string) => {
              filter.set(name, checkSingleValue(value, { validation: 'float' }))
            }}
            data-test='filter-to-amount'
            hideErrorLine
            forceLabel
          />
        </div>
      </FiltersContainer>

      <FilterButtons>
        <Button
          type='button'
          color='round-secondary'
          text={tr('FRONTEND.TRANSACTIONS.FILTER.APPLY_BUTTON', 'Apply')}
          onClick={handleApply}
          data-test='button-apply'
        />
        <Button
          id='clear-all'
          type='button'
          color='delete'
          onClick={handleClearAll}
          text={tr('FRONTEND.TRANSACTIONS.FILTER.CLEAR_ALL_BUTTON', 'Clear all')}
          data-test='reset-filter-button'
        />
      </FilterButtons>
    </FilterWrapper>
  )
}

const styles = {
  container: {
    width: '100%'
  }
}

export default TransactionsFilter
