import { useContext } from 'react'

import cn from 'classnames'

import { GlobalContext, tr } from 'mmfintech-commons'

import CardSettingsItem from './CardSettingsItem'
import { CardSettingsList } from './CardSettingsList'

import AdvancedCardOptions from './modals/AdvancedCardOptions'
import EditCardPin from './modals/EditCardPin'
import ReplaceCard from './modals/ReplaceCard'
import StatementHistory from './modals/StatementHistory'
import TerminateCard from './modals/TerminateCard'
import UnfreezeCard from './modals/UnfreezeCard'

import CloudIcon from '@images/icons/clock.svg?react'
import PhysicalCardIcon from '@images/icons/card_icon.svg?react'
import CloseIcon from '@images/icons/icon-close-transparent.svg?react'
import { CardSettingsWrapper } from './cardSettings.styled'

interface CardSettingsProps {
  cardType?: 'virtual' | 'physical'
  cardNumber?: string
  cardDetails?: any
  onClose?: () => void
}

export const CardSettings = ({ cardType, cardNumber = '', cardDetails, onClose }: CardSettingsProps) => {
  const { modalShow } = useContext(GlobalContext)
  // const { activeAccounts } = useMerchantAccounts()
  // const cardAccount = activeAccounts?.find(account => !!account.prepaidCardAccount) || {}
  // const cardBankDetails = () => {
  //   if (isValidArray(cardAccount?.dedicatedMerchantBankAccounts)) {
  //     return cardAccount?.dedicatedMerchantBankAccounts[0]
  //   } else {
  //     return {}
  //   }
  // }

  const isValidCardSetting = (key: string) => {
    const fail1 = cardDetails && cardDetails?.status != 'FROZEN' && key == 'unfreezeCard'
    const fail2 = key === 'changePin' && cardType === 'virtual'
    return !fail1 && !fail2
  }

  const handleOpenModal = (key: string, options?: any) => {
    switch (key) {
      case 'advancedOptions':
        modalShow({
          options: { closeOnClickOutside: false },
          // header: tr('FRONTEND.CARDS.SETTINGS.MODAL.ADVANCED_OPTIONS.TITLE', 'Advanced card options'),
          content: (
            <AdvancedCardOptions
              supportedOperations={cardDetails?.supportedOperations}
              prepaidCardId={cardDetails?.id}
            />
          )
        })
        break
      case 'changePin':
        modalShow({
          options: { closeOnClickOutside: true },
          header: tr('FRONTEND.CARDS.SETTINGS.MODAL.CHANGE_PIN.TITLE', 'Change PIN'),
          content: <EditCardPin prepaidCardId={cardDetails?.id} isSet={true} />
        })
        break
      case 'replaceCard':
        modalShow({
          options: { closeOnClickOutside: true },
          header:
            cardType == 'physical'
              ? tr('FRONTEND.CARDS.SETTINGS.MODAL.REPLACE_CARD.PHYSICAL.TITLE', 'Replace your Physical card')
              : tr('FRONTEND.CARDS.SETTINGS.MODAL.REPLACE_CARD.VIRTUAL.TITLE', 'Replace your Virtual card'),
          content: <ReplaceCard cardNumber={options?.cardNumber} cardType={options?.cardType} />
        })
        break
      case 'unfreezeCard':
        modalShow({
          options: { closeOnClickOutside: true },
          header: tr('FRONTEND.CARDS.SETTINGS.MODAL.UNFREEZE_CARD.TITLE', 'Unfreeze your card'),
          content: <UnfreezeCard prepaidCardId={cardDetails?.id} />
        })
        break
      case 'statementHistory':
        modalShow({
          options: { closeOnClickOutside: true },
          header: tr('FRONTEND.CARDS.SETTINGS.MODAL.STATEMENT_HISTORY.TITLE', 'Statement history'),
          content: <StatementHistory cardDetails={cardDetails} />
        })
        break
      case 'terminateCard':
        modalShow({
          options: { closeOnClickOutside: true },
          // header: tr('FRONTEND.CARDS.SETTINGS.MODAL.TERMINATE_CARD.TITLE', 'Terminate card'),
          content: <TerminateCard prepaidCardId={cardDetails?.id} />
        })
        break
      default:
      //nothing
    }
  }

  return (
    <CardSettingsWrapper>
      <div className='top-section'>
        <h3>{tr('FRONTEND.CARDS.SETTINGS.TITLE', 'Card settings')}</h3>
        <CloseIcon onClick={onClose && onClose} />
      </div>
      <div className='caption'>
        {cardType == 'physical'
          ? tr('FRONTEND.CARDS.SETTINGS.CARD_PHYSICAL', 'Physical card')
          : tr('FRONTEND.CARDS.SETTINGS.CARD_VIRTUAL', 'Virtual card')}
      </div>
      <div className='card-settings-bank-details'>
        <div className={cn('card-settings-card-number', { virtual: cardType == 'virtual' })}>
          <div className='card-setting-number-value'>
            {cardNumber && cardNumber.length > 4
              ? cardNumber
                  .toString()
                  .match(/.{1,4}/g)
                  .join(' ')
              : `**** **** **** ${cardNumber.slice(-4)}`}
          </div>
          {cardType === 'physical' ? <PhysicalCardIcon /> : <CloudIcon />}
        </div>
        <div className='card-holder-name'>{cardDetails?.cardHolderName}</div>
        {/* <div className='card-bank-detail'>
          <span className='detail-label'>{tr('FRONTEND.CARDS.SETTINGS.DETAILS.IBAN', 'IBAN')}</span>
          <span className='detail-value'>{cardBankDetails()?.iban}</span>
        </div>
        <div className='card-bank-detail'>
          <span className='detail-label'>{tr('FRONTEND.CARDS.SETTINGS.DETAILS.BANK_NAME', 'Bank name')}</span>
          <span className='detail-value'>{cardBankDetails()?.bankName}</span>
        </div>
        <div className='card-bank-detail'>
          <span className='detail-label'>{tr('FRONTEND.CARDS.SETTINGS.DETAILS.BANK_CODE', 'Bank Code')}</span>
          <span className='detail-value'>{cardBankDetails()?.bankCode}</span>
        </div> */}
      </div>
      <div className='caption'>{tr('FRONTEND.CARDS.SETTINGS.MANAGE', 'Manage')}</div>
      <div className='card-settings-manage-section'>
        {CardSettingsList.manage.map(element => {
          const { icon, titleKey, titleValue, key } = element
          return (
            <CardSettingsItem
              key={titleKey}
              icon={icon}
              title={tr(titleKey, titleValue)}
              clickHandler={() => handleOpenModal(key)}
            />
          )
        })}
      </div>
      {CardSettingsList.settings.some(entry => isValidCardSetting(entry.key)) && (
        <>
          <div className='caption'>{tr('FRONTEND.CARDS.SETTINGS.SETTINGS', 'Settings')}</div>
          <div className='card-settings-actions-section'>
            {CardSettingsList.settings.map(element => {
              const { icon, titleKey, titleValue, key } = element
              return isValidCardSetting(key) ? (
                <CardSettingsItem
                  key={titleKey}
                  icon={icon}
                  title={tr(titleKey, titleValue)}
                  clickHandler={() => handleOpenModal(key, { cardNumber, cardType })}
                />
              ) : null
            })}
          </div>
        </>
      )}
    </CardSettingsWrapper>
  )
}
