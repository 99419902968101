import styled from 'styled-components'

export const RecipientEditWrapper = styled.div`
  display: grid;
  place-items: center;

  position: relative;
  padding: 4.5rem 2.5rem;

  @media (min-width: 400px) {
    padding: 4.5rem;
  }

  width: 50rem;
  max-width: 95vw;

  @media (min-width: 960px) {
    &.wide {
      width: 95rem;
    }
  }
`

export const RecipientEditForm = styled.form`
  margin: 0;
  padding: 0;
  width: 100%;

  .title {
    display: block;
    text-align: center;
    margin-top: 2rem;
    margin-bottom: 1rem;

    &.single {
      margin-bottom: 5rem;
    }

    color: #131e3d;
    font-family: inherit;
    font-size: 2.4rem;
    font-style: normal;
    font-weight: normal;
    line-height: 2.9rem;
  }

  .subtitle {
    display: block;
    text-align: center;
    margin-bottom: 5rem;

    color: #131e3d;
    font-family: inherit;
    font-size: 1.4rem;
    font-style: normal;
    font-weight: normal;
    line-height: 1.7rem;
  }

  .spacer {
    margin-top: 5rem;
  }

  div.message-wrapper {
    margin-bottom: 5rem;

    & > span.message {
      color: #a3b8c2;
      font-size: 1.4rem;
    }
  }

  div.buttons {
    display: flex;
    justify-content: center;
    margin-top: 5rem;

    button {
      max-width: 40rem;
    }
  }
`

export const FormElementsContainer = styled.div`
  width: 100%;

  @media (min-width: 960px) {
    .wide & {
      display: grid;
      grid-gap: 5rem;
      grid-template-columns: 1fr;
      row-gap: 0;
    }
  }
`
