import React from 'react'
import * as ReactDOM from 'react-dom'
import 'react-datepicker/dist/react-datepicker.css'
import 'react-loading-skeleton/dist/skeleton.css'
import 'react-phone-input-2/lib/style.css'
// import 'react-redux-toastr/lib/css/react-redux-toastr.min.css'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'

import 'react-circular-progressbar/dist/styles.css'

import App from './App'
import './i18n'
import './index.css'
import { store } from './store'
import { Analytics } from 'mmfintech-commons'
import { apiConfig, configuration } from 'mmfintech-backend-api'

ReactDOM.render(
  <React.StrictMode>
    <Analytics
      enabled={configuration.isProduction()}
      codes={[apiConfig.VITE_GTM_CODE_ONE as string, apiConfig.VITE_GTM_CODE_TWO as string]}
    />
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </React.StrictMode>,
  document.getElementById('jetonbank-app')
)
