import { useEffect } from 'react'

import { fixFloat, useFormValues, UseFormValuesReturn } from 'mmfintech-commons'
import {
  isOwnerOrAdministrator,
  selectCurrentUserId,
  selectCurrentUserRole,
  useAppSelector,
  useGetMerchantUserAccessQuery,
  useGetMyPermissionsQuery,
  useInviteUserMutation,
  useUpdateUserMutation
} from 'mmfintech-backend-api'

import { CreateMerchantUserRequest } from 'mmfintech-commons-types'

// const READ_PERMISSION = 'READER'
const WRITE_PERMISSION = 'WRITER'

export const useAddUser = () => {
  const [inviteUser, { error: invitedCustomerError, isLoading: invitedCustomerLoading, isSuccess: isSuccessCreate }] =
    useInviteUserMutation()
  const [updateUser, { error: updatedCustomerError, isLoading: updatedCustomerLoading, isSuccess: isSuccessUpdate }] =
    useUpdateUserMutation()
  const formValues: UseFormValuesReturn<CreateMerchantUserRequest> = useFormValues({
    firstName: { required: true, validation: 'name' },
    lastName: { required: true, validation: 'name' },
    email: { required: true, validation: 'email' },
    customerRole: { required: true }
  })

  const handleCreate = () => {
    inviteUser({ ...formValues.prepare(), monthlyVolume: fixFloat(formValues.getValue('monthlyVolume')) })
  }

  const handleEdit = (userId: number) => {
    updateUser({
      userId,
      monthlyVolume: fixFloat(formValues.getValue('monthlyVolume')),
      ...formValues.prepare()
    })
  }

  useEffect(() => {
    if (invitedCustomerError || updatedCustomerError) {
      formValues.handleErrors(invitedCustomerError || updatedCustomerError)
    }
  }, [invitedCustomerError, updatedCustomerError])

  return {
    formValues,
    handleCreate,
    handleEdit,
    isLoadingModifyUser: invitedCustomerLoading || updatedCustomerLoading,
    isSuccessModifyUser: isSuccessCreate || isSuccessUpdate,
    errorModifyUser: invitedCustomerError || updatedCustomerError
  }
}

export const useUserPermissions = (props?: { customerId?: number }) => {
  const { customerId } = props || {}
  const userId = useAppSelector(selectCurrentUserId)
  const {
    data: userPermissions,
    isFetching: userPermissionsFetching,
    error: userPermissionsError
  } = useGetMerchantUserAccessQuery(
    { userId: customerId },
    {
      skip: !customerId
    }
  )
  const {
    data: myPermissions,
    isFetching: myPermissionsFetching,
    error: myPermissionsError
  } = useGetMyPermissionsQuery(userId, {
    skip: !!customerId
  })

  return {
    isLoading: myPermissionsFetching || userPermissionsFetching,
    error: myPermissionsError || userPermissionsError,
    permissions: myPermissions || userPermissions
  }
}

export const useHasWritePermissions = (accountId?: string) => {
  const { isLoading, error, permissions } = useUserPermissions()
  const customerRole = useAppSelector(selectCurrentUserRole)

  if (isOwnerOrAdministrator(customerRole)) return true
  if (isLoading || error) return false

  if (accountId) {
    const permission = permissions?.find(
      (perm: any) => perm.accountId === accountId && perm.accessLevel === WRITE_PERMISSION
    )

    return !!permission
  }

  const permission = permissions?.find((perm: any) => perm.accessLevel === WRITE_PERMISSION)
  return !!permission
}
