import { useEffect, useState } from 'react'

interface useCountdownResult {
    allowed: boolean,
    seconds: () => string,
    reset: () => void
}

const useCountdown = (initialSeconds: number = 5): useCountdownResult => {
    const [allowed, setAllowed] = useState(false)
    const [seconds, setSeconds] = useState(initialSeconds)

    useEffect(() => {
        if (seconds > 0) {
            const interval = setInterval(() => {
                setAllowed(seconds <= 1)
                setSeconds(prevState => prevState - 1)
            }, 1000)
            return () => clearInterval(interval)
        } else {
            if (!allowed) {
                setAllowed(true)
            }
        }
        // eslint-disable-next-line
    }, [seconds])

    const format = (): string => {
        return allowed ? '00:00' : `00:${seconds < 10 ? '0' : ''}${seconds}`
    }

    const reset = () => {
        setAllowed(false)
        setSeconds(initialSeconds)
    }

    return { allowed, seconds: format, reset }
}

export default useCountdown
