import styled from 'styled-components'

import MissingCard from '../../../images/missing-card.png'

export const MissingCards = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  margin-top: 4rem;
  gap: 4rem;

  .missing-cards-title {
    font-size: 24px;
    font-weight: 600;
    line-height: 28.13px;
    text-align: center;
    color: rgba(19, 30, 61, 1);
  }
  .missing-card-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 85rem;
    background-color: #ffffff;
    border-radius: 2.4rem;
    align-items: center;
    justify-content: center;

    .missing-card-content-wrapper {
      z-index: 0;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: calc(100% - 4rem);
      width: calc(100% - 4rem);
      margin: 2.4rem;
      padding: 4rem;
      background-image: url('${MissingCard}');
      border-radius: 1.6rem;
      background-size: cover;
      align-items: center;
      flex: 1;

      .missing-card-wrapper {
        display: flex;
        justify-content: start;
        flex-direction: column;
        align-items: center;
        color: #020617;
        h4 {
          font-size: 24px;
          font-weight: 600;
          line-height: 28.13px;
          text-align: center;

          margin: 0 0 0.8rem;
        }
        p {
          font-weight: 500;
          font-size: 1.4rem;
          line-height: 1.68rem;
          max-width: 55rem;
          opacity: 0.5;
          margin: 0;
          line-height: 2rem;
        }

        img {
          max-width: 45rem;
          margin: 3.2rem;
        }
        .missing-card-text {
          display: flex;
          flex-direction: column;
          align-items: center;
        }
        svg {
          margin-top: 5rem;
        }
      }
    }

    .missing-card-actions {
      padding: 2.4rem 0;
      max-width: 41rem;
      width: 100%;
    }
  }
`
