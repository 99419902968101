import React from 'react'

import styled from 'styled-components'

import { breakpoints, devices } from '../constants'

import BackgroundImage from '../images/pattern.png'

export const CommonFlexRow = (gap?: number) => `
display: flex;
flex-flow: row nowrap;
${typeof gap === 'number' ? `gap: ${gap}rem;` : ''}`

export const CommonFlexColumn = (gap?: number) => `
display: flex;
flex-flow: column nowrap;
${typeof gap === 'number' ? `gap: ${gap}rem;` : ''}`

export const CommonSignupWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;

  //prettier-ignore
  background-image: url("${BackgroundImage}");
  background-repeat: no-repeat;
  background-size: cover;
  @media ${devices.sm} {
    padding-bottom: 10rem;
  }
`

export const CommonSignupContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;

  height: 100%;

  .err-msg-wrapper {
    display: flex;
    justify-content: center;
  }

  @media ${devices.sm} {
    justify-content: space-between;
  }
`

export const CommonSignupContent = styled.div`
  width: 92vw;
  max-width: 480px;

  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0 0 25px rgba(46, 53, 56, 0.1);
  padding: 3rem;
  margin-bottom: 3rem;

  @media (min-width: 480px) {
    padding: 4rem;
  }

  .heading {
    color: #314787;
    font-family: inherit;
    font-size: 3.2rem;
    font-style: normal;
    font-weight: 700;
    line-height: 102.52%;
    letter-spacing: -0.025em;

    margin-bottom: 3rem;

    .subtitle {
      color: #131e3d;
      font-size: 1.6rem;
      font-weight: 400;
      line-height: 102.52%;
      letter-spacing: -0.025em;
      margin-top: 1rem;
    }
  }

  .input-wrapper {
    margin-bottom: 2rem;
    //.show-password,
    //label {
    //  font-size: 1rem;
    //  font-weight: 400;
    //  line-height: 1.2rem;
    //}
    /* input {
      background: #ffffff;
      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus,
      &:-webkit-autofill:active {
        -webkit-box-shadow: 0 0 0 3rem #f8f8f8 inset !important;
        box-shadow: 0 0 3rem 3rem #f8f8f8 inset !important;
        background: #ffffff !important;
      }
      &::placeholder {
        color: #85a0ad;
      }
    } */
  }

  .button {
    font-family: inherit;
    font-size: 1.6rem;
    font-weight: 600;
    line-height: 1.9rem;
  }
`

export const signupPhoneInputStyles = {
  containerStyle: {
    background: 'transparent'
  },
  inputStyle: {
    background: '#ffffff',
    border: 'none',
    paddingLeft: '5rem',
    paddingRight: '16px',
    fontSize: '1.4rem',
    lineHeight: '1.7rem',
    // height: '3.75rem',
    width: '100%'
  },
  buttonClass: 'area-code-button',
  buttonStyle: {
    background: 'transparent',
    border: 'none',
    width: '5rem'
  }
}

export const signupSelectInputStyles = {
  container: (provided: React.CSSProperties) => ({
    ...provided,
    width: '100%'
  }),
  control: (provided: React.CSSProperties) => ({
    ...provided,
    background: 'transparent',
    border: 'none',
    boxShadow: 'none',
    cursor: 'pointer',
    height: '4.5rem'
  }),
  valueContainer: (provided: React.CSSProperties) => ({
    ...provided,
    fontSize: '1.4rem',
    lineHeight: '1.7rem',
    padding: 0
  }),
  dropdownIndicator: (provided: React.CSSProperties) => ({
    ...provided,
    paddingRight: 0,
    color: '#85a0ad'
  }),
  placeholder: (provided: React.CSSProperties) => ({
    ...provided,
    color: '#85a0ad',
    fontSize: '1.4rem',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '1.7rem'
  }),
  option: (provided: React.CSSProperties, { isFocused }: { isFocused: boolean }) => ({
    ...provided,
    color: isFocused ? '#f8f8f8 !important' : '#131E3D !important',
    cursor: 'pointer',
    fontSize: '1.4rem',
    lineHeight: 'normal',
    margin: '0',
    background: isFocused ? '#1a1a1a !important' : '#ffffff !important'
  })
}

export const CommonMessageContainer = styled.div`
  background: #ffffff;
  border-radius: 1rem;
  box-shadow: 0 0 25px rgba(46, 53, 56, 0.1);

  display: flex;
  flex-flow: column nowrap;
  align-items: center;

  width: 92vw;
  max-width: 50rem;
  padding: 5rem 2rem;

  color: #454c56;
  font-family: inherit;
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.9rem;
  text-align: center;
  letter-spacing: -0.025em;

  .icon {
    margin-bottom: 2.5rem;
  }

  .heading {
    color: #131e3d;
    font-size: 2.4rem;
    font-weight: 600;
    line-height: 2.9rem;
    text-align: center;
    letter-spacing: normal;

    margin-bottom: 1.2rem;
  }

  .button {
    //max-width: 20rem;
    margin-top: 2.5rem;
  }

  @media (min-width: 480px) {
    padding: 4rem 5rem;
  }
`

export const CommonPageTitle = styled.div<{ centered?: boolean; normalFontWeight?: boolean }>`
  color: #131e3d;
  font-family: inherit;
  font-style: normal;

  font-size: 1.8rem;
  font-weight: 400;
  line-height: normal;
  margin-top: 3rem;
  text-align: ${({ centered }) => (centered ? 'center' : 'initial')};
  @media ${devices.xl} {
    margin-top: 0;
    font-size: 2.4rem;
    font-weight: ${({ normalFontWeight }) => (normalFontWeight ? '400' : '600')};
    line-height: 2.9rem;
  }

  .subtitle {
    color: #85a0ad;
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 1.5rem;
    margin-top: 5px;
  }
`

const Page = styled.div`
  display: grid;
  gap: 1.5rem;
  width: 100%;

  margin-top: 5rem;

  @media (max-width: ${breakpoints.xl}px) {
    padding-top: 3rem;
    margin-top: 0;
  }
`

const PageColumns = styled(Page)`
  grid-auto-flow: column;

  @media (max-width: ${breakpoints.xl}px) {
    grid-auto-flow: row;
  }
`

const PageRows = styled(Page)`
  grid-auto-flow: row;
`

export const ColumnThreeSectionContainer = styled(PageColumns)<{ size?: 'lg' | 'xl' }>`
  max-width: ${({ size }) => (size === 'lg' ? '880px' : size === 'xl' ? '1190px' : 'auto')};
  grid-template-areas: 'left center right';

  ${CommonFlexColumn(2)}
  padding: 2rem;
  @media ${devices.xl} {
    display: grid;
    grid-template-columns: 30rem 50rem 30rem;
  }
  @media ${devices['2xl']} {
    grid-template-columns: 33rem 50rem 33rem;
  }
`

export const ColumnTwoSectionContainer = styled(PageColumns)<{
  size?: 'lg' | 'xl' | 'md'
  additionalStyles?: React.CSSProperties
}>`
  max-width: ${({ size }) => (size === 'lg' ? '880px' : size === 'xl' ? '1370px' : size === 'md' ? '720px' : 'auto')};
  grid-template-columns: 33rem auto;
  grid-template-areas: 'left center';
  padding: 2rem;
  ${({ additionalStyles }) => ({ ...additionalStyles })}

  @media(max-width: ${breakpoints.xl}px) {
    max-width: 100%;
    ${CommonFlexColumn(2)}
    padding: 2rem;
  }
`

export const ColumnMainSectionContainer = styled.div`
  display: grid;
  grid-area: center;
  align-content: normal;
  height: 100%;
  gap: 2rem;

  @media ${devices.xl} {
    align-content: start;
  }
`

export const ColumnLeftSectionContainer = styled.div`
  display: grid;
  grid-area: left;
  align-content: start;
  gap: 2rem;

  .select-method-title {
    font-size: 18px;
    font-style: normal;
    font-weight: 400;

    margin: 1rem 0;
  }
`
export const ColumnRightSectionContainer = styled.div`
  display: grid;
  grid-area: right;
  align-content: start;
  gap: 2rem;
`
// setup for heading, mid controls, table
export const RowsThreeSectionContainer = styled(PageRows)<{ size?: 'lg' | 'xl' | 'md' }>`
  max-width: ${({ size }) => (size === 'md' ? '880px' : size === 'lg' ? '1130px' : size === 'xl' ? '1440px' : 'auto')};
  align-items: stretch;
  grid-template-rows: 30px 40px auto;
  grid-template-areas: 'top' 'center' 'bottom';
  grid-row-gap: 3.5rem;
  ${CommonFlexColumn(2)}
  padding: 2rem;

  @media ${devices.xl} {
    display: grid;
  }
`

export const RowsTwoSectionContainer = styled(PageRows)<{ size?: 'lg' | 'xl' | 'md' }>`
  max-width: ${({ size }) => (size === 'md' ? '880px' : size === 'lg' ? '1170px' : size === 'xl' ? '1440px' : 'auto')};
  grid-template-rows: 40px auto;
  grid-template-areas: 'top' 'center';
  grid-row-gap: 3.5rem;

  ${CommonFlexColumn(2)}
  padding: 2rem;
  @media ${devices.xl} {
    display: grid;
  }
`

export const RowsTopSection = styled.div`
  display: grid;
  grid-area: top;
  align-content: start;
`
export const RowsMainSection = styled.div`
  display: grid;
  grid-area: center;
  align-content: start;

  .pagination-buttons-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 2rem;

    position: initial;
    flex-flow: column nowrap;
    @media (min-width: ${breakpoints.xl}px) {
      position: relative;
      flex-flow: row nowrap;
    }

    .transactions-pagination,
    .invoices-pagination {
      @media ${devices.xs} {
        position: initial;
      }
      @media (min-width: ${breakpoints.xl}px) {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }

  .err-msg-wrapper {
    justify-content: center;
  }
`
export const RowsBottomSection = styled.div`
  display: grid;
  grid-area: bottom;
  align-content: start;
`
