import { useHistory } from 'react-router-dom'

import { paths, useGetTransactionsQuery } from 'mmfintech-backend-api'
import { formatMoney, isValidArray } from 'mmfintech-commons'
import { TransactionHistoryFilterRequest } from 'mmfintech-commons-types'
import { Button } from 'mmfintech-portal-commons'

// TODO: Extend for more actions like send/exchange
import { LastActionWrapper } from './styled/lastAction.styled'

import DepositIcon from '../images/deposit-icon.svg?react'

export function LastAction() {
  const history = useHistory()
  const transactionFilter: TransactionHistoryFilterRequest = {
    page: 0,
    size: 1,
    direction: 'DEPOSIT',
    paymentFlows: ['DEPOSIT'],
    sort: 'reqTime-desc,id-desc'
  }
  const { lastTransactions } = useGetTransactionsQuery(transactionFilter, {
    selectFromResult: ({ data }) => ({
      lastTransactions: isValidArray(data?.content) ? data.content : []
    })
  })
  const handleDepositAgain = () => {
    history.push(
      `${paths.banking.deposit()}/?accountId=${lastTransactions[0].accountId}&amount=${lastTransactions[0].amount}`
    )
  }

  return isValidArray(lastTransactions) ? (
    <LastActionWrapper>
      <h3>Last Deposit</h3>
      <div className='action-container'>
        <div className='action-container-inner-bg'>
          <div className='action-container-inner-content'>
            <DepositIcon />
            <span className='amount'>{formatMoney(lastTransactions[0].amount, lastTransactions[0].currency)}</span>
          </div>
        </div>
        <Button
          type='button'
          color='primary'
          className='deposit-again-button'
          text='Deposit Again'
          onClick={handleDepositAgain}
          data-test='button-back'
        />
      </div>
    </LastActionWrapper>
  ) : null
  // TODO: Add fallback component to display
}
