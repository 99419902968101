export const CheckmarkIcon = ({ color = '#0066ff' }) => (
  <svg width='11' height='8' viewBox='0 0 11 8' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M9.3252 1.07764L3.7868 7.07764L1.3252 4.41107'
      stroke={color}
      strokeWidth='1.5586'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)
