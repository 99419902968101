import { formatDate, tr } from 'mmfintech-commons'

import { TierWrapper } from './styled/referralTierPane.styled'

import InfoIcon from '../../images/icons/information-icon.svg?react'

function ReferralTierPane({ tiers, referralTier, lastTierUpdate }) {
  const tierValue = referralTier?.match(/\d/)[0]
  const qualifier = tiers?.find(tier => tier.attributeName === referralTier)?.qualifier
  // const prepareToolTip = () => {
  //   const text = []
  //   text.push('<br/>')
  //   text.push('<b>')
  //   text.push(tr('FRONTEND.REFERRAL.PROGRAM.TOOLTIP.TITLE', 'Commission Rates:'))
  //   text.push('</b>')
  //   text.push('<br/>')
  //   text.push('<br/>')

  //   tiers?.forEach((tier, index, arr) => {
  //     const { qualifier, attributeName, value } = tier
  //     text.push(
  //       tr(
  //         'FRONTEND.REFERRAL.PROGRAM.TOOLTIP.LINE',
  //         '<b>Tier %TIER%:</b> <span>%PERCENTAGE%%</span><br/>%VOLUME% Volume/Monthly'
  //       )
  //         .replace('%TIER%', attributeName.match(/\d/)[0])
  //         .replace('%PERCENTAGE%', qualifier)
  //         .replace('%VOLUME%', formattedValue(value, arr[index + 1]?.value, 'EUR'))
  //     )
  //     text.push('<br/>')
  //     text.push('<br/>')
  //   })
  //   return text.join('')
  // }
  return (
    <TierWrapper>
      <span className='tier'>
        {tr('FRONTEND.REFERRAL.PROGRAM.TIER', 'Tier: %tier% (%qualifier%%)')
          .replace('%tier%', tierValue || '')
          .replace('%qualifier%', qualifier || '')}
      </span>

      <span className='tier-date'>
        {tr('FRONTEND.REFERRAL.PROGRAM.TIER.DATE', 'as of %date%').replace('%date%', formatDate(lastTierUpdate))}
      </span>
      <span className='icon' data-for='tooltip' data-tip='true'>
        <InfoIcon />
      </span>
    </TierWrapper>
  )
}

export default ReferralTierPane
