import styled from 'styled-components'

export const CardOrderModalWrapper = styled.div<{ large: boolean }>`
  padding: 4rem;
  min-width: ${({ large }) => (large ? '75rem' : '63rem')};
  .card-order-header-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 2rem;
    margin-bottom: 3rem;
    .card-order-stepper {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
    }
    .card-order-header-title {
      font-size: 24px;
      font-weight: 600;
      line-height: 28.13px;
      text-align: center;
      color: #131e3d;
    }
  }

  .card-order-type-selection {
    display: flex;
    flex-direction: column;
    gap: 1.6rem;

    padding-bottom: 4rem;

    .card-order-flag-wrapper {
      height: 14rem;
      position: relative;
      transition: height 0.3s ease;
      &.open {
        height: 21rem;
      }

      /* .card-selection-background {
        width: 100%;
        height: 100%;
        position: absolute;
        background-color: #ffffff;
        transition: all 0.3s ease;
        top: 0;
        border-radius: 1.6rem;

        &.card-background-selected {
          top: -8px;
          box-shadow: var(--card-order-modal-background-shadow);
          scale: 1.02;
        }
      } */

      .card-order-card-type-container {
        padding: 1.3rem;
        display: flex;
        flex-direction: row;
        margin: 0;
        gap: 1.6rem;
        border-radius: 1.6rem;
        background-color: #ffffff;
        z-index: 1;
        position: relative;
        border: 1px solid #dcdfe5;
        cursor: pointer;

        svg {
          display: none;
          position: absolute;
          right: 1.2rem;
          top: 1.2rem;
        }

        &.card-selected {
          border: none;
          &.types {
            margin: 0.1rem;
          }
          &:not(.types) {
            border: 1px solid #6bcdfd;
          }
        }

        .card-order-card-content {
          .card-order-card-title {
            font-size: 14px;
            font-weight: 500;
            line-height: 16.41px;
            text-align: left;
            color: #131e3d;
            margin-bottom: 0.8rem;
          }
          .card-order-card-text {
            font-size: 12px;
            font-weight: 400;
            line-height: 14.06px;
            text-align: left;
            color: #575e6c;
            max-width: 39rem;
          }
        }

        .card-order-card-icon {
          position: relative;
          svg {
            display: block;
            position: unset;
          }
        }
        .card-brand-icon {
          position: absolute;
          z-index: 1;
          top: 10px;
          right: 10px;
        }
        .card-order-check-icon {
          position: absolute;
          z-index: 2;
          top: 15px;
          right: 22px;

          .check-box-component {
            margin: 0;
          }
        }
      }

      .card-order-choose-card-brand {
        height: 0;
        display: flex;
        align-items: flex-end;
        padding: 0;
        position: absolute;
        width: 100%;
        overflow: hidden;
        top: 0;
        left: 0;
        z-index: 0;
        border-radius: 1.6rem;
        transition: all 0.3s ease;
        gap: 1.6rem;
        &.open {
          height: 190px;
          padding: 1.6rem;
          border: 1px solid #6bcdfd;
        }

        .card-order-brand-wrapper {
          display: flex;
          height: 4.8rem;
          align-items: center;
          padding: 0.8rem 1.6rem;
          justify-content: space-between;
          background-color: rgba(107, 205, 253, 0.2);
          border: 1px solid transparent;
          border-radius: 1.6rem;
          width: 100%;
          cursor: pointer;
          span {
            @extend %bodyS-regular;
          }
          .card-order-chose-brand {
            display: flex;
            align-items: center;
            gap: 0.4rem;
          }
          &:hover {
            background-color: #ffffff;
            border: 1px solid #6bcdfd;
          }
          &.selected {
            background-color: #ffffff;
            border: 1px solid #6bcdfd;
          }
        }
      }
      &.card-unavailable {
        opacity: 50%;
        cursor: default;
        position: relative;
        overflow: hidden;
        user-select: none;

        .card-order-card-type-container {
          cursor: default;
        }
      }
    }
  }

  .card-order-form-wrapper {
    color: var(--primary-text-color);
    .card-order-form-title {
      @extend %body-medium;
    }

    .card-order-form-subtitle {
      @extend %body-regular;
      margin-bottom: 2.4rem;
    }

    .core-input-wrapper,
    .card-order-form-name-title {
      .core-input-section {
        height: 5.6rem;
      }
    }

    .selection-element-wrapper {
      min-height: 5.6rem;
    }

    .card-order-form-footer {
      @extend %caption-medium;
      margin: 1.6rem auto;
      text-align: center;

      a {
        text-decoration: none;
        outline: none;
        color: var(--red-text-color);
      }
    }

    .card-order-form-inputs {
      display: flex;
      flex-direction: column;
      gap: 1.6rem;
      padding-bottom: 3.2rem;
      border-bottom: 1px solid var(--card-order-modal-background-color);
    }

    .card-order-form-name {
      width: 100%;
      display: flex;
      gap: 1.6rem;

      .card-order-form-name-title {
        width: 12rem;
      }

      .card-order-form-name-value {
        flex: 1;
      }
    }

    .card-order-form-surname {
      margin-bottom: 2.4rem;
    }
  }

  .card-order-issuing-message {
    text-align: center;
  }

  .card-order-success-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 2.4rem;
    padding: 2.4rem 0;

    .card-order-success-title {
      font-size: 18px;
      font-weight: 400;
      line-height: 21.09px;
      text-align: center;
      margin-bottom: 2rem;
      color: rgba(19, 30, 61, 1);
    }

    .card-order-success-subtitle {
      font-size: 14px;
      font-weight: 400;
      line-height: 18.2px;
      text-align: center;
      color: #000000;
      margin-bottom: 2rem;
    }

    img {
      width: 20rem;
      padding: 3.2rem 0 6.4rem;
    }
  }

  .card-order-phone-section {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
    gap: 3rem;
    margin-bottom: 3rem;

    .card-order-phone-icon {
      width: 9.5rem;
      height: 9.5rem;
      border-radius: 50%;
      background-color: var(--card-order-phone-background);
      display: flex;
      align-items: center;
      justify-content: center;
      box-shadow: var(--card-order-phone-shadow);

      svg {
        height: 5.4rem;
        width: 5.4rem;
      }
    }

    .card-order-phone-instructions {
      width: 100%;
      margin: 2.4rem;
      display: flex;
      justify-content: center;
    }

    .core-input-wrapper {
      width: 100%;
      margin-bottom: 13rem;
    }
  }
  .card-order-currency-select {
    padding: 3.2rem 0;
    min-height: 30rem;
  }
  .card-order-modal-buttons-wrapper {
    display: flex;
    flex-direction: column;
    gap: 1.6rem;
    .card-order-modal-back-button {
      background-color: #fff;
      color: rgba(19, 30, 61, 1);
      &:hover {
        background-color: rgba(19, 30, 61, 1);
        color: #fff;
      }
    }
  }
  .card-order-checkbox {
    position: relative;
    .checkbox-icon {
      border: none;
      border-radius: 50%;
      position: relative;
      width: 1rem !important;
      height: 1rem !important;
      background-color: transparent;
    }
    &.checked {
      .checkbox-icon {
        background-image: none;
        background-color: #6bcdfd;
      }
    }
    ::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 1.9rem;
      height: 1.9rem;
      border-radius: 50%;
      border: 1px solid #dcdfe5;
      z-index: 0;
    }
  }
`
