import styled from 'styled-components'

export const RecipientPreviewWrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  gap: 5rem;
  padding: 2rem;
  .top-section {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    svg {
      cursor: pointer;
    }
  }
  .list {
    height: 100%;
    overflow-y: auto;
  }
`

export const RecipientPreviewData = styled.div`
  display: flex;
  flex-flow: column nowrap;
  gap: 1rem;
  padding: 1.5rem 0;
  border-bottom: 1px solid #e2e4e9;

  color: #131e3d;

  .label {
    color: rgba(19, 30, 61, 1);
    font-size: 1.6rem;
    font-weight: 700;
  }

  span {
    font-size: 2rem;
    overflow-wrap: anywhere;
    word-break: break-all;
  }
`
