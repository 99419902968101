import { useEffect, useState } from 'react'

import { useGetMerchantQuery } from 'mmfintech-backend-api'
import { OnboardingStatusEnum } from 'mmfintech-commons-types'

const getFraction = progress => {
  const numerator = progress / 25
  const denominator = 4

  return `${numerator}/${denominator}`
}

export const useOnboardingProgress = () => {
  const { data: merchant } = useGetMerchantQuery()
  const { onboardingStatus } = merchant || {}
  const [currentProgress, setCurrentProgress] = useState<25 | 50 | 75 | 100>(25)

  useEffect(() => {
    switch (onboardingStatus) {
      case OnboardingStatusEnum.IN_PROGRESS:
      case OnboardingStatusEnum.UNDER_REVIEW:
      case OnboardingStatusEnum.WAITING_COMPLIANCE_APPROVAL:
        setCurrentProgress(50)
        break
      case OnboardingStatusEnum.WAITING_ADDITIONAL_DOCUMENTS:
      case OnboardingStatusEnum.WAITING_BUSINESS_AGREEMENT_APPROVAL:
      case OnboardingStatusEnum.WAITING_OPS_APPROVAL:
        setCurrentProgress(75)
        break
      case OnboardingStatusEnum.APPROVED:
        setCurrentProgress(100)
        break
    }
  }, [onboardingStatus])

  return {
    currentProgress,
    progressFraction: getFraction(currentProgress)
  }
}
