import React, { createContext, useContext, useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'

import { AnimatePresence } from 'framer-motion'
import { MobileBanner, PillButton } from './styled/sumSubBanner.styled'
import { OnboardingContainer, OnboardingProgress } from './elements/BannerElements'

import { breakpoints } from '../../constants'
import { useGetMerchantQuery } from 'mmfintech-backend-api'
import { useOnboardingProgress } from '../../hooks'
import { GlobalContext, useWindowSize } from 'mmfintech-commons'

import { MerchantAccountTypeEnum } from 'mmfintech-commons-types'

import ArrowIcon from '../../images/icons/arrow-outlined.svg?react'

export const SumSubContext = createContext({
  setIsVisible: null,
  isVisible: false
})

const SumSubBanner: React.FC = () => {
  const { data: merchant, isLoading } = useGetMerchantQuery()
  const { accountType } = merchant || {}

  const [width] = useWindowSize()
  const [isVisible, setIsVisible] = useState(false)

  const isMobile = width < breakpoints.xl

  if (isLoading) return null

  if (accountType === MerchantAccountTypeEnum.PROSPECT) {
    return (
      <SumSubContext.Provider value={{ setIsVisible, isVisible }}>
        {isMobile ? <SumSubBannerMobile /> : <SumSubBannerDesktop />}
      </SumSubContext.Provider>
    )
  }
  return null
}

export default SumSubBanner

const SumSubBannerDesktop: React.FC = () => {
  const { pathname } = useLocation()
  const { setIsVisible, isVisible } = useContext(SumSubContext)
  const { progressFraction } = useOnboardingProgress()
  const buttonRef = useRef<HTMLButtonElement>(null)
  const [containerPosition, setContainerPosition] = useState({ bottom: '84px', right: '46px' })

  const updateContainerPosition = () => {
    if (buttonRef.current) {
      const buttonRect = buttonRef.current.getBoundingClientRect()
      setContainerPosition({
        bottom: `calc(100vh - (${buttonRect.bottom - buttonRef.current.offsetHeight}px) + 10px)`,
        right: `calc(100vw - ${buttonRect.right}px)`
      })
    }
  }

  useEffect(() => {
    updateContainerPosition()
    window.addEventListener('resize', updateContainerPosition)

    // Cleanup function to remove event listener on component unmount
    return () => {
      window.removeEventListener('resize', updateContainerPosition)
    }
  }, [buttonRef])

  useEffect(() => {
    setIsVisible(false)
  }, [pathname])

  const toggleVisibility = () => {
    setIsVisible(!isVisible)
  }
  return (
    <>
      <PillButton ref={buttonRef} onClick={toggleVisibility} isActive={isVisible}>
        <p>Onboarding</p>
        {' | '}
        <p>{progressFraction} Complete</p>
      </PillButton>
      <AnimatePresence>
        {isVisible && (
          <OnboardingContainer
            toggleOpen={toggleVisibility}
            style={{ bottom: containerPosition.bottom, right: containerPosition.right }}
          />
        )}
      </AnimatePresence>
    </>
  )
}

const SumSubBannerMobile: React.FC = () => {
  const { modalHide, modalShow } = useContext(GlobalContext)

  const handleOpenModal = () => {
    modalShow({
      content: <OnboardingContainer toggleOpen={modalHide} />
    })
  }
  return (
    <MobileBanner onClick={handleOpenModal}>
      <OnboardingProgress noMargin={true} isBanner={true} />
      <div className='right'>
        <div className='banner-container'>
          <p>Onboarding</p>
          <div className='ml-2'>
            <ArrowIcon />
          </div>
        </div>
        <span>Complete your account activation</span>
      </div>
    </MobileBanner>
  )
}
