import styled from 'styled-components'

export const ModalContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 3rem;

  width: 100%;
  max-width: 50rem;
  position: relative;
  margin: auto;
`

export const WelcomeModalWrapper = styled.div`
  width: 100%;
  max-width: 64rem;
  padding: 4rem;

  border-radius: 1rem;
  background-color: #ffffff;

  .welcome-modal-content {
    display: flex;
    flex-flow: column nowrap;
    width: 100%;

    .stepper {
      margin-top: 20px;
      margin-bottom: 52px;
    }

    .title-welcome {
      font-size: 2.4rem;
      font-weight: 600;
      line-height: 2.8rem;
      text-align: center;
    }

    .buttons-wrapper {
      margin-top: 40px;
      display: grid;
      grid-gap: 10px;
    }
  }

  @media (max-width: 480px) {
    margin: auto;
  }
`
