import { tr } from 'mmfintech-commons'

import { FiltersDropDown } from './FilterDropDown'

import DotsIcon from '../../../images/icons/dots-icon.svg?react'
import PdfIcon from '../../../images/icons/pdf-icon.svg?react'
import TransactionDetailsIcon from '../../../images/icons/transfer-details-icon.svg?react'

export const TransactionsMoreDropdown = ({ handleTransactionReceiptClick, handleDetailsClick }) => {
  return (
    <FiltersDropDown
      alternative={true}
      Icon={DotsIcon}
      dropDown={
        <div className='dropdown-container'>
          <div onClick={handleDetailsClick}>
            <TransactionDetailsIcon />
            <p>{tr('FRONTEND.TRANSACTIONS.VIEW_DETAILS_BUTTON', 'View Details')}</p>
          </div>
          {typeof handleTransactionReceiptClick === 'function' && (
            <div onClick={handleTransactionReceiptClick}>
              <PdfIcon />
              <p>Download Receipt</p>
            </div>
          )}
        </div>
      }
    />
  )
}
