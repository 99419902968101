import styled from 'styled-components'

export const DashboardWrapper = styled.div`
  .left-side {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    gap: 4rem;
  }

  .right-side {
    display: flex;
    flex-direction: column;
    gap: 4rem;
    .card-banner-container {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  @media (max-width: 767px) {
    .left-side,
    .right-side {
      gap: 2rem;
    }
  }
`
