import React from 'react'

import cn from 'classnames'

import { useDropDownMenu } from 'mmfintech-commons'

import { DropDownButton, DropDownContent, DropDownWrapper } from './styled/filterDropDown.styled'

interface FiltersDropDownProps {
  Icon?: any
  label?: string
  dropDown: React.ReactElement
  alternative?: boolean
}

export const FiltersDropDown: React.FC<FiltersDropDownProps> = ({ Icon, label = '', dropDown, alternative }) => {
  const { visible, toggleDropDown } = useDropDownMenu()

  return (
    <DropDownWrapper>
      {alternative ? (
        typeof Icon === 'function' ? (
          <div className='icon-container' onClick={toggleDropDown}>
            <Icon />
          </div>
        ) : null
      ) : (
        <DropDownButton className={cn({ opened: visible })} onClick={toggleDropDown}>
          <span>
            {typeof Icon === 'function' && (
              <span className='icon'>
                <Icon />
              </span>
            )}
            {label}
          </span>
        </DropDownButton>
      )}
      <DropDownContent className={cn({ opened: visible })} alternative={alternative}>
        {dropDown}
      </DropDownContent>
    </DropDownWrapper>
  )
}
