import AdvancedOptionsIcon from '@images/icons/card-settings-advanced.svg?react'
import ChangePinIcon from '@images/icons/card-settings-pin.svg?react'
import StatementHistoryIcon from '@images/icons/card-settings-history.svg?react'
import TerminateCardIcon from '@images/icons/card-settings-terminate.svg?react'

export const CardSettingsList = {
  manage: [
    {
      icon: <StatementHistoryIcon />,
      titleKey: 'FRONTEND.CARDS.SETTINGS.STATEMENT_HISTORY.TITLE',
      titleValue: 'Statement history',
      key: 'statementHistory'
    },
    {
      icon: <AdvancedOptionsIcon />,
      titleKey: 'FRONTEND.CARDS.SETTINGS.ADVANCED_OPTIONS.TITLE',
      titleValue: 'Advanced card options',
      key: 'advancedOptions'
    }
  ],
  settings: [
    {
      icon: <ChangePinIcon />,
      titleKey: 'FRONTEND.CARDS.SETTINGS.CHANGE_PIN.TITLE',
      titleValue: 'Change PIN',
      key: 'changePin'
    },
    // {
    //   icon: <UnfreezeCardIcon />,
    //   titleKey: 'FRONTEND.CARDS.SETTINGS.UNFREEZE_CARD.TITLE',
    //   titleValue: 'Unfreeze card',
    //   key: 'unfreezeCard'
    // },
    // {
    //   icon: <ReplaceCardIcon />,
    //   titleKey: 'FRONTEND.CARDS.SETTINGS.REPLACE_CARD.TITLE',
    //   titleValue: 'Replace card',
    //   key: 'replaceCard'
    // }
    {
      icon: <TerminateCardIcon />,
      titleKey: 'FRONTEND.CARDS.SETTINGS.TERMINATE_CARD.TITLE',
      titleValue: 'Terminate card',
      key: 'terminateCard'
    }
  ]
}
