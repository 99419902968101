import styled from 'styled-components'

export const BuyCashVoucherWrapper = styled.div`
  .cash-voucher-top-wrapper {
    display: flex;
    flex-direction: column;
    padding: 3.2rem 3.2rem 1.6rem 3.2rem;
    gap: 3.2rem;
    border-bottom: 1px solid var(--kingdom-cash-content-bottom-border);
    max-width: 50rem;
    background-color: white;
    border-radius: 1rem;

    .cash-voucher-top-wrapper-title {
      font-size: 1.8rem;
      text-align: center;
      line-height: 2.1rem;
    }

    .cash-voucher-type-buttons-wrapper {
      display: flex;
      width: 100%;
      height: 100%;
      gap: 1.6rem;
      flex-direction: column;

      .cash-voucher-type-buttons-content {
        display: flex;
        gap: 1.6rem;
        font-size: 1.4rem;
        .cash-voucher-type-button {
          display: flex;
          align-items: center;
          flex-direction: row;
          width: 100%;
          border-radius: 0.8rem;
          padding: 1.2rem;
          user-select: none;
          justify-content: space-between;
          border: 1px solid #dcdfe5;
          cursor: pointer;

          &.selected {
            border-color: #6bcdfd;
          }

          .custom-checkbox {
            .checkbox-icon {
              border-radius: 50%;
              background: unset;
              position: relative;
              border-color: #dcdfe5;
            }
            &.checked {
              .checkbox-icon::after {
                content: '';
                border-radius: 50%;
                width: 10px;
                height: 10px;
                position: absolute;
                left: 0;
                right: 0;
                top: 2px;
                margin-inline: auto;
                background-color: #6bcdfd;
              }
            }
          }

          .cash-voucher-type-icon {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 4.8rem;
            height: 4.8rem;
            border-radius: 1.2rem;
          }

          .cash-voucher-type-button-checkbox {
            width: unset;
            position: absolute;
            right: 0;
          }
        }
      }
    }

    .cash-voucher-announce-wrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 0.8rem;
      font-size: 1.2rem;
    }
  }

  .cash-voucher-bottom-wrapper {
    display: flex;
    flex-direction: column;
    gap: 3.2rem;
    padding: 3.2rem 0;
  }
`
