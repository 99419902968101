import { useCookies } from 'react-cookie'
import { useEffect } from 'react'

import { useGetMerchantQuery } from 'mmfintech-backend-api'
import { tr } from 'mmfintech-commons'

import WelcomeGa from '@images/icons/welcome_authenticator.svg?react'
import PointIcon from '@images/icons/why-ga-point.svg?react'

import { GoogleAuthenticatorInfoWrapper } from './GoogleAuthenticatorInfo.styled'

const WHY_GA_POINTS = () => [
  {
    title: tr('FRONTEND.WELCOME.MODAL.STEP_1.WHY_GA_1.TITLE', 'Better Protection'),
    description: tr(
      'FRONTEND.WELCOME.MODAL.STEP_1.WHY_GA_1.DESCRIPTION',
      'Google Authenticator generates codes directly on your phone, so you’re protected from SIM-swapping and phishing attacks that can affect SMS or email.'
    )
  },
  {
    title: tr('FRONTEND.WELCOME.MODAL.STEP_1.WHY_GA_2.TITLE', 'Works Offline'),
    description: tr(
      'FRONTEND.WELCOME.MODAL.STEP_1.WHY_GA_2.DESCRIPTION',
      'You don’t need an internet connection—your 2FA codes are always available in the app, anytime, anywhere.'
    )
  },
  {
    title: tr('FRONTEND.WELCOME.MODAL.STEP_1.WHY_GA_3.TITLE', 'Faster & Reliable'),
    description: tr(
      'FRONTEND.WELCOME.MODAL.STEP_1.WHY_GA_3.DESCRIPTION',
      'No waiting for texts or emails. Just open the app, get your code, and you’re good to go!'
    )
  }
]

export const GoogleAuthenticatorInfo = ({ isNewUser = true }: { isNewUser?: boolean }) => {
  const [cookies, setCookie] = useCookies(['authenticator'])
  const { data: merchant } = useGetMerchantQuery()
  const { merchantId } = merchant || {}

  useEffect(() => {
    if (merchantId) {
      setCookie('authenticator', JSON.stringify([...(cookies?.authenticator || []), merchantId]))
    }
  }, [merchantId])

  return (
    <GoogleAuthenticatorInfoWrapper>
      {isNewUser && (
        <div className='title'>{tr('FRONTEND.WELCOME.MODAL.STEP_1.TITLE', 'We support Google Authenticator!')}</div>
      )}
      <div className='logo'>
        {' '}
        <WelcomeGa />
      </div>

      {!isNewUser && (
        <div className='title-old'>
          {tr('FRONTEND.WELCOME.MODAL.STEP_1.TITLE_OLD', 'We now support Google Authenticator!')}
        </div>
      )}

      <p className='description'>
        {tr(
          'FRONTEND.WELCOME.MODAL.STEP_1.DESCRIPTION',
          'We care about your security and want to help you protect your account better. By switching to Google Authenticator for two-factor authentication (2FA), you’ll get a more secure and reliable way to log in compared to SMS or email.'
        )}
      </p>
      <div className='why-ga'>
        <span className='why-ga-title'>
          {tr('FRONTEND.WELCOME.MODAL.STEP_1.WHY_GA', 'Here’s why Google Authenticator is a great choice:')}
        </span>
        <div className='why-ga-content'>
          {WHY_GA_POINTS().map(({ title, description }) => {
            return (
              <div className='why-ga-content-item'>
                <PointIcon />
                <p>
                  <b>{title}:</b> {description}
                </p>
              </div>
            )
          })}
          <span className='why-ga-description'>
            {tr(
              'FRONTEND.WELCOME.MODAL.STEP_1.WHY_GA_DESCRIPTION',
              'Want to make your account even safer? Click Enable now to set up Google Authenticator in a few easy steps.'
            )}
          </span>
        </div>
      </div>
    </GoogleAuthenticatorInfoWrapper>
  )
}
