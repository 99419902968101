import { memo, useContext } from 'react'

import isEqual from 'lodash.isequal'
import PropTypes from 'prop-types'

import { GlobalContext, tr } from 'mmfintech-commons'
import { Button } from 'mmfintech-portal-commons'

import { ConfirmationButtons, ConfirmationContent, ConfirmationTitle } from './styled/confirmationModal.styled'

function ConfirmationModal({
  title = '',
  question,
  onConfirm,
  onCancel = undefined,
  confirmCaption = tr('FRONTEND.BUTTONS.CONFIRM', 'Confirm'),
  buttonColor = 'secondary' as const
}) {
  const { modalHide } = useContext(GlobalContext)

  const handleCancel = () => {
    modalHide()
    typeof onCancel === 'function' && onCancel()
  }

  const handleConfirm = () => {
    modalHide()
    typeof onConfirm === 'function' && onConfirm()
  }

  return (
    <div data-test='confirmation-dialog-modal'>
      <ConfirmationTitle className='title'>
        {title || tr('FRONTEND.MODAL.CONFIRMATION', 'Confirmation')}
      </ConfirmationTitle>
      <ConfirmationContent>{question}</ConfirmationContent>
      <ConfirmationButtons>
        <Button
          type='button'
          color='primary'
          onClick={handleConfirm}
          data-test='button-confirm'
          text={confirmCaption || tr('FRONTEND.BUTTONS.CONFIRM', 'Confirm')}
        />
        <Button
          type='button'
          color={buttonColor}
          onClick={handleCancel}
          data-test='button-cancel'
          text={tr('FRONTEND.BUTTONS.CANCEL', 'Cancel')}
        />
      </ConfirmationButtons>
    </div>
  )
}

export default memo(ConfirmationModal, (prevProps, nextProps) => isEqual(prevProps, nextProps))

ConfirmationModal.propTypes = {
  title: PropTypes.string,
  question: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  onConfirm: PropTypes.func.isRequired,
  confirmCaption: PropTypes.string
}
