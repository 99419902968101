import { isValidObject, isValidString } from 'mmfintech-commons'
import { useGetMerchantQuery, useGetSumSubAccessTokenMutation, useOnboardingBannerQry } from 'mmfintech-backend-api'

import { MerchantAccountTypeEnum, OnboardingStatusEnum } from 'mmfintech-commons-types'

const useOnboarding = () => {
  const { data: merchant } = useGetMerchantQuery()
  const [fetchAccessToken] = useGetSumSubAccessTokenMutation()

  const { accountSetupFee, capabilities, entityType, accountType, onboardingStatus } = merchant || {}
  const { ibanEligible } = capabilities || {}

  const startSumSubOnboarding = async () => {
    if (isValidString(entityType)) {
      if (accountType === MerchantAccountTypeEnum.PROSPECT && onboardingStatus === OnboardingStatusEnum.NOT_STARTED) {
        try {
          const response = await fetchAccessToken(entityType.toLowerCase()).unwrap()

          if (response) {
            await startOrContinueOnboarding(response?.onboardingStatus)
          }
        } catch (err) {}
      }
    }
  }

  const { startOrContinueOnboarding } = useOnboardingBannerQry({
    startOnboarding: startSumSubOnboarding
  })

  const shouldPayAccountSetupFee = () => isValidObject(accountSetupFee)

  const shouldRequestIban = () => ibanEligible

  return {
    shouldRequestIban,
    shouldPayAccountSetupFee,
    startOrContinueOnboarding
  }
}

export default useOnboarding
