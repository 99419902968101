import styled from 'styled-components'

export const LegalRequirementsWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-flow: column nowrap;
  align-items: center;
  max-width: 340px;
  margin: 0 auto;

  h3 {
    font-size: 2.4rem;
    font-weight: 400;
    margin: 0;
    margin-bottom: 1.6rem;
  }

  .content {
    display: grid;
    gap: 16px;
    margin-bottom: 40px;
    margin-top: 16px;
    font-size: 1.2rem;
    font-weight: 400;
    color: #000000;
    text-align: start;

    p {
      margin: 0;
    }

    a {
      color: #1e63ff;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }
`
