import { useState } from 'react'
import { useHistory } from 'react-router-dom'

import { paths, useUploadInvoiceMutation } from 'mmfintech-backend-api'
import { checkFileUpload, isValidArray, isValidFunction, tr } from 'mmfintech-commons'
import { Button, DragAndDrop, ErrorDisplay, FileInput } from 'mmfintech-portal-commons'

import settings from '../../settings'
import { UploadInvoiceWrapper } from './styled/invoiceUpload.styled'

import uploadButton from '../../images/icons/invoice-upload.svg'

function InvoiceUpload({ onCancel }) {
  const [file, setFile] = useState(null)
  const [fileError, setFileError] = useState(null)

  const history = useHistory()
  const [uploadInvoice, { error: uploadInvoiceError, isLoading: uploadInvoiceFetching }] = useUploadInvoiceMutation({
    fixedCacheKey: 'upload-invoice-mutation'
  })

  const handleError = errors => setFileError(checkFileUpload(errors))

  const handleSelected = (files: FileList | File[]) => {
    const list = Array.from(files)
    setFileError(null)
    if (isValidArray(list) && isValidFunction(setFile)) {
      setFile(list[0])
    }
  }

  const handleClose = () => {
    onCancel && onCancel()
  }

  const handleUpload = async () => {
    if (file !== null) {
      try {
        await uploadInvoice({ file }).unwrap()

        history.push(paths.invoices.upload())
        onCancel && onCancel()
      } catch (_err) {}
    }
  }
  return (
    <UploadInvoiceWrapper data-test='invoice-upload-modal'>
      <div className='title'>{tr('FRONTEND.INVOICES.UPLOAD.TITLE', 'Upload Invoice')}</div>
      <DragAndDrop
        onDrop={handleSelected}
        onError={handleError}
        maxFileSize={settings.defaultUploadInvoiceMaxFileSize}
        acceptType={settings.defaultUploadInvoiceAcceptType}>
        <FileInput
          maxNumber={1}
          maxFileSize={settings.defaultUploadInvoiceMaxFileSize}
          acceptType={settings.defaultUploadInvoiceAcceptType}
          onChange={handleSelected}
          onError={handleError}>
          {({ onFileUpload, onRemoveAll }) => (
            <>
              <div
                className='upload-box'
                data-test='upload-box'
                onClick={() => {
                  onRemoveAll()
                  onFileUpload()
                }}>
                <img src={uploadButton} alt='Upload invoice' />
                {file ? file.name : tr('FRONTEND.INVOICES.UPLOAD.BUTTON_UPLOAD', 'Upload Invoice')}
              </div>
              <div className='file-types'>{settings.defaultUploadInvoiceAcceptType.join(', ')}</div>
            </>
          )}
        </FileInput>
      </DragAndDrop>
      <ErrorDisplay error={[uploadInvoiceError, fileError]} />

      <div className='buttons'>
        <Button
          type='button'
          color='secondary'
          text={tr('FRONTEND.BUTTONS.CANCEL', 'Cancel')}
          onClick={handleClose}
          disabled={uploadInvoiceFetching}
          data-test='button-cancel'
        />
        <Button
          type='button'
          color='primary'
          loading={uploadInvoiceFetching}
          disabled={file === null}
          text={tr('FRONTEND.INVOICES.UPLOAD.BUTTON_SUBMIT', 'Upload')}
          onClick={handleUpload}
          data-test='button-upload'
        />
      </div>
    </UploadInvoiceWrapper>
  )
}

export default InvoiceUpload
