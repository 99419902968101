import styled from 'styled-components'

import InfoIcon from '../../../images/icons/info-circle-silver.svg'

export const InvestModalWrapper = styled.div`
  width: 100%;
  max-width: 50rem;

  display: grid;
  place-items: center;

  padding: 2.5rem 2.5rem 3rem;

  @media (min-width: 400px) {
    padding: 3.5rem 4rem 4rem;
  }
`

export const StyledForm = styled.form`
  background-color: #ffffff;
  margin: 0;
  padding: 0;
  width: 100%;

  .title {
    display: block;
    text-align: center;
    margin-bottom: 3rem;
    color: #000000;
    font-size: 2.4rem;
    font-weight: 400;
    line-height: 2.9rem;
  }

  .current-apy-container {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;

    .current-apy {
      font-size: 1.6rem;
      font-weight: 600;
      line-height: 1.9rem;
    }

    .account-info-icon {
      cursor: pointer;
      border-radius: 50%;
      //prettier-ignore
      background: url("${InfoIcon}") center no-repeat;
      width: 17px;
      height: 17px;
      margin-left: 0.8rem;
    }
  }

  .estimated-earnings {
    font-size: 1.6rem;
    font-weight: 600;
    line-height: 1.9rem;
    margin-bottom: 3rem;
  }

  .disclaimer {
    font-family: Inter, sans-serif;
    color: #000000;
    font-size: 1rem;
    font-weight: 400;
    font-style: italic;
    line-height: 1.2rem;
  }

  .buttons-container {
    margin-top: 4rem;

    .button-secondary {
      margin-top: 1rem;
    }
  }

  .staking-type {
    span {
      font-size: 14px;
      font-weight: 400;
      color: #000000;
    }
    img {
      margin-right: 1rem;
    }
  }
`

export const Duration = styled.div`
  .duration-title {
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 1.9rem;
    text-align: left;
    margin: 1rem 0;
  }

  .duration-periods {
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: repeat(4, 1fr);

    .period {
      cursor: pointer;
      border: 1px solid rgba(133, 160, 173, 0.3);
      border-radius: 30px;
      display: inline-flex;
      justify-content: center;

      font-size: 1.4rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.7rem;

      padding: 1rem 0;

      &.active {
        background-color: #000000;
        color: #ffffff;
      }
      &.disabled {
        background-color: gray;
        color: #ffffff;
        cursor: not-allowed;
        pointer-events: none;
      }
    }
  }
`

export const InvestInfoWrapper = styled.div`
  margin: 3rem 0 2rem;
  border-bottom: 1px solid rgba(163, 184, 194, 0.3);

  span {
    color: #85a0ad;
    font-size: 1.2rem;
    font-weight: 400 !important;
    line-height: 1.5rem;
  }
`

export const ServiceAgreement = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  position: relative;
  margin-bottom: 1rem;

  div {
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 1.7rem;
    margin-left: 1rem;

    a {
      margin: 0;
      color: #0d80f2;
      font-size: 1.4rem;
      font-weight: 400;
    }
  }
`
