import styled from 'styled-components'

import { CommonFlexColumn, CommonFlexRow } from '../../../components/jetonStyled'
import { tablePadding } from '../../../components/styled/table.styled'
import { breakpoints, devices } from '../../../constants'

export const TitleContainer = styled.div`
  display: grid;
  grid-template-columns: 15rem minmax(50rem, 100rem);
  grid-column-gap: 2rem;

  .top-section {
    ${CommonFlexRow()}
    width: 100%;
    .title {
      margin: 0 auto;
    }
    .currencySelector {
      width: 15rem;
    }
  }
  @media (max-width: ${breakpoints.xl}px) {
    grid-template-columns: 1fr;

    .top-section {
      ${CommonFlexColumn(2)}
      align-items: center;
      .title {
        margin: 0;
      }
      .currencySelector {
        width: auto;
      }
    }
  }
`
export const AccountHeader = styled.div`
  display: flex;
  gap: 1.5rem;
  margin-top: 4rem;
  flex-flow: row wrap;
  margin-bottom: 2rem;
  @media ${devices.xl} {
    flex-flow: column nowrap;
  }
`

export const AccountsMain = styled.div`
  display: grid;
  grid-template-columns: 15rem 100rem;
  grid-column-gap: 2rem;

  @media (max-width: ${breakpoints.xl}px) {
    grid-template-columns: 1fr;
    width: 100%;
  }
`

export const AccountsContainer = styled.div`
  border-radius: 6px;
  display: flex;
  flex-flow: column nowrap;
  @media ${devices.xl} {
    .data-table-row:hover {
      .edit-cell > span {
        visibility: visible;
      }
    }
  }
  .data-table-cell,
  .data-table-head-cell {
    padding: 0 0.5rem;
    //padding: 0.5rem 0.5rem 0.5rem 0;

    font-size: 1.4rem;
    font-weight: 400;
    line-height: 120%;
  }

  .accounts-pagination {
    padding: 2rem 0;
  }

  .edit-cell > span {
    cursor: pointer;
    visibility: hidden;
  }

  .transactions-cell {
    width: 100%;
    justify-content: center;
    span {
      width: 100%;
      .button {
        font-size: 1.6rem;
        font-weight: normal;
        width: 100%;
        margin: 0 auto;
      }
    }
  }

  .data-table-body > .data-table-row:first-child > .edit-cell {
    border-top-right-radius: 10px;
  }

  .data-table-body > .data-table-row:last-child > .edit-cell {
    border-bottom-right-radius: 10px;
  }

  .account-details-iban {
    display: flex;
    align-items: center;
    gap: 1rem;
    svg:active {
      rect {
        fill: black;
      }
      filter: brightness(0) saturate(100%) invert(44%) sepia(82%) saturate(1126%) hue-rotate(200deg) brightness(99%)
        contrast(99%);
    }
  }

  @media ${devices.xl} {
    .transactions-cell {
      display: none;
    }
  }

  ${tablePadding}
`

export const CurrencyWrapper = styled.div`
  ${CommonFlexRow()}
  align-items: center;
`

export const CurrenciesSelectStyled = {
  menu: provided => ({
    ...provided,
    border: 'none !important',
    backgroundColor: '#ffffff'
  }),
  placeholder: provided => ({
    ...provided,
    color: 'black',
    marginLeft: '2.2rem',
    fontSize: '1.4rem',
    textTransform: 'capitalize'
  }),
  option: (provided, { isFocused }) => ({
    ...provided,
    fontSize: '1.4rem',
    fontWeight: '400',
    cursor: 'pointer',
    lineHeight: '2rem',
    color: isFocused ? '#ffffff' : '#131E3D',
    backgroundColor: isFocused ? '#131E3D' : 'transparent'
  }),
  container: provided => ({
    ...provided,
    borderColor: 'transparent',
    boxShadow: 'none',
    backgroundColor: 'transparent'
  }),
  control: provided => ({
    ...provided,
    width: '15rem',
    height: '4rem',
    cursor: 'pointer',
    minHeight: 'auto',
    fontWeight: '400',
    border: '1px solid #DCDFE5',
    borderRadius: '10px',
    backgroundColor: '#ffffff',
    padding: '0.5rem 1.5rem'
  }),
  singleValue: provided => ({
    ...provided,
    fontSize: '1.4rem',
    fontWeight: '400',
    lineHeight: '2rem',
    marginLeft: '0',
    width: '100%',
    color: '#131E3D'
  }),
  dropdownIndicator: provided => ({
    ...provided,
    cursor: 'pointer',
    width: '1.5rem',
    padding: 0,
    color: '#29303D'
  }),
  valueContainer: provided => ({
    ...provided,
    padding: 0
  })
}
