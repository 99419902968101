import { tr } from 'mmfintech-commons'

import { Button } from 'mmfintech-portal-commons'
import { NotOnboardedWrapper } from './notOnboardedModal.styled'

export const NotOnboardedModal = ({ onClose }) => {
  return (
    <NotOnboardedWrapper>
      <span className='title'>{tr('FRONTEND.DASHBOARD.ORDER_CARDS.TITLE', 'Order a card')}</span>
      <div className='not-onboarded-text'>
        {tr(
          'FRONTEND.CARDS.NOT_ONBOARDED.MODAL.TEXT',
          'You need to complete the onboarding process and get verified, before you can issue a card.'
        )}
      </div>
      <Button type='button' text={tr('FRONTEND.CARDS.NOT_ONBOARDED.MODAL.OK', 'OK')} onClick={onClose} />
    </NotOnboardedWrapper>
  )
}
