import { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import cn from 'classnames'
import toast from 'react-hot-toast'

import {
  paths,
  responseDoesNotContainsChallenge,
  useChangeCardStatusMutation,
  useLazyShowCardDetailsQuery
} from 'mmfintech-backend-api'
import { GlobalContext, OtpContext, formatFloat, getCurrencyDecimals, tr, translateError } from 'mmfintech-commons'

import ConfirmationModal from '../../../components/ConfirmationModal'
import { Button, ErrorDisplay } from 'mmfintech-portal-commons'
import { CardSettings, RotatingCard } from '../../index'

import ClosedEyeIcon from '@images/icons/eye-closed.svg?react'
import OpenedEyeIcon from '@images/icons/eye-opened.svg?react'
import FreezeIcon from '@images/icons/freeze.svg?react'
import PlusIcon from '@images/icons/transaction-type-plus-icon.svg?react'
import SettingsIcon from '@images/icons/settings.svg?react'

import {
  CardPodiumBottomSection,
  CardPodiumContainer,
  CardPodiumMainSection,
  CardPodiumWrapper
} from './cardPodium.styled'

interface CardPodiumProps {
  prepaidCards?: any[]
  selectedTab?: 'virtual' | 'physical'
  setSelectedTab?: Dispatch<SetStateAction<'virtual' | 'physical'>>
}

export const CardPodium = ({ prepaidCards, selectedTab }: CardPodiumProps) => {
  const { modalShow } = useContext(GlobalContext)
  const { sidebarRightShow, sidebarRightHide }: any = useContext(GlobalContext)

  const [cardFlip, setCardFlip] = useState(null)
  const [cardFrozen, setCardFrozen] = useState<boolean>()
  const [visibleSide, setVisibleSide] = useState<'front' | 'back'>('front')
  const [cardDetails, setCardDetails] = useState(null)

  const [isLoadingCardDetails, setIsLoadingCardDetails] = useState<boolean>(false)
  const [isLoadingCardStatus, setIsLoadingCardStatus] = useState<boolean>(false)

  const history = useHistory()

  const [showCardDetails, { error: cardDetailsError }] = useLazyShowCardDetailsQuery()
  const [changeCardStatus, { isLoading: isFreezing, error: cardStatusError }] = useChangeCardStatusMutation()
  const { setOtpOnSuccess, otpOnSuccess } = useContext(OtpContext)

  const virtualCardDetails = () => {
    const virtualCard = prepaidCards.find(card => card.isVirtual)
    if (virtualCard) {
      return virtualCard
    }
    return null
  }

  const physicalCardDetails = () => {
    const physicalCard = prepaidCards.find(card => !card.isVirtual)
    if (physicalCard) {
      return physicalCard
    }
    return null
  }

  const selectedCardDetails = () => {
    return selectedTab == 'physical' ? physicalCardDetails() : virtualCardDetails()
  }

  const cardExists = () => !!selectedCardDetails()

  const flipCard = async () => {
    if (!cardDetails) {
      if (selectedCardDetails() && selectedCardDetails().id) {
        setIsLoadingCardDetails(true)
        setOtpOnSuccess(() => (result: any) => {
          if (result) {
            setIsLoadingCardDetails(false)
            setCardDetails(result)
            setCardFlip({
              detailsShown: true,
              actionCalled: Date.now()
            })
          }
        })
        try {
          await showCardDetails({ prepaidCardId: selectedCardDetails().id }).unwrap()
        } catch (err) {
          setOtpOnSuccess(null)
        }
      }
    } else {
      setIsLoadingCardDetails(false)
      if (visibleSide == 'back') {
        return setCardFlip({
          detailsShown: false,
          actionCalled: Date.now()
        })
      }
      setCardFlip({
        detailsShown: true,
        actionCalled: Date.now()
      })
    }
  }

  const cardFreezeSuccess = () => {
    setIsLoadingCardStatus(false)
    setCardFrozen(cardFrozen => !cardFrozen)
    setVisibleSide('front')
    setCardFlip({
      detailsShown: false,
      actionCalled: Date.now()
    })
  }

  const handleCardFreeze = async () => {
    if (selectedCardDetails() && selectedCardDetails().id) {
      setIsLoadingCardStatus(true)
      setOtpOnSuccess(() => cardFreezeSuccess)
      const newCardStatus = cardFrozen ? 'ACTIVE' : 'FROZEN'
      try {
        const response = await changeCardStatus({
          prepaidCardId: selectedCardDetails().id,
          body: { prepaidCardStatus: newCardStatus }
        }).unwrap()
        if (responseDoesNotContainsChallenge(response)) {
          setOtpOnSuccess(null)
          cardFreezeSuccess()
        }
      } catch (error) {
        const message = translateError(error)
        setOtpOnSuccess(null)
        if (message) {
          toast.remove()
          toast.error(message)
        }
      }
    }
  }

  const handleSettingsClick = () => {
    sidebarRightShow({
      options: { size: 'extra-large', closeOnClickOutside: false },
      content: (
        <CardSettings
          cardType={selectedTab}
          cardNumber={cardDetails ? cardDetails.pan : cardExists() ? selectedCardDetails().last4Digits : ''}
          cardDetails={selectedCardDetails()}
          onClose={sidebarRightHide}
        />
      )
    })
  }

  // const tabOptions = [
  //   { label: tr('FRONTEND.CARDS.CARD_TYPE.PHYSICAL', 'Physical'), value: 'physical' },
  //   { label: tr('FRONTEND.CARDS.CARD_TYPE.VIRTUAL', 'Virtual'), value: 'virtual' }
  // ]

  useEffect(() => {
    if (selectedCardDetails()?.status === 'FROZEN') {
      setCardFrozen(true)
    } else {
      setCardFrozen(false)
    }
  }, [prepaidCards, selectedTab])

  useEffect(() => {
    if (otpOnSuccess === null) {
      setIsLoadingCardDetails(false)
    }
  }, [otpOnSuccess])

  return (
    <div>
      <CardPodiumWrapper className={cn({ frozen: cardFrozen, unfrozen: cardFrozen === false })}>
        <CardPodiumContainer>
          {/* <div className='card-podium-top-section'>
              <CoreTabs
                tabs={tabOptions}
                initial={selectedTab}
                onChangeTab={tab => {
                  setCardFlip({
                    detailsShown: false,
                    actionCalled: Date.now()
                  })
                  setCardDetails(null)
                  setSelectedTab(tab)
                }}
              />
            </div> */}

          <CardPodiumMainSection>
            <RotatingCard
              cardNumber={cardDetails && cardDetails.pan}
              lastFourDigits={cardExists() && selectedCardDetails().last4Digits}
              cvv={cardDetails && cardDetails.cvv}
              validity={
                cardDetails && `${cardDetails.expiryMonth?.padStart(2, '0')}/${cardDetails.expiryYear?.slice(-2)}`
              }
              flip={cardFlip}
              setVisibleSide={setVisibleSide}
              //      <div className={cn('card-podium-wrapper', { frozen: cardFrozen, unfrozen: cardFrozen === false })}>
              enableRotation={false}
              visibleSide={visibleSide}
              cardType={selectedTab}
              isFrozen={cardFrozen}
              cardBrand={selectedCardDetails()?.cardBrand}
              hasShadow={false}
              cardHolderName={selectedCardDetails()?.cardHolderName}
            />
            <ErrorDisplay error={[cardDetailsError, cardStatusError]} />

            {cardExists() &&
              (selectedCardDetails().status === 'NOT_ACTIVATED' ? (
                <div className='activate-physical-card-container'>
                  <Button
                    className='activate-button'
                    text={tr('FRONTEND.CARDS.CARD_BALANCE.ACTIVATE', 'Activate')}
                    onClick={() =>
                      modalShow({
                        header: tr('FRONTEND.SECURITY.ACTIVATE_CARD.TITLE', 'Activate card'),
                        content: <></>
                        // <ActivateCard prepaidCardId={selectedCardDetails().id} />
                      })
                    }
                  />
                  <div className='activate-card-instructions'>
                    {tr(
                      'FRONTEND.SECURITY.ACTIVATE_CARD.INSTRUCTIONS',
                      'You can only activate your card after being delivered to you, as it contains the CVC needed to proceed.'
                    )}
                  </div>
                </div>
              ) : selectedCardDetails().status === 'ACTIVE' || selectedCardDetails().status === 'FROZEN' ? (
                <CardPodiumBottomSection
                  className={cn({
                    frozen: cardFrozen,
                    unfrozen: cardFrozen === false
                  })}>
                  <div className='card-podium-bottom-section-content'>
                    {!cardFrozen && (
                      <div className='card-podium-balance'>
                        <div className='card-balance-title'>
                          {tr('FRONTEND.CARDS.CARD_BALANCE.TITLE', 'Card balance')}
                        </div>
                        <div className='card-balance-value'>
                          {formatFloat(
                            selectedCardDetails()?.availableBalance,
                            getCurrencyDecimals(selectedCardDetails()?.currency)
                          )}
                          <span className='card-balance-currency'>{selectedCardDetails()?.currency}</span>
                        </div>
                      </div>
                    )}

                    <div className='card-podium-actions'>
                      {!cardFrozen && (
                        <div className='card-podium-action-tuple'>
                          <Button
                            icon={
                              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                {visibleSide == 'back' ? <OpenedEyeIcon /> : <ClosedEyeIcon />}
                              </div>
                            }
                            onClick={flipCard}
                            loading={isLoadingCardDetails}
                          />
                          <div className='card-podium-action-title'>
                            {tr('FRONTEND.CARDS.CARD_ACTION.DETAILS', 'Show details')}
                          </div>
                        </div>
                      )}
                      <div className='card-podium-action-tuple'>
                        <Button
                          icon={<FreezeIcon />}
                          className={cardFrozen ? 'freeze-button' : ''}
                          loading={isFreezing || isLoadingCardStatus}
                          onClick={() => {
                            cardFrozen
                              ? handleCardFreeze()
                              : modalShow({
                                  header: tr('FRONTEND.CARDS.CARDS_MODAL.TITLE', 'Freezing card'),
                                  content: (
                                    <ConfirmationModal
                                      question={
                                        <>
                                          {tr(
                                            'FRONTEND.CARDS.CARDS_MODAL.CONFIRM1',
                                            'You are about to freeze your card which will prevent its usage.'
                                          )}
                                          <br />
                                          {tr(
                                            'FRONTEND.CARDS.CARDS_MODAL.CONFIRM2',
                                            'Unfreezing the card will require OTP confirmation. Do you wish to proceed?'
                                          )}
                                        </>
                                      }
                                      confirmCaption={tr('FRONTEND.CARDS.CARDS_MODAL.BUTTON_YES', 'Yes')}
                                      onConfirm={handleCardFreeze}
                                    />
                                  )
                                })
                          }}
                        />
                        <div className='card-podium-action-title'>
                          {cardFrozen
                            ? tr('FRONTEND.CARDS.CARD_BALANCE.UNFREEZE', 'Unfreeze')
                            : tr('FRONTEND.CARDS.CARD_BALANCE.FREEZE', 'Freeze')}
                        </div>
                      </div>
                      <div className='card-podium-action-tuple'>
                        <Button icon={<SettingsIcon />} onClick={handleSettingsClick} />
                        <div className='card-podium-action-title'>
                          {tr('FRONTEND.CARDS.CARD_ACTION.SETTINGS', 'Settings')}
                        </div>
                      </div>
                    </div>
                  </div>
                </CardPodiumBottomSection>
              ) : (
                <div className='issuing-physical-card-container'>
                  <div className='activate-card-instructions'>
                    {tr(
                      'FRONTEND.SECURITY.ISSUING_CARD.INSTRUCTIONS',
                      'Your card is currently being issued. Once it is issued you will be notified and the card will be sent to the provided address.'
                    )}
                  </div>
                </div>
              ))}
          </CardPodiumMainSection>
          <div className='add-funds-button-container'>
            <Button
              icon={<PlusIcon />}
              className='add-funds-button'
              iconPosition='left'
              color='primary'
              text={tr('FRONTEND.CARDS.CARD_BALANCE.ADD_FUNDS', 'Add funds')}
              onClick={() =>
                history.push({
                  pathname: paths.banking.deposit(),
                  state: { preselectedAccount: selectedCardDetails()?.accountId }
                })
              }
            />
          </div>
        </CardPodiumContainer>
      </CardPodiumWrapper>
    </div>
  )
}
