import styled from 'styled-components'

import { CommonFlexRow } from '../../../components/jetonStyled'
import { devices } from '../../../constants'

export const TransactionsHeader = styled.div`
  ${CommonFlexRow()}
  align-items: center;
  width: 100%;

  @media ${devices.xl} {
    &::before {
      content: '';
      flex: 1;
    }
  }
  .title {
    flex-grow: 1;
  }
`

export const SelectionNumber = styled.div`
  border-radius: 50%;
  background-color: rgba(0, 102, 255, 1);
  padding: 0.2rem 0.7rem;
  color: #ffffff;
`

export const TransactionsHeaderRight = styled.div`
  ${CommonFlexRow(2)}
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  @media ${devices.xl} {
    width: auto;
  }

  .buttons-container {
    display: flex;
    flex-flow: row wrap;
    gap: 2rem;
  }

  .button {
    font-family: inherit;
    font-size: 1.4rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.7rem;
    white-space: nowrap;
    width: auto;
    min-width: 14rem;
    :first-child {
      margin-left: 0;
    }
    @media ${devices.xl} {
      padding: 1rem 2rem 1rem 1.5rem;
    }
  }
`

export const TransactionExportButtons = styled.div`
  display: inline-flex;
  flex-flow: column nowrap;
  align-items: center;
  gap: 1rem;

  .button {
    height: 3.2rem;
  }

  @media (min-width: 480px) {
    flex-flow: row nowrap;
    justify-content: center;

    .button {
      width: auto;
      margin-bottom: 0;
    }
  }
  @media (min-width: 1200px) {
    grid-column: 3;
    justify-content: flex-end;
  }
`
