import styled from 'styled-components'

import { CommonFlexRow } from '../../../components/jetonStyled'
import { tablePadding } from '../../../components/styled/table.styled'
import { devices } from '../../../constants'

export const LimitsWrapper = styled.div`
  width: 100%;
  max-width: 110rem;
  justify-self: center;
  [data-test='details-button'] {
    background: rgba(19, 30, 61, 1);
    border-radius: 5px;
    height: 3.5rem;

    font-size: 1rem;
    font-weight: 400;
    line-height: 120%;

    @media ${devices.xl} {
      visibility: hidden;
      height: 3rem;
    }
  }
  ${tablePadding}

  .data-table {
    .data-table-head-cell {
      cursor: pointer;
    }
    .activeSortCell {
      display: flex;
      gap: 1rem;
      justify-content: center;
      align-items: center;
    }
    .data-table-body {
      .data-table-row {
        background: #ffffff;
        [data-test='description'] {
          line-break: anywhere;
        }
        [data-test='amount'] {
          white-space: pre;
        }
        :hover {
          background-color: #edf3fd;
          [data-test='details-button'] {
            visibility: visible;
          }
          .recipient-details {
            opacity: 1;
          }
        }

        .data-table-cell {
          font-size: 1.2rem;
          font-weight: 400;
          line-height: 1.5rem;

          .limit-name {
            width: 30rem;
          }
        }
        .data-table-cell-caption {
          text-transform: none;
        }
      }
    }

    /* @media ${devices.xl} { */
    .recipient-wrapper {
      @media (max-width: 768px) {
        width: auto;
        display: block;
      }
      .recipient-details {
        background-color: rgba(19, 30, 61, 1);
        color: rgba(255, 255, 255, 1);
        border-radius: 8px;
        height: 3rem;

        text-decoration: none;

        font-size: 1.2rem;
        @media ${devices.xl} {
          width: 8rem;
          opacity: 0;
        }
      }
    }

    .action-buttons {
      span,
      .status {
        ${CommonFlexRow(1)}
        justify-content: center;
      }
      text-align: center;

      @media ${devices.xl} {
        width: 300px;
      }

      button {
        width: 9rem;
        height: 3rem;
        padding: 1.5rem 3rem;
      }

      .approve {
        background: #ffffff;
        color: #44cc00;
        border: 1px solid rgba(68, 204, 0, 0.7);
        border-radius: 5px;
      }

      .reject {
        background: #ffffff;
        color: #ff3131;
        border: 1px solid rgba(255, 49, 49, 0.5);
        border-radius: 5px;
      }

      .status {
        width: 100%;
      }
    }

    .icon {
      margin-right: 0.5rem;
    }
    /* } */
  }
`
