import styled from 'styled-components'

import { tablePadding } from '../../../../components/styled/table.styled'

export const TransactionsSectionWrapper = styled.div``

export const TransactionsSectionTitle = styled.h3`
  font-size: 18px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
`

export const TransactionsWrapper = styled.div``

export const TransactionsTableContainer = styled.div`
  .data-table-head {
    .data-table-row {
      .data-table-head-cell {
        height: 5rem;
        vertical-align: middle;

        font-size: 12px;
        font-family: Roboto;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
  }

  .data-table-body {
    background-color: #fff;
    .data-table-row {
      border-bottom: 1px solid #f4f6f6;
      :last-child {
        border-bottom: none;
      }

      .pagination-cell {
        margin: auto;
      }

      .data-table-cell {
        font-size: 12px;
        font-family: Roboto;
        font-style: normal;
        font-weight: 400;
        line-height: normal;

        &.text-upper {
          text-transform: uppercase;
        }
        &.status-failed {
          color: #d13737;
        }
        &.status-cancelled {
          color: #e4c41e;
        }
        &.status-processed {
          color: #34a518;
        }
      }
      .data-table-cell-caption {
        text-transform: none;
      }
    }
  }

  ${tablePadding}
`
