function range(start: number, end?: number, step?: number): number[] {
  // Handle the case when only one argument is provided
  if (end === undefined) {
    end = start
    start = 0
    step = 1
  }

  // If step is not provided, determine if we should step up or down
  if (step === undefined) {
    step = start < end ? 1 : -1
  }

  // Validate step to avoid an infinite loop
  if (step === 0 || (step > 0 && start >= end) || (step < 0 && start <= end)) {
    throw new Error('Invalid range parameters')
  }

  // Create the range of numbers
  let rangeArray: number[] = []
  if (step > 0) {
    for (let i = start; i < end; i += step) {
      rangeArray.push(i)
    }
  } else {
    for (let i = start; i > end; i += step) {
      rangeArray.push(i)
    }
  }

  return rangeArray
}
function Separator(props) {
  return (
    <div
      style={{
        position: 'absolute',
        height: '100%',
        transform: `rotate(${props.turns}turn)`,
        borderRadius: 10,
      }}>
      <div style={props.style} />
    </div>
  )
}

function RadialSeparators(props) {
  const turns = 1 / props.count
  return (
    <>
      {range(props.count).map(index => (
        <Separator key={index} turns={index * turns} style={props.style} />
      ))}
    </>
  )
}

export default RadialSeparators
