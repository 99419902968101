import React from 'react'

import DoneIcon from '../../../../images/icons/application-fee-paid.svg?react'
import InReviewIcon from '../../../../images/icons/in-review-icon.svg?react'

export const ActionComponent: React.FC<{ isDone?: boolean }> = ({ isDone = true }) => (
  <div className='action-container'>
    {isDone ? <DoneIcon /> : <InReviewIcon />}
    <span>{isDone ? 'Done' : 'Review'}</span>
  </div>
)
