import styled from 'styled-components'

export const EmptyVoucherPageWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  font-size: 1.6rem;
  font-weight: 600;
`
