import { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { paths, useGetMerchantQuery } from 'mmfintech-backend-api'
import { GlobalContext, tr } from 'mmfintech-commons'
import { Button, Preloader, SwitchInput } from 'mmfintech-portal-commons'

import cn from 'classnames'

import { BuyVoucherTab } from './buyVoucherTab/BuyVoucherTab'
import { YourVouchers } from './yourVouchers/YourVouchers'
import { AccessDenied } from '../../components'
import { CommonPageTitle, RowsMainSection, RowsTopSection } from '../../components/jetonStyled'

import SuccessImage from '../../images/icons/success.svg?react'

import { CashVoucherContainer, CashVoucherLoader, CashVoucherWrapper, SuccessModalWrapper, Tab, TabsWrapper } from './cashVoucher.styled'

const VOUCHER_TABS = [
  {
    label: tr('FRONTEND.PROFILE.PROFILE_DETAILS_TITLE', 'Buy New Voucher'),
    value: 'buy',
    path: paths.more.cashVoucher('buy')
  },
  {
    label: tr('FRONTEND.SECURITY.YOUR VOUCHERS', 'Your Vouchers'),
    value: 'list',
    path: paths.more.cashVoucher('list')
  }
]

const CashVoucher = () => {
  const { data: merchant } = useGetMerchantQuery(null)
  const history = useHistory()
  const { modalShow, modalHide } = useContext(GlobalContext)

  const { pathname } = history?.location
  const endPath = pathname.split('/')[3]
  const [activeTab, setActiveTab] = useState(endPath)
  const [isActiveVouchers, setIsActiveVouchers] = useState(true)

  const { capabilities } = merchant || {}

  useEffect(() => {
    if (endPath) {
      setActiveTab(endPath)
    }
  }, [endPath])

  const onChangeTab = value => {
    setActiveTab(value)
    history.push(VOUCHER_TABS.find(x => x.value === value).path, endPath)
  }

  const handleSuccess = () => {
    setTimeout(() => {
      modalShow({
        content: (
          <SuccessModalWrapper>
            <SuccessImage />
            <SuccessMessage />
            <Button text='OK' onClick={modalHide} color='primary' />
          </SuccessModalWrapper>
        )
      })
    }, 0)
  }

  if (!merchant) {
    return (
      <CashVoucherLoader>
        <Preloader />
      </CashVoucherLoader>
    )
  }
  if (capabilities && !capabilities.enableCashVouchers) {
    return <AccessDenied />
  }
  return (
    <CashVoucherWrapper>
      <RowsTopSection>
        <CommonPageTitle centered>{tr('FRONTEND.CASH_VOUCHERS.TITLE', 'Jeton Cash')}</CommonPageTitle>
      </RowsTopSection>
      <CashVoucherContainer className='cash-voucher-container'>
        <div className='navigation-tabs'>
          <RowsMainSection>
            <TabsWrapper>
              <Tab active={activeTab == 'buy'} onClick={() => onChangeTab('buy')} data-test='buy-tab'>
                {tr('FRONTEND.CASH_VOUCHERS.NAV.BUY', 'Buy New Vouchers')}
              </Tab>
              <Tab active={activeTab == 'list'} onClick={() => onChangeTab('list')} data-test='list-tab'>
                {tr('FRONTEND.CASH_VOUCHERS.NAV.LIST', 'Your Vouchers')}
              </Tab>
            </TabsWrapper>
          </RowsMainSection>

          {activeTab === 'list' && (
            <div className='switch-active-tabs'>
              <span>History</span>
              <SwitchInput onChange={() => setIsActiveVouchers(!isActiveVouchers)} checked={isActiveVouchers} />
              <span>Active</span>
            </div>
          )}
        </div>
        <div className={cn('cash-voucher-content-container')}>
          {activeTab === 'buy' && (
            <div className='cash-voucher-wrapper'>
              <div className='cash-voucher-content'>
                <BuyVoucherTab onSuccess={handleSuccess} />
              </div>
            </div>
          )}
          {activeTab === 'list' && <YourVouchers isActiveVouchers={isActiveVouchers} />}
        </div>
      </CashVoucherContainer>
    </CashVoucherWrapper>
  )
}

export default CashVoucher

const SuccessMessage = () => {
  return (
    <div className='cash-voucher-success-pane'>
      <div className='cash-voucher-success-pane-title'>
        {tr('FRONTEND.CASH_VOUCHERS.SUCCESS_PANE.TITLE', 'Voucher purchased successfully!')}
      </div>
      <div className='cash-voucher-success-pane-sub-title'>
        {tr('FRONTEND.CASH_VOUCHERS.SUCCESS_PANE.SUB_TITLE', 'Go to Your Vouchers tab to view it.')}
      </div>
    </div>
  )
}
