import { useContext, useEffect } from 'react'
import { useCookies } from 'react-cookie'

import {
  isOwnerOrAdministrator,
  selectCurrentUserRole,
  selectInitialEmailVerification,
  useAppSelector,
  useGetMerchantQuery,
  useGetPendingLegalRequirementsQuery,
  useGetProfileQuery,
  useGetSumSubStatusQuery
} from 'mmfintech-backend-api'
import { GlobalContext, isValidArray, useWindowSize } from 'mmfintech-commons'
import { TwoFactorTypeEnum } from 'mmfintech-commons-types'

import { GoogleAuthenticatorInfoModal, LegalRequirementsModal } from '../../components'
import { OnboardingBanner } from './elements/OnboardingBanner'
import { WelcomeModal } from './elements/WelcomeModal'
import { DashboardStaff } from './DashboardStaff'
import { DashboardAdmin } from './DashboardAdmin'
import { breakpoints } from '../../constants'

import { ColumnTwoSectionContainer } from '../../components/jetonStyled'
import { DashboardContainer } from './Dashboard.styled'

export const Dashboard = () => {
  const { modalShow } = useContext(GlobalContext)
  const [cookies] = useCookies(['authenticator'])
  const { data: profile } = useGetProfileQuery(null)
  const { twoFactor } = profile || {}
  const { data: merchant } = useGetMerchantQuery()
  const { merchantId } = merchant || {}

  const customerRole = useAppSelector(selectCurrentUserRole)
  const initialEmailVerification = useAppSelector(selectInitialEmailVerification)

  useGetSumSubStatusQuery(null)

  const {
    data: legalRequirements,
    isFetching: legalRequirementsFetching,
    isLoading: legalRequirementsLoading
  } = useGetPendingLegalRequirementsQuery(null)

  const [width] = useWindowSize()

  useEffect(() => {
    if (legalRequirementsFetching || legalRequirementsLoading) return null

    if (initialEmailVerification && isValidArray(legalRequirements)) {
      modalShow({
        options: {
          size: 'medium',
          transparent: true,
          closeOnClickOutside: false,
          closeOnEscape: false
        },
        content: <WelcomeModal requirements={legalRequirements} />
      })
    } else if (!legalRequirementsFetching && isValidArray(legalRequirements)) {
      modalShow({
        options: {
          size: 'medium',
          transparent: true,
          closeOnEscape: false,
          closeOnClickOutside: false
        },
        content: <LegalRequirementsModal requirements={legalRequirements} />
      })
    } else if (merchantId && !cookies?.authenticator?.includes(merchantId) && twoFactor !== TwoFactorTypeEnum.TOTP) {
      modalShow({
        options: {
          closeOnClickOutside: true,
          closeOnEscape: true,
          hideCloseIcon: true
        },
        content: <GoogleAuthenticatorInfoModal />
      })
    }
  }, [legalRequirements, merchantId])

  return (
    <DashboardContainer>
      {width > breakpoints.xl && <OnboardingBanner />}

      <ColumnTwoSectionContainer size='xl' additionalStyles={{ gap: '5rem', padding: '2rem 0' }}>
        {isOwnerOrAdministrator(customerRole) ? <DashboardAdmin /> : <DashboardStaff />}
      </ColumnTwoSectionContainer>
    </DashboardContainer>
  )
}
