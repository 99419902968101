import React, { memo, useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import cn from 'classnames'
import isEqual from 'lodash.isequal'

import { useModal } from 'mmfintech-commons'

import { SidebarRightContainer, SidebarRightWrapper } from './sidebarRight.styled'

interface SidebarRightProps {
  content: any
  options: {
    closeIconPosition?: 'default'
    closeOnClickOutside?: boolean
    closeOnEscape?: boolean
    hideCloseIcon?: boolean
    scrollable?: boolean
    maxWidth?: number
    onClose?: () => void
    transparent?: boolean
    size?: 'auto' | 'small' | 'medium' | 'large' | 'extra-large' | 'horizontal' | 'centered'
  }
  visible: boolean
  onClose: () => void
}

const SidebarRight: React.FC<SidebarRightProps> = ({ content, options, visible, onClose }) => {
  const { ref, modalOptions, handleMouseDown } = useModal({
    options,
    visible,
    onClose
  })

  const location = useLocation()

  useEffect(() => {
    if (visible) {
      typeof onClose === 'function' && onClose()
    }
    // eslint-disable-next-line
  }, [location.pathname])

  return (
    <SidebarRightWrapper onMouseDown={handleMouseDown} ref={ref} visible={visible}>
      <SidebarRightContainer
        visible={visible}
        data-test='modal-container'
        onMouseDown={e => e.stopPropagation()}
        className={cn({
          'size-auto': modalOptions.size === 'auto',
          'size-large': modalOptions.size === 'large',
          'size-small': modalOptions.size === 'small',
          'size-extra-large': modalOptions.size === 'extra-large',
          centered: modalOptions.size === 'centered',
          horizontal: modalOptions.size === 'horizontal',
          transparent: modalOptions.transparent === true,
          'overflow-none': modalOptions.overflow === 'none'
        })}>
        {content}
      </SidebarRightContainer>
    </SidebarRightWrapper>
  )
}

export default memo(SidebarRight, (prevProps, nextProps) => isEqual(prevProps, nextProps))
