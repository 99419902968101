// noinspection DuplicatedCode
export const FilterSelectStyles = {
  container: provided => ({
    ...provided,
    width: '100%',
    fontFamily: 'inherit',
    fontSize: '1.2rem',
    lineHeight: '1.5rem',
    background: 'rgba(255, 255, 255, 1)',
    borderRadius: '10px'
  }),
  control: provided => ({
    ...provided,
    cursor: 'pointer',
    color: 'rgba(19, 30, 61, 1)',
    background: 'rgba(255, 255, 255, 1)',
    borderWidth: '1px',
    borderStyle: 'none',
    borderRadius: '10px',
    minHeight: '3.5rem',
    // paddingLeft: '1rem',
    padding: '0',
    transition: 'none',
    boxShadow: 'none',
    outline: 'none'
  }),
  singleValue: provided => ({
    ...provided,
    color: 'inherit'
  }),
  valueContainer: provided => ({
    ...provided,
    color: 'rgba(19, 30, 61, 1)',
    padding: '0 8px',
    textAlign: 'center'
  }),
  dropdownIndicator: provided => ({
    ...provided,
    padding: '0 8px 0 0',
    color: 'rgba(19, 30, 61, 1)'
  }),
  input: provided => ({
    ...provided,
    marginBottom: 0,
    marginTop: 0,
    paddingBottom: 0,
    paddingTop: 0
  }),
  placeholder: provided => ({
    ...provided,
    color: 'rgba(19, 30, 61, 1)',
    fontSize: '1.4rem',
    fontWeight: 'normal',
    lineHeight: '120%'
  }),
  menu: provided => ({
    ...provided,
    background: '#ffffff',
    border: 'none !important',
    borderRadius: '1rem',
    margin: '1rem 0 0 0',
    padding: '1rem 0',
    boxShadow: '0 5px 30px rgba(172, 182, 191, 0.25)',
    ':hover': {
      border: 'none !important'
    }
  }),
  option: provided => ({
    ...provided,
    color: 'rgba(19, 30, 61, 1)',
    fontSize: '1.4rem',
    lineHeight: '120%',
    cursor: 'pointer',
    textAlign: 'left',
    background: '#ffffff',
    // marginTop: '5px',
    padding: '1rem 2rem',
    ':hover': {
      background: 'rgba(194, 201, 214, 1)'
    }
  })
}
