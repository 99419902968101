import { useContext } from 'react'

import { useGetAllCardsQuery } from 'mmfintech-backend-api'
import { GlobalContext, isValidArray, tr } from 'mmfintech-commons'

import CardsIcon from '../../../images/cards-banner-image.svg?react'
import { CardsBannerContainer } from './cardsBanner.style'
import { Button } from 'mmfintech-portal-commons'
import { CardOrderModal } from '../../../components/CardOrderModal'

const CardsBanner = () => {
  const { modalShow, modalHide } = useContext(GlobalContext)
  const { data: activeCards, isLoading: activeCardsLoading, error: activeCardsError } = useGetAllCardsQuery(null)

  const handleOrderClick = () => {
    modalShow({
      header: tr('FRONTEND.DASHBOARD.ORDER_CARDS.TITLE', 'Order a card'),
      content: <CardOrderModal onClose={modalHide} />,
      options: {
        closeOnClickOutside: false,
        size: 'auto'
      }
    })
  }

  if ((isValidArray(activeCards) && activeCards.length >= 1) || activeCardsError) return <></>

  return activeCardsLoading ? (
    <></>
  ) : (
    <CardsBannerContainer>
      <div className='cards-banner-title'>{tr('FRONTEND.DASHBOARD.CARDS_BANNER.TITLE', 'Jetonbank Cards')}</div>
      <div className='cards-banner-container'>
        <div className='cards-top-container'>
          <div className='cards-banner-bank-cards'>
            <CardsIcon />
          </div>
        </div>
        <div className='cards-buttons-wrapper'>
          <Button
            text={tr('FRONTEND.DASHBOARD.CARDS_BANNER.ORDER_BUTTON', 'Order Card')}
            onClick={() => handleOrderClick()}
          />
        </div>
      </div>
    </CardsBannerContainer>
  )
}

export default CardsBanner
