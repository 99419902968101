import styled from 'styled-components'

import { tablePadding } from '../../../components/styled/table.styled'
import { breakpoints } from '../../../constants'

export const InvestmentsTableCurrentContainer = styled.div`
  ${tablePadding}

  .button-wrapper {
    width: 12rem;
    .withdraw-button {
      font-family: Roboto;
      font-size: 12px;
      font-weight: 400;

      width: 10rem;
      height: 3rem;
    }
  }
  .data-table-container .data-table-body .data-table-row .data-table-cell {

    &.bold {
      font-weight: 500;
    }

    &.alt-color {
      color: #0066FF;
    } 
    
    :nth-last-child(2){
      text-align: end;
    } 
    .currency-wrapper{
      display: flex;
      align-items: center;
      gap: 1rem;
    }

    @media (max-width: ${breakpoints.xl}px) {
      border-radius: 0;
      border-bottom: none;
    }
  }

  .data-table-container .data-table-head .data-table-row .data-table-head-cell:last-child{
    text-align: end;
    width: 15rem;
    padding-right: 1rem;
  }

  .no-arrow-buttons{
    padding: 1rem 0;
  }
`