import React, { useContext } from 'react'
import { useHistory } from 'react-router-dom'

import { api, paths, useAuth, useLazyCardLogoutQuery, useLazyLogoutQuery } from 'mmfintech-backend-api'
import { GlobalContext, tr } from 'mmfintech-commons'
import { Button } from 'mmfintech-portal-commons'

import ActivitiesMenu from './ActivitiesMenu'
import { ActivityButton } from './styled/HeaderIcons.styled'
import { MerchantSwitchMenu } from './MerchantSwitchMenu'
import { HamburgerMenuWrapper } from './styled/hamburger.styled'

import { useAppDispatch } from '../hooks'

import { LoginStatusEnum } from 'mmfintech-commons-types'

import ActivitiesIcon from '../images/icons/activities-icon.svg?react'
import IconClose from '../images/icons/icon-close.svg?react'

interface HamburgerMenuProps {
  opened: boolean
  height?: number
  maxWidth: number
  hideMenu: () => void
  Items: React.FC
  Logo: React.FC
}

function HamburgerMenu({ Logo, opened, maxWidth, hideMenu, Items }: HamburgerMenuProps) {
  const userStatus = useAuth()
  const dispatch = useAppDispatch()
  const { sidebarRightShow, sidebarRightHide } = useContext(GlobalContext)
  const history = useHistory()
  const isLoggedIn = () => userStatus === LoginStatusEnum.LOGGED_IN
  const [logout] = useLazyLogoutQuery()
  const [cardLogout] = useLazyCardLogoutQuery()

  const handleLogoutClick = async (e: React.SyntheticEvent) => {
    e.preventDefault()
    hideMenu()
    try {
      await logout().unwrap()
      await cardLogout().unwrap()
      dispatch(api.util.resetApiState())
    } catch {}
    return false
  }
  const handleOpenSidebarRight = () => {
    hideMenu()
    sidebarRightShow({
      content: <ActivitiesMenu onClose={sidebarRightHide} />,
      options: { size: 'extra-large', closeOnClickOutside: true }
    })
  }
  return (
    <HamburgerMenuWrapper open={opened} maxMobileWidth={maxWidth}>
      <div className='upper'>
        <Logo />
        <div onClick={hideMenu}>
          <IconClose />
        </div>
      </div>
      <div className='items-container'>
        {isLoggedIn() && (
          <>
            <ActivityButton onClick={handleOpenSidebarRight}>
              <div>
                <ActivitiesIcon title='Activities' />
              </div>
              <span>Activity</span>
            </ActivityButton>
            {isLoggedIn() && <MerchantSwitchMenu />}
            <Items />
          </>
        )}
      </div>
      <div className='action-button'>
        {isLoggedIn() && (
          <Button
            type='button'
            color='primary'
            text={tr('FRONTEND.HEADER.LOGOUT', 'Log Out')}
            data-test='header-logout'
            onClick={handleLogoutClick}
          />
        )}
        {!isLoggedIn() && (
          <Button
            type='button'
            color='primary'
            text={tr('FRONTEND.HEADER.LOGIN', 'Log in')}
            data-test='header-login'
            onClick={() => {
              history.push(paths.login())
              hideMenu()
            }}
          />
        )}
        {!isLoggedIn() && (
          <Button
            type='button'
            color='primary'
            text={tr('FRONTEND.HEADER.SIGNUP', 'Sign Up')}
            data-test='header-signup'
            onClick={() => {
              history.push(paths.signUp())
              hideMenu()
            }}
          />
        )}
      </div>
    </HamburgerMenuWrapper>
  )
}

export default HamburgerMenu
