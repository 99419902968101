// noinspection DuplicatedCode
import cn from 'classnames'
import { tr } from 'mmfintech-commons'
import PropTypes from 'prop-types'

import {
  CheckBoxGroupFooter,
  CheckBoxGroupHeader,
  CheckBoxGroupItem,
  CheckBoxGroupWrapper
} from './styled/checkBoxGroup.styled'

function CheckBoxGroup({ footer, header, items, checked, setChecked }) {
  return (
    <CheckBoxGroupWrapper onClick={e => e.stopPropagation()}>
      {header && <CheckBoxGroupHeader>{header}</CheckBoxGroupHeader>}
      <div className='item-container'>
        {items?.map((item, index) => {
          const { name, label, localizationKey } = item
          return (
            <CheckBoxGroupItem key={index} onClick={() => setChecked(name)}>
              <span>{tr(localizationKey, label)}</span>
              <span className={cn('checkbox', { checked: checked(name) })} data-test={`toggle-${name}`} />
            </CheckBoxGroupItem>
          )
        })}
      </div>
      {footer && <CheckBoxGroupFooter>{footer}</CheckBoxGroupFooter>}
    </CheckBoxGroupWrapper>
  )
}

export default CheckBoxGroup

CheckBoxGroup.propTypes = {
  footer: PropTypes.any,
  header: PropTypes.any,
  items: PropTypes.array
}
