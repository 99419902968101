import styled from 'styled-components'

export const GraphPaneWrapper = styled.div`
  min-height: 30rem;

  display: grid;
  .graph {
    width: 100%;
    height: 30rem;
  }
`
