import { selectCurrentUserId, useAppSelector, useGetUserExpenseLimitsQuery } from 'mmfintech-backend-api'
import { formatMoney, tr } from 'mmfintech-commons'

import { ProgressBarInfoWrapper, ProgressBarWithInfoWrapper } from './styles/SpendingBar.styled'

function SpendingLimitBarUser() {
  const merchantUserId = useAppSelector(selectCurrentUserId)
  const { data: userExpenseLimits } = useGetUserExpenseLimitsQuery({ merchantUserId })

  const { availableMonthlyVolume, monthlyVolume, currency, spentMonthlyVolume } = userExpenseLimits || {}

  const availableMonthlyVol = availableMonthlyVolume > 0 ? availableMonthlyVolume : 0
  const progressPercentage = monthlyVolume ? 100 - (availableMonthlyVol / monthlyVolume) * 100 : 100

  if (!userExpenseLimits) {
    return null
  }

  return (
    <div>
      <ProgressBarInfoWrapper>
        <div>
          <span className='info-text'>
            {tr('FRONTEND.USERS.EXPENSE_MANAGEMENT.DASHBOARD.MONEY_SPENT', 'Money Spent:')}
          </span>
          {formatMoney(spentMonthlyVolume, currency)}
        </div>
        <div>
          <span className='info-text'>
            {tr('FRONTEND.USERS.EXPENSE_MANAGEMENT.DASHBOARD.MONTHLY_LIMIT', 'Monthly Limit:')}{' '}
          </span>
          {monthlyVolume ? formatMoney(monthlyVolume, currency) : <span className='infinity'>∞</span>}
        </div>
      </ProgressBarInfoWrapper>
      <ProgressBarWithInfoWrapper percentage={progressPercentage} monthlyVolume={monthlyVolume}>
        <div className='progress-bar'>
          <div>{monthlyVolume ? Math.round(progressPercentage) : 0}%</div>
        </div>
      </ProgressBarWithInfoWrapper>
    </div>
  )
}

export default SpendingLimitBarUser
