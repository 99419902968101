import styled from 'styled-components'

export const SingleActiveVoucherContainer = styled.div`
  width: 100%;
  max-width: 90rem;
  box-shadow: -6px -6px 8px 0px #ffffff99, 3px 4px 8.2px -1px #1e293b1a, 2px 2px 4px 0px #00000008 inset;

  border-radius: 1.6rem;

  .single-voucher-top-banner {
    background-color: #699cfc;
    width: 100%;
    height: 1.4rem;
    border-radius: 1.6rem 1.6rem 0 0;
    &.bulk {
      background-color: #3cd1a3;
    }
  }
  .single-voucher-top-side {
    padding: 3.2rem;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #ededed;
    margin-bottom: 5rem;

    .single-voucher-amount {
      display: flex;
      flex-direction: column;
      .amount-title {
        font-size: 1.4rem;
        opacity: 0.5;
      }
      .amount-content {
        font-size: 3.2rem;
        font-weight: 600;

        .amount-content-currency {
          font-size: 1.6rem;
          font-weight: 600;
        }

        @media (max-width: 400px) {
          font-size: 2.2rem;
        }
      }
    }
    .single-voucher-pin-wrapper {
      display: flex;
      justify-content: center;
      border: 1px solid #d8d9dc;
      border-radius: 3.3rem;
      width: 15rem;
      height: 5.5rem;
      align-items: center;
      position: relative;
      .single-voucher-pin {
        display: flex;
        flex-direction: column;
        position: absolute;
        left: 20px;
        font-size: 1.4rem;
        font-weight: 600;
        .single-voucher-pin-security {
          height: 10px;
        }
      }
      .single-voucher-pin-icon {
        transition: right 0.5s, left 0.5s;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 4rem;
        height: 4rem;
        border-radius: 50%;
        background-color: #699cfc;
        position: absolute;
        z-index: 3;
        right: 0.8rem;
        &.left {
          transition: right 0.5s, left 0.5s;
          right: 10rem;
        }
        &.bulk {
          background-color: #3cd1a3;
        }
        svg {
          filter: brightness(0) saturate(1) invert(1);
        }
        .single-voucher-pin-icon-number {
          color: white;
          font-size: 1.8rem;
          font-weight: 600;
        }
      }
      .single-voucher-received-pin-wrapper {
        position: absolute;
        top: 1.7rem;
        .single-voucher-received-pin-overlay {
          transition: width 0.5s;
          background-color: #f6f7f8;
          position: absolute;
          height: 2rem;
          z-index: 2;
          width: 8rem;
          left: -27px;
        }
        .single-voucher-received-pin {
          position: absolute;
          z-index: 1;
          font-size: 1.4rem;
        }
        &.show {
          .single-voucher-received-pin-overlay {
            width: 0;
          }
        }
        &.bulk {
          .single-voucher-received-pin-overlay {
            width: 0;
          }
          .single-voucher-received-pin {
            width: 10rem;
            top: -6px;
            left: -20px;
          }
        }
      }
    }
  }
  .single-voucher-bottom-side {
    padding: 0 3rem 0 3rem;
    display: flex;
    flex-direction: column;
    gap: 1.6rem;

    .single-voucher-bottom-side-title {
      font-size: 1.2rem;
      font-weight: 600;
    }
    .single-voucher-bottom-side-container {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      .single-voucher-bottom-side-detail-wrapper {
        display: flex;
        gap: 5rem;
        flex-wrap: wrap;
        margin-bottom: 3rem;
        .single-voucher-bottom-side-content {
          .single-voucher-bottom-side-wrapper {
            display: flex;
            gap: 1.6rem;
            align-items: center;
            .single-voucher-bottom-side-icon {
              display: flex;
              justify-content: center;
              align-items: center;
              width: 4rem;
              height: 4rem;
              border-radius: 1.2rem;
            }
            .single-voucher-bottom-side-detail-container {
              display: flex;
              flex-direction: column;
              .single-voucher-bottom-side-detail-title {
                font-weight: 1.4rem;
                line-height: 2rem;
                opacity: 0.5;
              }
              .single-voucher-bottom-side-detail-content {
                font-size: 1.6rem;
                font-weight: 600;
                line-height: 2.1rem;
              }
            }
          }
        }
      }
      .single-voucher-bottom-side-redeem-button {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 0.8rem;
        height: 4.8rem;
        padding: 1.4rem 2.6rem;
        border-radius: 3.6rem;
        border: 1px solid #d8d9dc;
        cursor: pointer;
        margin-bottom: 3rem;

        span {
          user-select: none;
          font-size: 1.6rem;
          font-weight: 500;
        }
      }
    }
  }
`

export const SingleHistoryVoucherContainer = styled.div`
  width: 100%;
  border-radius: 1.6rem;
  max-width: 100rem;
  padding: 2.4rem;
  display: flex;
  justify-content: space-between;
  position: relative;
  background-color: white;
  border: 1px solid #dcdfe5;

  @media (max-width: 1024px) {
    gap: 2rem;
    flex-direction: column;
  }

  .single-history-voucher-amount {
    display: flex;
    flex-direction: column;
    .single-history-voucher-amount-title {
      font-size: 1.4rem;
      opacity: 0.5;
    }
    .single-history-voucher-amount-content {
      width: 7rem;
      font-size: 3.2rem;
      font-weight: 600;
      white-space: nowrap;

      .amount-content-currency {
        font-size: 1.6rem;
      }
    }
  }
  .single-history-voucher-details {
    display: flex;
    align-items: center;
    gap: 1.5rem;
    .single-voucher-details-icon {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 4rem;
      height: 4rem;
      border-radius: 1.2rem;
      opacity: 0.5;
    }
    .single-voucher-details-container {
      display: flex;
      flex-direction: column;
      .single-voucher-details-title {
        font-size: 1.4rem;
        opacity: 0.5;
      }
      .single-voucher-details-content {
        font-size: 1.6rem;
        font-weight: 600;
        width: 18rem;
      }
    }
  }
  .single-history-voucher-status {
    display: flex;
    align-items: center;
    @media (max-width: 1024px) {
      position: absolute;
      right: 3rem;
      top: 3rem;
    }
  }
`
