import { useMerchantAccounts } from 'mmfintech-backend-api'
import { Preloader } from 'mmfintech-portal-commons'

import { useUserPermissions } from '../../hooks'
import { AccountOverviewRow } from './AccountsOverviewRow'
import { AccountOverviewListWrapper } from './styled/accountOverview.styled'

export function AccountOverviewList() {
  const { isLoading, permissions } = useUserPermissions()
  const { accounts: paymentAccounts, accountsFetching } = useMerchantAccounts()

  if (accountsFetching || isLoading) {
    return <Preloader />
  }

  const accounts = paymentAccounts?.map((a: any) => {
    const permission = permissions?.find((perm: any) => a?.id === perm?.accountId)
    return { ...a, permission: permission?.accessLevel }
  })

  return (
    <AccountOverviewListWrapper>
      {accounts.map((account: any, i: number) => (
        <AccountOverviewRow account={account} key={i} />
      ))}
    </AccountOverviewListWrapper>
  )
}
