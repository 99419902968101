import { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import ReactTooltip from 'react-tooltip'

import MastercardLogo from '../../images/icons/cardTypes/mastercard-card-type.svg?react'
import MastercardPureLogo from '../../images/icons/cardTypes/mastercard-pure-logo.svg?react'
import UnionpayLogo from '../../images/icons/cardTypes/unionpay-card-type.svg?react'
import UnionpayPureLogo from '../../images/icons/cardTypes/unionpay-pure-icon.svg?react'
import VisaLogo from '../../images/icons/cardTypes/visa-card-type.svg?react'
import VisaPureLogo from '../../images/icons/cardTypes/visa-pure-icon.svg?react'
import PhysicalCardIcon from '../../images/physical-card-without-brand.svg?react'
import VirtualCardIcon from '../../images/virtual-card-without-brand.svg?react'
import SuccessIcon from '../../images/icons/card-success-icon.svg?react'

import cn from 'classnames'

import {
  paths,
  useChange3dsPhoneMutation,
  useGetAllCardsQuery,
  useGetProfileQuery,
  useGetSupportedCardCurrenciesQuery,
  useGetSupportedCardsQuery,
  useIssuePrepaidCardMutation
} from 'mmfintech-backend-api'
import {
  GlobalContext,
  OtpContext,
  checkFinalValue,
  fixPhoneNumber,
  isValidArray,
  isValidObject,
  isValidString,
  tr,
  translateError,
  useFormValues
} from 'mmfintech-commons'
import { Button, Checkbox, ErrorDisplay, Input, Spinner } from 'mmfintech-portal-commons'

import { CardOrderForm } from './CardOrderForm'
import { Stepper } from '../'

import { CardOrderModalWrapper } from './cardOrderModal.style'

const MAX_STEPS = [
  { step: 1, label: 'Card Type' },
  { step: 2, label: 'Contact' },
  { step: 3, label: 'Delivery' }
]

interface CardOrderModalProps {
  onClose?: () => void
  currentStep?: number
  selectedCardType?: 'physical' | 'virtual'
  issueCard?: boolean
  passedData?: any
  error?: any
  virtualCardBrand?: string
  physicalCardBrand?: string
  cardCurrency?: string
}

const findCardBrand = (cardBrand: string, logoOnly?: boolean) => {
  switch (cardBrand) {
    case 'MASTERCARD':
      return logoOnly ? <MastercardPureLogo /> : <MastercardLogo />
    case 'VISA':
      return logoOnly ? <VisaPureLogo /> : <VisaLogo />
    case 'UNIONPAY':
      return logoOnly ? <UnionpayPureLogo /> : <UnionpayLogo />
    default:
      break
  }
}

// const supportedCards = {
//   virtual: {
//     GBP: ['MASTERCARD', 'VISA', 'UNIONPAY'],
//     EUR: ['MASTERCARD', 'UNIONPAY', 'VISA'],
//     BGN: ['MASTERCARD'],
//     BGN2: []
//   },
//   physical: { GBP: ['MASTERCARD', 'VISA', 'UNIONPAY'], EUR: ['MASTERCARD'], BGN: [], BGN2: [] }
// }

// const supportedCardCurrencies = [
//   {
//     currencyCode: 'GBP',
//     currencyFullName: 'British Pound Sterling',
//     isoNumeric: '826',
//     precision: 2,
//     supportedForCardIssuing: true
//   },
//   {
//     currencyCode: 'EUR',
//     currencyFullName: 'British Pound Sterling',
//     isoNumeric: '826',
//     precision: 2,
//     supportedForCardIssuing: true
//   },
//   {
//     currencyCode: 'BGN',
//     currencyFullName: 'British Pound Sterling',
//     isoNumeric: '826',
//     precision: 2,
//     supportedForCardIssuing: true
//   },
//   {
//     currencyCode: 'BGN2',
//     currencyFullName: 'British Pound Sterling',
//     isoNumeric: '826',
//     precision: 2,
//     supportedForCardIssuing: true
//   }
// ]

export const CardOrderModal = ({
  onClose,
  currentStep,
  selectedCardType,
  issueCard,
  passedData,
  error,
  virtualCardBrand,
  physicalCardBrand,
  cardCurrency
}: CardOrderModalProps) => {
  const history = useHistory()
  const [step, setStep] = useState<number>(currentStep || 1)
  const [cardType, setCardType] = useState<'physical' | 'virtual'>(selectedCardType)
  const { setOtpOnSuccess } = useContext(OtpContext)
  const { modalShow, modalHide } = useContext(GlobalContext)
  const [phoneNumber, setPhoneNumber] = useState<string>(null)
  const [issuingError, setIssuingError] = useState(null)
  const [selectedPhysicalBrand, setSelectedPhysicalBrand] = useState(physicalCardBrand)
  const [selectedVirtualBrand, setSelectedVirtualBrand] = useState(virtualCardBrand)
  const [enablePhysicalCardBrandOptions, setEnablePhysicalCardBrandOptions] = useState<boolean>(null)
  const [enableVirtualCardBrandOptions, setEnableVirtualCardBrandOptions] = useState<boolean>(null)
  const [enableCurrencySelect, setEnableCurrencySelect] = useState<boolean>(null)
  const [disablePhysicalCard, setDisablePhysicalCard] = useState<boolean>(false)
  const [disableVirtualCard, setDisableVirtualCard] = useState<boolean>(false)
  const [selectedCardCurrency, setSelectedCardCurrency] = useState(cardCurrency)
  const [prepareCardCurrencies, setPrepareCardCurrencies] = useState(null)

  const enablePhysicalCard = false // flag to disable the physical card

  const formValues = useFormValues({
    // cardHolderName: { required: true, validation: 'name', maxLength: 26, minLength: 3 },
    city: { required: true, validation: 'alphaNumeric+' },
    countryCode: { required: true },
    // dateOfBirth: { required: true, validation: 'safe-string' },
    // firstName: { required: true, validation: 'name' },
    // lastName: { required: true, validation: 'name' },
    phoneNumber: { required: true, validation: 'phone' },
    // title: { required: false },
    street: { required: true, validation: 'alphaNumeric+' },
    streetNumber: { required: true, validation: 'alphaNumeric+' },
    postalCode: { required: true, validation: 'alphaNumeric+' }
  })

  const [orderCard, { isLoading, error: cardIssueError }] = useIssuePrepaidCardMutation()
  const [changePhone, { error: changePhoneError }] = useChange3dsPhoneMutation()
  const { data: activeCards, isLoading: activeCardsLoading, error: activeCardsError } = useGetAllCardsQuery(null)
  const {
    data: supportedCardCurrencies,
    isLoading: supportedCardCurrenciesLoading,
    error: supportedCardCurrenciesError
  } = useGetSupportedCardCurrenciesQuery(null)
  const {
    data: supportedCards,
    error: supportedCardsError,
    isLoading: supportedCardsLoading
  } = useGetSupportedCardsQuery(null)
  const {
    hasValidatedPhone,
    phoneChecked,
    twoFactorPhone,
    phoneCheckLoading,
    refetch: recheckPhone
  } = useGetProfileQuery(null, {
    selectFromResult: ({ data, isSuccess, isLoading }) => {
      return {
        hasValidatedPhone: !!data?.phone3dsVerified,
        twoFactorPhone: data?.twoFactorPhone,
        phoneChecked: isSuccess,
        phoneCheckLoading: isLoading
      }
    }
  })

  const handlePhoneChange = async () => {
    setOtpOnSuccess(() => async (response: any) => {
      if (response) {
        setTimeout(() => {
          modalShow({
            header: tr('FRONTEND.DASHBOARD.ORDER_CARDS.TITLE', 'Order a card'),
            content: (
              <CardOrderModal
                onClose={modalHide}
                currentStep={2}
                selectedCardType={'virtual'}
                virtualCardBrand={selectedVirtualBrand}
                physicalCardBrand={selectedPhysicalBrand}
                cardCurrency={selectedCardCurrency}
              />
            ),
            options: {
              size: 'auto'
            }
          })
        }, 0)
      }
    })
    try {
      const state = checkFinalValue(phoneNumber, { validation: 'phone', required: true })

      if (state.valid) {
        const result = await changePhone({ phone: fixPhoneNumber(phoneNumber) }).unwrap()
        if (result && !result.challenge?.challengeId) {
          await recheckPhone().unwrap()
          modalShow({
            header: tr('FRONTEND.DASHBOARD.ORDER_CARDS.TITLE', 'Order a card'),
            content: (
              <CardOrderModal
                onClose={modalHide}
                currentStep={2}
                selectedCardType={'virtual'}
                virtualCardBrand={selectedVirtualBrand}
                physicalCardBrand={selectedPhysicalBrand}
                cardCurrency={selectedCardCurrency}
              />
            ),
            options: {
              size: 'auto'
            }
          })
        }
      } else {
        setIssuingError(translateError(state))
      }
    } catch (error) {
      return error
    }
  }

  const handleSubmit = async () => {
    setOtpOnSuccess(() => (response: any) => {
      if (response && response.externalCardId) {
        setTimeout(() => {
          modalShow({
            header: tr('FRONTEND.DASHBOARD.ORDER_CARDS.TITLE', 'Order a card'),
            content: (
              <CardOrderModal
                onClose={modalHide}
                currentStep={3}
                selectedCardType={cardType}
                virtualCardBrand={selectedVirtualBrand}
                physicalCardBrand={selectedPhysicalBrand}
                cardCurrency={selectedCardCurrency}
              />
            ),
            options: {
              size: 'auto'
            }
          })
        }, 0)
      }

      if (response && !response.externalCardId) {
        setTimeout(() => {
          modalShow({
            header: tr('FRONTEND.DASHBOARD.ORDER_CARDS.TITLE', 'Order a card'),
            content: (
              <CardOrderModal
                onClose={modalHide}
                currentStep={1}
                error={tr('FRONTEND.DASHBOARD.ORDER_CARDS.GENERAL_ERROR', 'Issuing failed')}
                virtualCardBrand={selectedVirtualBrand}
                physicalCardBrand={selectedPhysicalBrand}
                cardCurrency={selectedCardCurrency}
              />
            ),
            options: {
              size: 'auto'
            }
          })
        }, 0)
      }
    })
    try {
      let data = {} as any
      if (cardType === 'virtual') {
        data = {
          isVirtual: true,
          currencyCode: selectedCardCurrency || 'EUR',
          cardBrand: selectedVirtualBrand
        }
      } else {
        if (formValues.areValid()) {
          data = {
            // ...formValues.prepare(),
            isVirtual: false,
            currencyCode: selectedCardCurrency || 'EUR',
            cardBrand: selectedPhysicalBrand,
            address: {
              country: formValues.getValue('countryCode'),
              streetName: formValues.getValue('street'),
              streetNumber: formValues.getValue('streetNumber'),
              zipCode: formValues.getValue('postalCode'),
              city: formValues.getValue('city')
            }
            // country: undefined,
            // streetName: undefined,
            // streetNumber: undefined,
            // zipCode: undefined,
            // city: undefined
          }
        } else {
          return null
        }
      }
      if (cardType === 'physical' && !hasValidatedPhone) {
        setOtpOnSuccess(() => (response: any) => {
          if (response) {
            setTimeout(() => {
              modalShow({
                header: tr('FRONTEND.DASHBOARD.ORDER_CARDS.TITLE', 'Order a card'),
                content: (
                  <CardOrderModal
                    onClose={modalHide}
                    currentStep={2}
                    selectedCardType={cardType}
                    issueCard={true}
                    passedData={formValues.prepare()}
                    virtualCardBrand={selectedVirtualBrand}
                    physicalCardBrand={selectedPhysicalBrand}
                    cardCurrency={selectedCardCurrency}
                  />
                ),
                options: {
                  size: 'auto'
                }
              })
            }, 0)
          }
        })
        const changeResult = await changePhone({ phone: fixPhoneNumber(formValues.getValue('phoneNumber')) }).unwrap()
        if (changeResult.challenge) {
          return changeResult
        }
      }

      const result = await orderCard(data).unwrap()
      if (step !== 3 && result && result.externalCardId) {
        setStep(step => step + 1)
      }
      if (result && !result.externalCardId) {
        setIssuingError(tr('FRONTEND.DASHBOARD.ORDER_CARDS.GENERAL_ERROR', 'Issuing failed'))
      }
      return result
    } catch (err) {
      return err
    }
  }

  useEffect(() => {
    if (step == 2 && cardType == 'virtual' && hasValidatedPhone) {
      void handleSubmit()
    }
    if (step == 2 && cardType == 'physical' && issueCard) {
      if (formValues && passedData) {
        Object.entries(passedData).forEach(el => {
          const [key, value] = el
          formValues.setValue(key, value)
        })
        if (passedData.address) {
          Object.entries(passedData.address).forEach(el => {
            const [key, value] = el
            formValues.setValue(key, value)
          })
        }
      }

      void handleSubmit()
    }
  }, [step, hasValidatedPhone, issueCard])

  useEffect(() => {
    if (isValidString(twoFactorPhone)) {
      setPhoneNumber(twoFactorPhone)
    }
  }, [phoneChecked])

  useEffect(() => {
    if (error) {
      setIssuingError(error)
    }
  }, [error])

  useEffect(() => {
    if (supportedCardCurrencies && currentStep !== 3 && !selectedCardCurrency) {
      const prepCountries = supportedCardCurrencies.map(country => {
        return { ...country, value: country.currencyCode, label: country.currencyCode }
      })
      setPrepareCardCurrencies(prepCountries)
      setSelectedCardCurrency(prepCountries?.[0]?.currencyCode)
      if (prepCountries.length > 1 && !currentStep) {
        setEnableCurrencySelect(true)
        setStep(0)
      }
    }
  }, [supportedCardCurrencies])

  useEffect(() => {
    if (isValidArray(supportedCards?.physical?.[selectedCardCurrency])) {
      setSelectedPhysicalBrand(supportedCards?.physical?.[selectedCardCurrency][0])
    }
    if (supportedCards?.physical?.[selectedCardCurrency]?.length > 1) {
      setEnablePhysicalCardBrandOptions(true)
    } else {
      setEnablePhysicalCardBrandOptions(false)
    }

    if (isValidArray(supportedCards?.virtual?.[selectedCardCurrency])) {
      setSelectedVirtualBrand(supportedCards?.virtual?.[selectedCardCurrency][0])
    }
    if (supportedCards?.virtual?.[selectedCardCurrency]?.length > 1) {
      setEnableVirtualCardBrandOptions(true)
    } else {
      setEnableVirtualCardBrandOptions(false)
    }
    if (cardType && !selectedCardType) {
      setCardType(null)
    }
  }, [selectedCardCurrency, supportedCards, selectedCardType])

  useEffect(() => {
    if (
      (isValidArray(activeCards) && activeCards.find(card => card.isVirtual != true)) ||
      !isValidArray(supportedCards?.physical?.[selectedCardCurrency])
    ) {
      setDisablePhysicalCard(true)
    } else {
      setDisablePhysicalCard(false)
    }

    if (
      (isValidArray(activeCards) && activeCards.find(card => card.isVirtual == true)) ||
      !isValidArray(supportedCards?.virtual?.[selectedCardCurrency])
    ) {
      setDisableVirtualCard(true)
    } else {
      setDisableVirtualCard(false)
    }
  }, [activeCards, supportedCards, selectedCardCurrency])

  return (
    <CardOrderModalWrapper large={step === 1}>
      {activeCardsLoading || supportedCardsLoading || supportedCardCurrenciesLoading ? (
        <Spinner />
      ) : (
        <>
          <div className='card-order-header-wrapper'>
            <span className='card-order-header-title'>{tr('FRONTEND.CARD_ORDER.HEADER_LABEL', 'Order Card')}</span>
            <div className='card-order-stepper'>
              <Stepper currentStep={enableCurrencySelect ? step + 1 : step} maxStep={MAX_STEPS} />
            </div>
          </div>
          {step === 0 && enableCurrencySelect && (
            <div className='card-order-currency-select'>
              <Input
                type='select'
                options={prepareCardCurrencies}
                value={selectedCardCurrency}
                onChange={(_, value) => {
                  setSelectedCardCurrency(value)
                }}
              />
            </div>
          )}
          {step === 1 && (
            <div className='card-order-type-selection'>
              <ErrorDisplay error={[issuingError, supportedCardsError, supportedCardCurrenciesError]} />

              {enablePhysicalCard && (
                <div
                  className={cn('card-order-flag-wrapper', {
                    open: cardType === 'physical' && enablePhysicalCardBrandOptions,
                    'card-unavailable': disablePhysicalCard
                  })}
                  data-for={`card-order-physical-card`}
                  data-tip={
                    !isValidArray(supportedCards?.physical?.[selectedCardCurrency])
                      ? tr(
                          'FRONTEND.CARD_ORDER.TOOLTIP_UNAVAILABLE_PHYSICAL_CARD',
                          'This currency do not support physical card.'
                        )
                      : tr('FRONTEND.CARD_ORDER.TOOLTIP_UNAVAILABLE_PHYSICAL_CARD', 'You already have physical card.')
                  }>
                  {/* <div
                  className={cn('card-selection-background', {
                    'card-background-selected': cardType === 'physical'
                  })}></div> */}
                  <div
                    className={cn('card-order-card-type-container', {
                      types: cardType === 'physical' && enablePhysicalCardBrandOptions,
                      'card-selected': cardType === 'physical'
                    })}
                    onClick={() => {
                      !disablePhysicalCard && setCardType('physical')
                    }}>
                    <div className='card-order-check-icon'>
                      <Checkbox checked={cardType === 'physical'} onClick={() => setCardType('physical')} />
                    </div>
                    <div className='card-order-card-icon'>
                      <PhysicalCardIcon />
                      <div className='card-brand-icon'>{findCardBrand(selectedPhysicalBrand, true)}</div>
                    </div>
                    <div className='card-order-card-content'>
                      <div className='card-order-card-title'>
                        {tr('FRONTEND.DASHBOARD.CARDS_ORDER.PHYSICAL.TITLE', 'Physical card')}
                      </div>
                      <div className='card-order-card-text'>
                        {tr(
                          'FRONTEND.DASHBOARD.CARDS_ORDER.PHYSICAL.TEXT',
                          'A contactless debit card will be delivered to you. Your card balance will be GBP with a new account.'
                        )}
                      </div>
                    </div>
                  </div>
                  {enablePhysicalCardBrandOptions && (
                    <div className={cn('card-order-choose-card-brand', { open: cardType === 'physical' })}>
                      {isValidObject(supportedCards?.physical?.[selectedCardCurrency]) &&
                        supportedCards?.physical?.[selectedCardCurrency].map(brand => {
                          return (
                            <div
                              onClick={() => setSelectedPhysicalBrand(brand)}
                              className={`card-order-brand-wrapper ${
                                brand === selectedPhysicalBrand ? 'selected' : ''
                              }`}>
                              <div className='.card-order-chose-brand'>
                                <div>{findCardBrand(brand as string)}</div>
                                <span className='card-order-chose-brand-label'>{brand}</span>
                              </div>
                              <Checkbox
                                onClick={() => setSelectedPhysicalBrand(brand)}
                                className='card-order-checkbox'
                                checked={selectedPhysicalBrand === brand}
                              />
                            </div>
                          )
                        })}
                    </div>
                  )}
                  {disablePhysicalCard && (
                    <ReactTooltip
                      id={`card-order-physical-card`}
                      textColor='black'
                      backgroundColor='white'
                      effect='solid'
                      className='custom-tool-tip-component'
                      padding='8px 6px'
                      place={'right'}>
                      {!isValidArray(supportedCards?.physical?.[selectedCardCurrency])
                        ? tr(
                            'FRONTEND.CARD_ORDER.TOOLTIP_UNAVAILABLE_PHYSICAL_CARD',
                            'This currency do not support physical card.'
                          )
                        : tr(
                            'FRONTEND.CARD_ORDER.TOOLTIP_UNAVAILABLE_PHYSICAL_CARD',
                            'You already have physical card.'
                          )}
                    </ReactTooltip>
                  )}
                </div>
              )}

              <div
                className={cn('card-order-flag-wrapper', {
                  open: cardType === 'virtual' && enableVirtualCardBrandOptions,
                  'card-unavailable': disableVirtualCard
                })}
                data-for={`card-order-virtual-card`}
                data-tip={
                  !isValidArray(supportedCards?.virtual?.[selectedCardCurrency])
                    ? tr(
                        'FRONTEND.CARD_ORDER.TOOLTIP_UNAVAILABLE_VIRTUAL_CARD',
                        'This currency do not support virtual card.'
                      )
                    : tr('FRONTEND.CARD_ORDER.TOOLTIP_UNAVAILABLE_VIRTUAL_CARD', 'You already have virtual card.')
                }>
                <div
                  className={cn('card-order-card-type-container', {
                    types: cardType === 'virtual' && enableVirtualCardBrandOptions,
                    'card-selected': cardType === 'virtual'
                  })}
                  onClick={() => {
                    !disableVirtualCard && setCardType('virtual')
                  }}>
                  <div className='card-order-check-icon'>
                    <Checkbox
                      className='card-order-checkbox'
                      checked={cardType === 'virtual'}
                      onClick={() => setCardType('virtual')}
                    />
                  </div>
                  <div className='card-order-card-icon'>
                    <VirtualCardIcon />
                    <div className='card-brand-icon '>{findCardBrand(selectedVirtualBrand, true)}</div>
                  </div>
                  <div className='card-order-card-content'>
                    <div className='card-order-card-title'>
                      {tr('FRONTEND.DASHBOARD.CARDS_ORDER.VIRTUAL.TITLE', 'Virtual card')}
                    </div>
                    <div className='card-order-card-text'>
                      {tr(
                        'FRONTEND.DASHBOARD.CARDS_ORDER.VIRTUAL.TEXT',
                        'Get virtual card and manage your online payments. Your card balance will be GBP with a new account.'
                      )}
                    </div>
                  </div>
                </div>
                {enableVirtualCardBrandOptions && (
                  <div className={cn('card-order-choose-card-brand', { open: cardType === 'virtual' })}>
                    {isValidObject(supportedCards?.virtual) &&
                      supportedCards?.virtual?.[selectedCardCurrency].map(brand => {
                        return (
                          <div
                            onClick={() => setSelectedVirtualBrand(brand)}
                            className={`card-order-brand-wrapper ${brand === selectedVirtualBrand ? 'selected' : ''}`}>
                            <div className='card-order-chose-brand'>
                              {findCardBrand(brand as string)}
                              <span>{brand}</span>
                            </div>
                            <Checkbox
                              className='card-order-checkbox'
                              checked={selectedVirtualBrand === brand}
                              onClick={() => setSelectedVirtualBrand(brand)}
                            />
                          </div>
                        )
                      })}
                  </div>
                )}
              </div>
              {disableVirtualCard ? (
                <ReactTooltip
                  id={`card-order-virtual-card`}
                  textColor='black'
                  backgroundColor='white'
                  effect='solid'
                  className='custom-tool-tip-component'
                  padding='8px 6px'
                  place={'right'}>
                  {!isValidArray(supportedCards?.virtual?.[selectedCardCurrency])
                    ? tr(
                        'FRONTEND.CARD_ORDER.TOOLTIP_UNAVAILABLE_VIRTUAL_CARD',
                        'This currency do not support virtual card.'
                      )
                    : tr('FRONTEND.CARD_ORDER.TOOLTIP_UNAVAILABLE_VIRTUAL_CARD', 'You already have virtual card.')}
                </ReactTooltip>
              ) : null}
            </div>
          )}
          {step === 2 && cardType === 'physical' && enablePhysicalCard && (
            <CardOrderForm
              formValues={formValues}
              cardIssueError={cardIssueError}
              cardIssueLoading={isLoading}
              changePhoneError={changePhoneError || issuingError}
            />
          )}
          {step === 3 && (
            <div className='card-order-success-wrapper'>
              <SuccessIcon />
              <div>
                <div className='card-order-success-title'>
                  {tr('FRONTEND.DASHBOARD.CARDS_ORDER.SUCCESS.TITLE', 'You have successfully ordered Jetonbank Card!')}
                </div>
                <div className='card-order-success-subtitle'>
                  {selectedCardType == 'physical'
                    ? tr(
                        'FRONTEND.DASHBOARD.CARDS_ORDER.SUCCESS.SUBTITLE',
                        'Your card will be ready to be delivered within 1-2 weeks.'
                      )
                    : tr(
                        'FRONTEND.DASHBOARD.CARDS_ORDER.SUCCESS.VIRTUAL',
                        'Your card will be available in the Cards menu.'
                      )}
                </div>
              </div>
            </div>
          )}
        </>
      )}

      <ErrorDisplay error={activeCardsError} />
      {step === 2 &&
        cardType === 'virtual' &&
        (isLoading ? (
          <div className='card-order-issuing-message'>
            <Spinner />
            <span style={{ marginBottom: '24px' }}>
              {tr('FRONTEND.DASHBOARD.CARDS_ORDER.ISSUING_MESSAGE', 'Your virtual card is now being issued.')}
            </span>
          </div>
        ) : (
          <>
            {phoneCheckLoading && <Spinner />}
            <ErrorDisplay error={cardIssueError?.error || cardIssueError || changePhoneError} />
          </>
        ))}
      {step == 2 && cardType === 'virtual' && phoneChecked && !hasValidatedPhone && (
        <div className='card-order-phone-section'>
          {/* <div className='card-order-phone-icon'><PhoneIcon /></div> */}
          <div className='card-order-phone-instructions'>
            {tr(
              'FRONTEND.DASHBOARD.CARDS_ORDER.PHONE_INSTRUCTIONS',
              "Please enter your phone number. We'll send you a code via SMS"
            )}
          </div>
          <Input
            label={tr('FRONTEND.DASHBOARD.CARDS_ORDER.LABEL.PHONE_NUMBER', 'Phone number')}
            type='phone'
            onChange={(_: any, value: string) => {
              setPhoneNumber(value)
              setIssuingError(null)
            }}
            value={phoneNumber}
          />
          <ErrorDisplay error={issuingError} />
        </div>
      )}
      <div className='card-order-modal-buttons-wrapper'>
        {!(step === 2 && isLoading) && !(cardIssueError && cardType !== 'physical') && (
          <Button
            disabled={step === 1 && !cardType}
            text={
              step === 1 || step === 0
                ? tr('FRONTEND.DASHBOARD.CARDS_ORDER.CONTINUE_BUTTON', 'Continue')
                : step === 2
                ? tr('FRONTEND.DASHBOARD.CARDS_ORDER.COMPLETE_BUTTON', 'Accept and complete order')
                : tr('FRONTEND.DASHBOARD.CARDS_ORDER.FINISH_BUTTON', 'Continue to Overview')
            }
            onClick={async () => {
              if (step === 3) {
                onClose && onClose()
                return history.push(paths.dashboard())
              }
              if (step === 2) {
                if ((phoneChecked && hasValidatedPhone) || cardType === 'physical') {
                  return await handleSubmit()
                } else {
                  return await handlePhoneChange()
                }
              }
              if (step !== 3) {
                setStep(step => step + 1)
              }
            }}
          />
        )}
        {step == 0 ? (
          <Button
            text={tr('FRONTEND.BUTTONS.CANCEL', 'Cancel')}
            className='card-order-modal-back-button'
            onClick={() => {
              onClose()
            }}
            color='alternative'
            // color?: 'primary' | 'secondary' | 'alternative' | 'round-primary' | 'round-secondary' | 'delete' | 'round-alternative';
          />
        ) : null}
        {((enableCurrencySelect && step > 0) || step > 1) && step !== 3 ? (
          <Button
            text={tr('FRONTEND.DASHBOARD.CARDS_ORDER.BACK_BUTTON', 'Back')}
            className='card-order-modal-back-button'
            onClick={async () => {
              if (step > 1 || (enableCurrencySelect && step > 0)) {
                setStep(step - 1)
              }
            }}
          />
        ) : null}
      </div>
    </CardOrderModalWrapper>
  )
}
