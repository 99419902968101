import styled from 'styled-components'

export const UploadInvoiceWrapper = styled.div`
  background: #ffffff;
  border-radius: ${({ theme }) => theme.containerBorderRadius};
  box-shadow: ${({ theme }) => theme.containerDropShadow};

  width: 100%;
  max-width: 70rem;
  padding: 5rem 5rem 4rem;

  display: flex;
  flex-flow: column nowrap;
  align-items: center;

  font-family: ${({ theme }) => theme.fontName}, sans-serif;
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 500;
  line-height: 2.2rem;

  color: ${({ theme }) => theme.contentWrapper.textColor};

  .title {
    color: #131e3d;
    font-family: inherit;
    font-size: 2.4rem;
    font-style: normal;
    font-weight: 500;
    line-height: 3.6rem;
    text-align: center;
  }

  .upload-box {
    cursor: pointer;
    width: 100%;

    border-radius: ${({ theme }) => theme.containerBorderRadius};
    border: 1px dashed #dadada;
    padding: 1rem 2rem;
    margin: 4rem 0 1rem;
    height: 14rem;

    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;

    img,
    svg {
      margin-right: 2rem;
    }
  }

  .file-types {
    margin-bottom: 4rem;

    color: #828282;
    font-size: 1.2rem;
    font-style: italic;
    font-weight: 500;
    line-height: 1.5rem;
    text-align: center;
  }

  .buttons {
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;

    button {
      max-width: 17rem;
      margin-left: 1rem;

      :first-of-type {
        margin-left: 0;
      }
    }
  }
`
