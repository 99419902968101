import styled from 'styled-components'
import backgroundSource from '../../../images/card-banner-background-image.png'

export const CardsBannerContainer = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  height: 100%;
  max-width: 35.5rem;

  gap: 2rem;

  .cards-banner-title {
    font-size: 18px;
    font-weight: 400;
    line-height: 21.09px;
    text-align: left;
    color: #131e3d;
  }

  .cards-banner-container {
    background-color: #ffffff;
    padding: 1.2rem;
    border-radius: 1rem;
    display: flex;
    gap: 0.8rem;
    flex-direction: column;
    .cards-top-container {
      background-image: url('${backgroundSource}');
      background-position: center;
      background-size: cover;
      height: 21rem;
      border-radius: 1rem;

      .cards-banner-bank-cards {
        margin: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        svg {
        }
      }
    }
    .cards-buttons-wrapper {
      .cards-button {
        font-size: 14px;
        font-weight: 600;
        line-height: 18px;
        letter-spacing: -0.5px;
        text-align: center;
        height: 4.2rem;
        width: 100%;
        background-color: #131e3d;
        color: #ffffff;
        border-radius: 0.8rem;
      }
    }
  }
`
