import { useContext } from 'react'

import { GlobalContext, tr } from 'mmfintech-commons'
import {
  isOwnerOrAdministrator,
  selectCurrentUserRole,
  useAcceptLegalRequirementsMutation,
  useAppSelector
} from 'mmfintech-backend-api'
import { LegalRequirementResponse } from 'mmfintech-commons-types'
import { Button, ErrorDisplay } from 'mmfintech-portal-commons'

import { LegalRequirements } from './LegalRequirements'

import { ModalWrapper } from './LegalRequirementsModal.styled'

interface LegalRequirementsModal {
  requirements: LegalRequirementResponse[]
  onAccepted?: () => void
  isInModal?: boolean
}

export const LegalRequirementsModal = ({ requirements, onAccepted, isInModal }: LegalRequirementsModal) => {
  const { modalHide } = useContext(GlobalContext)
  const customerRole = useAppSelector(selectCurrentUserRole)

  const [acceptLegalRequirements, { error: acceptLegalRequirementsError, isLoading: acceptLegalRequirementsFetching }] =
    useAcceptLegalRequirementsMutation()

  const handleAccept = () => {
    const requirementIds = requirements.map(entry => entry.id)
    acceptLegalRequirements({ requirementIds })
      .unwrap()
      .then(() => {
        if (!isInModal) {
          modalHide()
        }
        onAccepted && onAccepted()
      })
      .catch(() => {
        return
      })
  }

  if (isOwnerOrAdministrator(customerRole)) {
    return (
      <ModalWrapper isInModal={isInModal}>
        <LegalRequirements customerRole={customerRole} requirements={requirements} />

        <ErrorDisplay error={acceptLegalRequirementsError} />

        <div className='buttons'>
          <Button
            text={tr('PORTAL.LEGAL.ACCEPT_BUTTON', 'Accept')}
            color='primary'
            onClick={handleAccept}
            loading={acceptLegalRequirementsFetching}
          />
        </div>
      </ModalWrapper>
    )
  }

  return (
    <ModalWrapper isInModal={isInModal}>
      <LegalRequirements customerRole={customerRole} requirements={requirements} />
      <div className='buttons'>
        <Button
          className='button-close'
          text={tr('FRONTEND.BUTTONS.CLOSE', 'Close')}
          color='primary'
          onClick={modalHide}
          loading={acceptLegalRequirementsFetching}
        />
      </div>
    </ModalWrapper>
  )
}
