import styled from 'styled-components'

import { CommonFlexRow } from '../../../components/jetonStyled'
import { devices } from '../../../constants'

type TabProps = {
  active: boolean
}
export const TabsWrapper = styled.div`
  ${CommonFlexRow()}
  justify-content: center;

  & > :first-of-type {
    border-top-left-radius: 1rem;
    border-bottom-left-radius: 1rem;
  }
  & > :last-of-type {
    border-top-right-radius: 1rem;
    border-bottom-right-radius: 1rem;
  }
`
export const Tab = styled.div<TabProps>`
  width: 100%;
  @media ${devices.xl} {
    width: 17.5rem;
  }
  font-size: 1.4rem;
  font-weight: 400;

  background-color: rgba(255, 255, 255, 1);
  color: rgba(19, 30, 61, 1);
  cursor: pointer;

  padding: 1.2rem 0;
  text-align: center;

  @media ${devices.xs} {
    border: 1px solid #edeeef;
  }
  @media ${devices.xl} {
    border: none;
  }

  ${({ active }) =>
    active &&
    `
    color: rgba(0, 102, 255, 1);
    background-color: rgba(0, 102, 255, 0.1);
  `}
`
