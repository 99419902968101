import { tr } from "mmfintech-commons"
import { Spinner } from "mmfintech-portal-commons"
import { useGetCryptoPlansSummaryQuery, useGetFiatPlansSummaryQuery } from "mmfintech-backend-api"

import SavingPlans from "./SavingPlans"
import { PlansSectionTitle, PlansSectionWrapper } from "./styled/plansSection.styled"

const PlansSection = ({ title = '' }) => {

    const { data: fiatPlans, isSuccess: fiatPlansSuccess } = useGetFiatPlansSummaryQuery()
    const { data: cryptoPlans, isSuccess: cryptoPlansSuccess } = useGetCryptoPlansSummaryQuery()

    return (
        <PlansSectionWrapper>
            <PlansSectionTitle>
                {title || tr('FRONTEND.SAVINGS.PLANS_TITLE', 'Available plans')}
            </PlansSectionTitle>
            <div className="available-plans">
                {fiatPlansSuccess && <SavingPlans title="Money" plans={fiatPlans} type="money" />}
                {cryptoPlansSuccess && <SavingPlans title="Crypto" plans={cryptoPlans} type="crypto" />}
                {(!fiatPlansSuccess || !cryptoPlansSuccess) && <Spinner />}
            </div>
        </PlansSectionWrapper>
    )
}

export default PlansSection