import { motion } from 'framer-motion'
import styled from 'styled-components'

import { CommonFlexColumn, CommonFlexRow } from '../../../components/jetonStyled'
import { devices } from '../../../constants'

export const SlidingContainer = styled(motion.div)`
  position: static;
  background: white;
  border-radius: 10px; // adjust as needed
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 3;

  width: 100%;

  max-height: 50rem;
  height: 100%;
  overflow: hidden;
  padding-bottom: 1rem;

  @media ${devices.xl} {
    position: fixed;
    max-width: 45rem;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0 2rem;

    li {
      display: grid;
      grid-template-columns: 4rem 1fr 10rem;
      grid-gap: 1.6rem;
      align-items: center;

      padding: 2.2rem 0 1.8rem;
      border-bottom: 1px solid #e2e4e9;
      &:last-of-type {
        border-bottom: none;
      }

      .content {
        ${CommonFlexColumn(0.4)}
        p {
          font-size: 1.4rem;
          font-weight: 500;
          margin: 0;
        }
        span {
          font-size: 1.2rem;
          font-weight: 400;
        }
      }
      .action-container {
        ${CommonFlexRow(0.6)};
        align-items: center;
        justify-content: center;

        font-size: 1.2rem;
      }
      .button {
        border-radius: 0.6rem;
        color: #fff;
        max-height: 4rem;
        box-shadow: 0;
        font-size: 1.4rem;
        font-weight: 500;
      }

      .button:hover {
        color: #fff;
      }
      .continue-button {
        background: #6bcdfd;
      }
      .continue-button:hover {
        background: #54c5fd;
      }
    }
  }
`

export const PillButton = styled.button<{ isActive?: boolean }>`
  position: fixed;
  bottom: 6.4rem; // adjust as needed
  right: 4.6rem; // adjust as needed
  width: 240px; // adjust as needed
  padding: 1.6rem 0;
  box-shadow: 0px 4px 10px 0px rgba(135, 153, 186, 0.2);
  border-radius: 28px; // This creates the pill shape
  background: ${({ isActive }) => (isActive ? 'rgba(255, 255, 255, 1)' : '#131E3D')};
  border: none;
  cursor: pointer;
  z-index: 3;
  color: rgba(49, 71, 135, 1);
  font-weight: 500;
  font-size: 1.6rem;

  ${CommonFlexRow(1)}
  align-items: center;
  justify-content: center;

  font-weight: 500;
  color: ${({ isActive }) => (isActive ? '#314787' : '#FFF')};
  p {
    margin: 0;
    font-size: 1.6rem;
  }
`

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background-color: #699cfc;

  font-size: 1.4rem;
  font-weight: 700;
  color: #fff;

  cursor: pointer;
`

export const MobileBanner = styled.div`
  flex: 1;
  width: 100%;
  padding: 0 1rem;
  ${CommonFlexRow(1)}
  align-items: center;

  .right {
    ${CommonFlexColumn()}
    justify-content: center;
    width: 100%;
    height: 100%;
    .banner-container {
      ${CommonFlexRow()}
      justify-content: space-between;
    }
    p {
      font-size: 1.4rem;
      font-weight: 500;
      margin: 0.5rem 0;
    }
    span {
      font-size: 1.2rem;
    }
  }
`
