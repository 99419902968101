import { memo } from 'react'

import isEqual from 'lodash.isequal'

import SideBarInnerRight from './SideBar/SideBarInnerRight'
import { Wrapper } from './styled/contentWrapper.styled'

function ContentWrapper({ children, sideBarInner }) {
  return (
    <Wrapper>
      <>{children}</>
      <SideBarInnerRight {...sideBarInner} />
    </Wrapper>
  )
}

export default memo(ContentWrapper, (prevProps, nextProps) => isEqual(prevProps, nextProps))
