import { useContext, useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import toast from 'react-hot-toast'

import { GlobalContext, isValidArray, tr, useFormValues } from 'mmfintech-commons'
import { api, useMerchantAccounts, useRedeemVoucherByIdMutation, useSelectedAccount } from 'mmfintech-backend-api'
import { Button, ErrorDisplay } from 'mmfintech-portal-commons'
import { Currency } from 'mmfintech-commons-types'

import { RedeemVoucherModalWrapper } from './redeemModal.styled'
import SelectAccount from '../../../components/SelectAccount'

interface RedeemModalProps {
  voucherId: string | number
  currency: string
  handleReloadVouchers: () => void
}

export const RedeemModal = ({ voucherId, currency, handleReloadVouchers }: RedeemModalProps) => {
  const { modalHide } = useContext(GlobalContext)
  const dispatch = useDispatch()
  const accounts = useRedeemVoucherEligibleAccounts(currency)
  const { selectedAccount, setSelectedAccount } = useSelectedAccount()

  const [redeemVoucher, { error, isLoading, reset }] = useRedeemVoucherByIdMutation()

  const formValues = useFormValues({
    destinationAccountId: { required: true },
    uuid: { required: true, value: voucherId }
  })

  const handleRedeemVoucher = async () => {
    if (formValues.areValid()) {
      try {
        await redeemVoucher(formValues.prepare()).unwrap()
        toast.success('You have successfully redeemed your voucher.')

        reset()
        dispatch(api.util.invalidateTags(['Vouchers']))
        handleReloadVouchers()
        modalHide()
      } catch {
        toast.error('Unable to redeem voucher. Please check voucher details or contact support for assistance.')
      }
    }
  }

  const filteredAccounts = useMemo(() => {
    return accounts.filter(account => account.currencyCode === currency)
  }, [accounts])

  useEffect(() => {
    if (isValidArray(filteredAccounts)) {
      setSelectedAccount(filteredAccounts[0])
    }
  }, [filteredAccounts])

  useEffect(() => {
    formValues.setValue('destinationAccountId', selectedAccount?.id)
  }, [selectedAccount])

  return (
    <RedeemVoucherModalWrapper className='redeem-voucher-modal-wrapper'>
      <div className='redeem-voucher-modal-content'>
        <span className='redeem-voucher-title'>{tr('FRONTEND.VOUCHER.REDEEM_VOUCHER_TITLE', 'Redeem Voucher')}</span>
        <SelectAccount
          accounts={filteredAccounts}
          label={tr('FRONTEND.TRANSACTIONS.ACCOUNT_LABEL', 'Account')}
          selectedAccount={selectedAccount}
          setSelectedAccount={setSelectedAccount}
        />
      </div>

      <ErrorDisplay error={error} />
      <div className='redeem-voucher-modal-actions'>
        <Button disabled={isLoading} text='Redeem' onClick={handleRedeemVoucher} />
        <Button text='Cancel' color='secondary' onClick={modalHide} />
      </div>
    </RedeemVoucherModalWrapper>
  )
}

const useRedeemVoucherEligibleAccounts = (voucherCurrencyCode?: string) => {
  const matchCurrency = voucherCurrencyCode ? voucherCurrencyCode : ''

  const { activeAccounts: accounts } = useMerchantAccounts({
    validCurrency: (currency: Currency) =>
      !!currency.supportedForCashVoucher && (voucherCurrencyCode ? matchCurrency.includes(currency.currencyCode) : true)
  })

  return accounts
}
