import { useContext } from 'react'

import { useGetMerchantQuery } from 'mmfintech-backend-api'
import { Button } from 'mmfintech-portal-commons'

import { SumSubContext } from '../../SumSubBanner'
import useOnboarding from '../../useOnboarding'
import { ActionComponent } from './ActionComponent'
import { OnboardingStatusEnum } from 'mmfintech-commons-types'

export const DisplayActions: React.FC<{ step?: string }> = ({ step }) => {
  const { setIsVisible } = useContext(SumSubContext)
  const { startOrContinueOnboarding } = useOnboarding()
  const { data: merchant } = useGetMerchantQuery()
  const { onboardingStatus } = merchant || {}
  const handleClick = () => {
    typeof setIsVisible === 'function' && setIsVisible(false)
    void startOrContinueOnboarding()
  }
  switch (step) {
    case 'kyb-questionnaire':
      switch (onboardingStatus) {
        case OnboardingStatusEnum.NOT_STARTED:
          return (
            <Button
              className='button start-button'
              type='button'
              color='secondary'
              text='Start'
              onClick={handleClick}
              data-test='start-questionnaire-button'
            />
          )
        case OnboardingStatusEnum.WAITING_QUESTIONNAIRE:
          return (
            <Button
              className='button continue-button'
              type='button'
              color='secondary'
              text='Continue'
              onClick={handleClick}
              data-test='continue-questionnaire-button'
            />
          )
        default:
          return <ActionComponent />
      }
    case 'sumsub-onboarding':
      switch (onboardingStatus) {
        case OnboardingStatusEnum.NOT_STARTED:
        case OnboardingStatusEnum.WAITING_QUESTIONNAIRE:
          return null
        case OnboardingStatusEnum.IN_PROGRESS:
          return (
            <Button
              className='button start-button'
              type='button'
              color='secondary'
              text='Start'
              onClick={handleClick}
              data-test='sumsub-onboarding-button'
            />
          )
        case OnboardingStatusEnum.UNDER_REVIEW:
        case OnboardingStatusEnum.WAITING_COMPLIANCE_APPROVAL:
          return <ActionComponent isDone={false} />
        default:
          return <ActionComponent />
      }
    case 'additional-documents':
      switch (onboardingStatus) {
        case OnboardingStatusEnum.NOT_STARTED:
        case OnboardingStatusEnum.WAITING_QUESTIONNAIRE:
        case OnboardingStatusEnum.IN_PROGRESS:
        case OnboardingStatusEnum.UNDER_REVIEW:
          return null
        case OnboardingStatusEnum.WAITING_ADDITIONAL_DOCUMENTS:
          return (
            <Button
              className='button start-button'
              type='button'
              color='secondary'
              text='Start'
              onClick={handleClick}
              data-test='sumsub-onboarding-button'
            />
          )
        case OnboardingStatusEnum.WAITING_COMPLIANCE_APPROVAL:
          return null
        case OnboardingStatusEnum.WAITING_BUSINESS_AGREEMENT_APPROVAL:
        case OnboardingStatusEnum.WAITING_OPS_APPROVAL:
          return <ActionComponent isDone={false} />
        default:
          return <ActionComponent />
      }
    default:
      return <ActionComponent />
  }
}
