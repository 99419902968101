import styled from 'styled-components'

import { CommonFlexColumn } from '../../../components/jetonStyled'
import { devices } from '../../../constants'

export const AmountPaneWrapper = styled.div`
  ${CommonFlexColumn(2)}

  max-width: 20rem;
  width: 100%;
  .pane-title {
    @media ${devices.xs} {
      font-size: 1.2rem;
      font-weight: 500;
    }
    @media ${devices.xl} {
      font-size: 1.6rem;
      font-weight: 500;
    }
  }
`

export const AmountContainer = styled.div`
  border-radius: 1rem;
  border: 2px solid #3cd1a3;
  background-color: rgba(60, 209, 163, 0.1);

  display: flex;
  flex-flow: row nowrap;
  gap: 1rem;
  color: #131e3d;
  font-size: 1.4rem;
  font-weight: 700;

  padding: 2rem;

  .amount-currency {
    display: flex;
    flex-flow: column nowrap;
    gap: 4px;
    font-size: 1.4rem;
    font-weight: 700;
    .currency {
      color: #131e3d;
      font-weight: 400;
      line-height: 1.2rem;
    }
  }
`
