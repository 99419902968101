import { invoiceStatuses, prepareInvoiceStatusOptions } from 'mmfintech-backend-api'
import { tr, useFormValues } from 'mmfintech-commons'
import { Button, Input } from 'mmfintech-portal-commons'

import {
  FilterButtons,
  FilterWrapper,
  FiltersContainer
} from '../transactions/elements/styled/transactionsFilter.styled'
import { FilterSelectStyles } from './styled/invoicesFilter.styled'

import CloseIcon from '../../images/icons/icon-close-transparent.svg?react'

function InvoicesFilter({ status, applyFilter, modalHide = null }) {
  const formValues = useFormValues({
    payerName: { validation: 'safe-string' },
    invoiceNumber: {},
    ownInvoiceNumber: {},
    status: { value: status || '' }
  })
  const clearAll = () => {
    if (formValues.count() > 0) {
      formValues.cleanup()
      applyFilter()
    }
  }
  return (
    <FilterWrapper>
      <FiltersContainer>
        <div className='top-row'>
          <h3>Filters</h3>
          {typeof modalHide === 'function' && <CloseIcon onClick={modalHide} />}
        </div>
        <Input
          type='text'
          data-test='filter-payer-name'
          label={tr('FRONTEND.INVOICES.LIST.SEARCH.CUSTOMER', 'Customer')}
          {...formValues.registerInput('payerName')}
        />
        <Input
          type='text'
          data-test='filter-invoice-number'
          label={tr('FRONTEND.INVOICES.LIST.SEARCH.INVOICE_NUMBER', 'Invoice №')}
          {...formValues.registerInput('invoiceNumber')}
        />
        <Input
          type='text'
          data-test='filter-own-invoice-number'
          label={tr('FRONTEND.INVOICES.LIST.SEARCH.ORIGINAL_INVOICE_NO', 'Original Invoice No')}
          {...formValues.registerInput('ownInvoiceNumber')}
        />
        <Input
          type='select'
          data-test='filter-status'
          label=' '
          styles={FilterSelectStyles}
          options={prepareInvoiceStatusOptions([
            '',
            invoiceStatuses.PAID,
            invoiceStatuses.OPENED,
            'OVERDUE',
            invoiceStatuses.CANCELED
          ])}
          {...formValues.registerInput('status')}
          hideErrorLine
          forceLabel
        />
        <FilterButtons>
          <Button
            color='round-secondary'
            text='Apply'
            onClick={() => applyFilter(formValues)}
            data-test='button-apply'
          />
          <Button
            id='clear-all'
            type='button'
            color='delete'
            onClick={clearAll}
            text={tr('FRONTEND.TRANSACTIONS.FILTER.CLEAR_ALL_BUTTON', 'Clear All')}
            data-test='reset-filter-button'
          />
        </FilterButtons>
      </FiltersContainer>
    </FilterWrapper>
  )
}

export default InvoicesFilter
