import React, { isValidElement, useContext, useEffect, useState } from 'react'
import { toast } from 'react-hot-toast'

import cn from 'classnames'

import AccountCreateModal from '../views/account/AccountCreateModal'
import { CurrencyIcon } from './CurrencyIcon'

import { Button } from 'mmfintech-portal-commons'
import { ThreeDots } from 'react-loader-spinner'
import {
  AccountButtonStyled,
  AccountListStyled,
  AccountStyled,
  AddButtonWrapper,
  SelectAccountError,
  SelectAccountLabel,
  SelectAccountStyled,
  SelectAccountWrapper
} from './styled/selectAccount.styled'

import {
  isOwnerOrAdministrator,
  selectCurrentUserRole,
  selectMerchantCapabilities,
  useAppSelector
} from 'mmfintech-backend-api'
import { formatMoney, GlobalContext, isValidArray, tr, useDropDownMenu } from 'mmfintech-commons'

import { AccountBalanceOut, TradeableAsset } from 'mmfintech-commons-types'

import ChevronDownIcon from '../images/icons/chevron-down-black.svg?react'
import ChevronUpIcon from '../images/icons/chevron-up-black.svg?react'
import PlusIcon from '../images/icons/plus.svg?react'

interface SelectAccountProps {
  staticDisplay?: boolean
  accounts?: AccountBalanceOut[]
  error?: string
  label?: string | React.ReactNode
  showAddButton?: boolean
  showAllAccounts?: boolean
  selectedAccount?: AccountBalanceOut
  setSelectedAccount?: (account: AccountBalanceOut) => void
  filteredCurrencies?: TradeableAsset['currency'][]
  noShadow?: boolean
  hasExchangeAssets?: boolean
  className?: string
  extraSection?: React.ReactNode
  noBorder?: boolean
  showId?: boolean
  loading?: boolean
}

function SelectAccount(props: SelectAccountProps) {
  const {
    staticDisplay = false, // if set, static account display will be presented
    accounts, // list of accounts to select from
    error, // error message (if needed)
    label, // label above the selection box
    showAddButton = false, // if set an "Add New Account" button will be added at the end of the list
    showAllAccounts = false, // if set, "All Account" option will be added at the beginning of the list
    selectedAccount, // selected account
    setSelectedAccount, // selected account setter
    filteredCurrencies, // list of supported currencies for the add new account screen
    noShadow = false,
    hasExchangeAssets = true,
    className,
    showId = false, // shows account id next to name
    noBorder = false, // hides border
    extraSection = null,
    loading
  } = props

  const { modalShow } = useContext(GlobalContext)

  const [preselectAccountId, setPreselectAccountId] = useState(0)

  const customerRole = useAppSelector(selectCurrentUserRole)
  const capabilities = useAppSelector(selectMerchantCapabilities)
  const { disableCurrencyAccountCreation } = capabilities || {}

  const { visible, hideDropDown, toggleDropDown } = useDropDownMenu()

  const toggle = () => !staticDisplay && toggleDropDown()

  const handleSelect = (account: AccountBalanceOut) => {
    hideDropDown()
    setSelectedAccount(account)
  }

  const handleCreateSuccess = (_, accountId) => {
    setPreselectAccountId(Number(accountId))
    toast.remove()
    toast.success('Account created')
  }
  const handleNewAccountClick = () => {
    modalShow({
      options: { closeOnClickOutside: false, size: 'auto', overflow: 'none' },
      content: <AccountCreateModal filteredCurrencies={filteredCurrencies} onSuccess={handleCreateSuccess} />
    })
  }

  const { balance, currencyCode, name, id } = selectedAccount || {}

  useEffect(() => {
    if (preselectAccountId) {
      const find = accounts.find(account => account.id === preselectAccountId)
      setPreselectAccountId(0)
      handleSelect(find)
    }
    // eslint-disable-next-line
  }, [accounts])

  return (
    <SelectAccountWrapper className={cn('select-account-wrapper', className)} data-test='account-dropdown'>
      {label && <SelectAccountLabel>{label}</SelectAccountLabel>}
      <SelectAccountStyled>
        <AccountButtonStyled
          className={cn({
            'no-shadow': noShadow,
            static: staticDisplay,
            error: error?.length,
            'no-border': noBorder
          })}
          onClick={toggle}>
          {loading ? (
            <span className='loading'>
              <ThreeDots height='12px' color='#1e63ff' />
            </span>
          ) : selectedAccount ? (
            <span className='account-info'>
              <span className='icon'>
                <CurrencyIcon currency={currencyCode} size='26px' />
              </span>
              <span className='content'>
                {id ? (
                  <span className='balance'>
                    <b>{tr('FRONTEND.COMPONENTS.SELECT_ACCOUNT.BALANCE_LABEL', 'Balance')}:</b>{' '}
                    {formatMoney(balance, currencyCode)}
                  </span>
                ) : null}
                {name && (
                  <span>
                    {name} {showId && id ? ` - ID: ${id}` : ''}
                  </span>
                )}
              </span>
            </span>
          ) : (
            <span className='no-account'>
              {showAllAccounts
                ? tr('FRONTEND.COMPONENTS.SELECT_ACCOUNT.ALL_ACCOUNTS', 'All Accounts')
                : tr('FRONTEND.COMPONENTS.SELECT_ACCOUNT.PLACEHOLDER', 'Select Account')}
            </span>
          )}
          {!staticDisplay && <span className='chevron'>{visible ? <ChevronUpIcon /> : <ChevronDownIcon />}</span>}
        </AccountButtonStyled>

        {!staticDisplay && (
          <AccountListStyled opened={visible} data-test='accounts-menu-content'>
            {showAllAccounts && (
              <AccountStyled data-test='all-accounts-menu-item' onClick={() => handleSelect(null)}>
                <span className='icon' />
                <span className='content'>
                  <span className='balance'>
                    {tr('FRONTEND.COMPONENTS.SELECT_ACCOUNT.ALL_ACCOUNTS', 'All Accounts')}
                  </span>
                </span>
              </AccountStyled>
            )}
            {!isValidArray(accounts) && (
              <AccountStyled data-test='all-accounts-menu-item' className='disabled'>
                <span className='icon'>
                  <span className='icon-all'>N/A</span>
                </span>
                <span className='content'>
                  <span className='balance'>
                    <b>
                      {hasExchangeAssets
                        ? tr('FRONTEND.COMPONENTS.SELECT_ACCOUNT.NO_ACCOUNTS', 'No Eligible Accounts')
                        : tr(
                            'FRONTEND.COMPONENTS.SELECT_ACCOUNT.CANNOT_EXCHANGE',
                            'Currently this asset cannot be exchanged'
                          )}
                    </b>
                  </span>
                </span>
              </AccountStyled>
            )}
            {accounts?.map(account => {
              const { id, currencyCode, balance, name: accName } = account

              return (
                <AccountStyled
                  key={id}
                  onClick={() => handleSelect(account)}
                  data-test='account-menu-item'
                  className='option'>
                  <span className='icon'>
                    <CurrencyIcon currency={currencyCode} size='26px' />
                  </span>
                  <span className='content'>
                    <span className='balance'>
                      {tr('FRONTEND.COMPONENTS.SELECT_ACCOUNT.BALANCE_LABEL', 'Balance')}:{' '}
                      {formatMoney(balance, currencyCode)}
                    </span>
                    <span>{accName}</span>
                  </span>
                </AccountStyled>
              )
            })}
            {showAddButton && isOwnerOrAdministrator(customerRole) && !disableCurrencyAccountCreation && (
              <AddButtonWrapper>
                <Button
                  type='button'
                  color='round-secondary'
                  text={tr('FRONTEND.COMPONENTS.SELECT_ACCOUNT.ADD_BUTTON', 'Add New Account')}
                  onClick={handleNewAccountClick}
                  icon={<PlusIcon />}
                  data-test='addNewAccountBtn'
                />
              </AddButtonWrapper>
            )}
            {isValidElement(extraSection) ? extraSection : null}
          </AccountListStyled>
        )}
      </SelectAccountStyled>

      {error?.length && <SelectAccountError data-test='error-wrapper'>{error}</SelectAccountError>}
    </SelectAccountWrapper>
  )
}

export default SelectAccount
