import styled from 'styled-components'

interface SidebarProps {
  isCompletelyClosed?: boolean
  visible?: boolean
}

export const SidebarRightWrapper = styled.div<SidebarProps>`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background: rgba(0, 0, 0, 0.5);
  opacity: ${props => (props.visible ? '1' : '0')};
  visibility: ${props => (props.visible ? 'visible' : 'hidden')};
  transition:
    opacity 0.3s ease-out,
    visibility 0.3s ease-out;
`

export const SidebarRightContainer = styled.div<SidebarProps>`
  width: 300px;
  height: 100%;
  /* padding: 20px; */
  background: #fff;
  overflow-y: auto;
  box-sizing: border-box;
  transform: ${props => (props.visible ? 'translateX(0)' : 'translateX(100%)')};
  transition: transform 0.3s ease-out;

  &.size-auto {
    width: auto;
  }

  &.size-large {
    width: 400px;
  }

  &.size-small {
    width: 200px;
  }

  &.size-extra-large {
    width: 600px;
  }

  &.centered {
    align-self: center;
  }

  &.horizontal {
    width: 100%;
    height: auto;
  }

  &.transparent {
    background: transparent;
  }

  &.overflow-none {
    overflow: hidden;
  }
`

export const SidebarInnerRightWrapper = styled.div<SidebarProps>`
  max-width: ${({ visible }) => (visible ? '600px' : '0')};

  height: 100%;
  z-index: 2;
  display: flex;
  box-sizing: border-box;
  justify-content: flex-end;
  overflow: hidden;
`

export const SidebarInnerRightContainer = styled.div<SidebarProps>`
  /* min-height: 100vh; */
  height: 100%;
  padding: ${props => (props.isCompletelyClosed ? 0 : '20px')};
  background: #ebeeef;
  box-sizing: border-box;
  transition: transform 0.3s ease-out;

  transform: translateX(${props => (props.visible ? '0' : '100%')});

  &.size-auto {
    width: auto;
  }

  &.size-large {
    width: 400px;
  }

  &.size-small {
    width: 200px;
  }

  &.size-extra-large {
    width: 600px;
  }
`
