import styled from 'styled-components'

export const TierWrapper = styled.div`
  border: 1px solid rgba(191, 200, 217, 1);
  border-radius: 1rem;
  filter: drop-shadow(0px 0px 25px rgba(46, 53, 56, 0.1));

  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: 5px;
  padding: 1rem;
  color: #131e3d;
  .tier {
    font-size: 1.2rem;
    font-style: normal;
    font-weight: 600;
  }
  .icon {
    cursor: pointer;
    margin-left: 5px;
    svg {
      width: 1.6rem;
      height: 1.6rem;
    }
  }

  .tier-date {
    font-size: 1rem;
    font-style: italic;
  }
`
