import styled from 'styled-components'

import { CommonFlexRow } from '../../components/jetonStyled'
import { devices } from '../../constants'

export const CashVoucherContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2.4rem 3.2rem 3.2rem;
  gap: 3.2rem;
  position: relative;
  width: 100%;
  align-items: center;

  .navigation-tabs {
    border-bottom: 1px solid var(--kingdom-cash-content-bottom-border);
    width: 100%;
  }

  .switch-active-tabs {
    display: flex;
    align-items: center;
    gap: 1rem;
    justify-content: center;
    margin-top: 2rem;

    span {
      font-size: 1.6rem;
    }

    .form-switch {
      input:checked + i::after {
        transform: translate3d(calc(-2px + 2.4rem), 2px, 0px);
      }
      input:checked + i {
        background: #3cd1a3;
      }
      i,
      i::before {
        background: #bfc8d9;
      }
    }
  }

  .cash-voucher-content-container {
    display: flex;
    justify-content: center;
    width: 100%;

    .cash-voucher-wrapper {
      display: flex;
      flex-direction: column;
      gap: 3.2rem;
      justify-content: center;
      max-width: 160rem;
      width: 100%;
      align-items: center;

      .success-pane-wrapper {
        max-width: unset;
      }

      .cash-voucher-content {
        width: 100%;
        max-width: 50rem;
        border-radius: 2.4rem;
        box-shadow: var(--kingdom-cash-content-shadow);
        background-color: var(--kingdom-cash-content-background-color);
        transition: all 0.19s linear;

        .cash-voucher-success-pane {
          display: flex;
          flex-direction: column;
          align-items: center;
          .cash-voucher-success-pane-title {
            @extend %body-medium;
          }
          .cash-voucher-success-pane-sub-title {
            @extend %body-regular;
          }
        }

        @media (max-width: 1024px) {
          width: calc(100vw - 6.4rem);
        }
      }
      @media (max-width: 1350px) {
        .deposit-right-side {
          width: 100%;
        }
      }
    }
  }
`

export const CashVoucherLoader = styled.div`
  display: flex;
  width: 100%;
  height: 90vh;
  justify-content: center;
  align-items: center;
`

export const CashVoucherWrapper = styled.div`
  margin-top: 5rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`

type TabProps = {
  active: boolean
}
export const TabsWrapper = styled.div`
  ${CommonFlexRow()}
  justify-content: center;

  & > :first-of-type {
    border-top-left-radius: 1rem;
    border-bottom-left-radius: 1rem;
  }
  & > :last-of-type {
    border-top-right-radius: 1rem;
    border-bottom-right-radius: 1rem;
  }
`
export const Tab = styled.div<TabProps>`
  width: 100%;
  @media ${devices.xl} {
    width: 17.5rem;
  }
  font-size: 1.4rem;
  font-weight: 400;

  background-color: rgba(255, 255, 255, 1);
  color: rgba(19, 30, 61, 1);
  cursor: pointer;

  padding: 1.2rem 0;
  text-align: center;

  @media ${devices.xs} {
    border: 1px solid #edeeef;
  }
  @media ${devices.xl} {
    border: none;
  }

  ${({ active }) =>
    active &&
    `
    color: rgba(0, 102, 255, 1);
    background-color: rgba(0, 102, 255, 0.1);
  `}
`

export const SuccessModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3rem;
  padding: 4rem;
  align-items: center;
  text-align: center;
`
