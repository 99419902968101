import cn from 'classnames'

import { configuration } from 'mmfintech-backend-api'
import { getCurrencyImageUrl } from 'mmfintech-commons'

import { CurrencyIconWrapper } from './CurrencyIcon.styled'

interface CurrencyIconProps {
  currency: string
  size?: string
  circle?: boolean
  className?: string
}

export const CurrencyIcon = ({ currency, size = '3rem', circle, className }: CurrencyIconProps) => (
  <CurrencyIconWrapper size={size} className={cn(className, { circle })}>
    <img src={`${configuration.getBaseUrl()}${getCurrencyImageUrl(currency, 'svg')}`} alt='' />
  </CurrencyIconWrapper>
)
