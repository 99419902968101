import { useContext, useEffect } from 'react'
import toast from 'react-hot-toast'

import { useSendInvitationMutation } from 'mmfintech-backend-api'
import { GlobalContext, tr, useFormValues } from 'mmfintech-commons'
import { Button, ErrorDisplay, Input } from 'mmfintech-portal-commons'
import styled from 'styled-components'

function SendEmailModal() {
  const { modalHide } = useContext(GlobalContext)
  const [sendInvite, { error, reset }] = useSendInvitationMutation()
  const formValues = useFormValues({
    emails: { required: true }
  })

  const onSuccess = () => {
    modalHide()
    toast.remove()
    toast.success('Invite link was sent successfully.')
  }

  const handleSubmit = e => {
    e.preventDefault()
    if (formValues.areValid()) {
      sendInvite(formValues.prepare()).unwrap().then(onSuccess)
    }
  }

  useEffect(() => {
    formValues.handleErrors(error)
    // eslint-disable-next-line
  }, [error])

  return (
    <SendViaEmailWrapper data-test='referral-send-by-email-modal'>
      <SendViaEmailTitle className='title'>{tr('FRONTEND.REFERRAL.SEND.TITLE', 'Send via Email')}</SendViaEmailTitle>
      <SendForm noValidate onSubmit={handleSubmit}>
        <Input
          type='email'
          label={tr('FRONTEND.REFERRAL.SEND.INPUT_LABEL', 'Email')}
          data-test='email'
          required
          error={formValues.getError('emails')}
          {...formValues.registerInput('emails')}
        />

        <ErrorDisplay error={error} />

        <div className='button-wrapper'>
          <Button
            type='submit'
            color='primary'
            data-test='button-submit'
            text={tr('FRONTEND.REFERRAL.SEND.BUTTON', 'Send')}
            disabled={!formValues.getValue('emails')?.length}
          />
          <Button
            color='secondary'
            data-test='button-cancel'
            text='Cancel'
            onClick={() => {
              modalHide()
              reset()
            }}
          />
        </div>
      </SendForm>
    </SendViaEmailWrapper>
  )
}

export default SendEmailModal

const SendViaEmailWrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  min-width: 50rem;
  width: 100%;
  @media (max-width: 565px) {
    max-width: 35rem;
    min-width: 35rem;
    padding: 1rem 0;
  }
`
const SendViaEmailTitle = styled.div`
  font-size: 2.4rem;
  line-height: 2.9rem;
  padding: 3rem 0;

  text-align: center;
  @media (max-width: 565px) {
    font-size: 3rem;
    padding: 3rem;
  }
`
const SendForm = styled.form`
  padding: 3rem;
  .button-wrapper {
    margin: 2rem 0;
    button {
      font-size: 1.6rem;
      font-weight: 600;
      &:last-of-type {
        margin-top: 1rem;
      }
    }
  }
  @media (max-width: 565px) {
    margin: 1rem;
  }
`
