import React from 'react'

import { DisplayActions } from './DisplayActions'

import AgreementIcon from '../../../../images/icons/onboarding/agreement-icon.svg?react'
import JetonBankIcon from '../../../../images/icons/onboarding/jetonbank-icon.svg?react'
import KybIcon from '../../../../images/icons/onboarding/kyb-icon.svg?react'
import VerificationIcon from '../../../../images/icons/onboarding/verification-icon.svg?react'

export const OnboardingList: React.FC = () => (
  <ul>
    {onboardingSteps.map(({ Icon, title, content, step }, index) => (
      <li key={index}>
        <Icon />
        <div className='content'>
          <p>{title}</p>
          {content && <span>{content}</span>}
        </div>
        <DisplayActions step={step} />
      </li>
    ))}
  </ul>
)

//todo localize
const onboardingSteps = [
  {
    Icon: JetonBankIcon,
    title: 'Create company account'
  },
  {
    Icon: KybIcon,
    title: 'Provide company details',
    content: 'Complete the KYB Questionnaire',
    step: 'kyb-questionnaire'
  },
  {
    Icon: VerificationIcon,
    title: 'Verify company details',
    content: 'Complete your account verification',
    step: 'sumsub-onboarding'
  },
  {
    Icon: AgreementIcon,
    title: 'Business agreement',
    content: 'Download and fill in our business agreement',
    step: 'additional-documents'
  }
]
