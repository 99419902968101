import DashboardChart from './DashboardChart'
import { GraphPaneWrapper } from './styled/graphSection.styled'

function GraphSection() {
  return (
    <GraphPaneWrapper>
      <DashboardChart />
    </GraphPaneWrapper>
  )
}

export default GraphSection
