import styled from 'styled-components'

export const ConfirmationTitle = styled.div`
  padding: 2rem 2rem 1.6rem;
  text-align: center;

  font-style: normal;
  font-weight: normal;
  font-size: 2.4rem;
  line-height: 3.6rem;

  @media (min-width: 450px) {
    padding: 4rem 5rem 1.6rem;
  }
`

export const ConfirmationContent = styled.div`
  padding: 0 2rem;
  min-height: 8rem;
  text-align: center;

  @media (min-width: 450px) {
    padding: 0 5rem;
  }
`

export const ConfirmationButtons = styled.div`
  padding: 0.8rem 2rem 2rem;

  display: flex;
  flex-flow: column nowrap;
  width: 100%;

  font-size: 1.5rem;
  font-family: inherit;

  .button {
    :first-of-type {
      margin-bottom: 1rem;
    }
  }

  @media (min-width: 450px) {
    padding: 0.8rem 5rem 4rem;
  }
`
