import styled from 'styled-components'

import { devices } from '../../../constants'
import { CommonPageTitle } from '../../../components/jetonStyled'

export const InvoicesTitle = styled(CommonPageTitle)`
  margin-bottom: 0;
`

export const InvoicesInner = styled.div`
  display: grid;
  align-items: center;
  width: 100%;

  @media ${devices.xs} {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 2rem;
    button {
      max-width: 15rem;
    }
  }
  @media ${devices.xl} {
    grid-template-columns: auto 1fr auto;
  }

  .right-side {
    display: flex;
    flex-flow: row wrap;
    gap: 1rem;
  }
`
