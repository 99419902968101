import { useState } from 'react'

import { tr, useWindowSize } from 'mmfintech-commons'
import { isOwnerOrAdministrator, selectCurrentUserRole } from 'mmfintech-backend-api'

import {
  CommonPageTitle,
  RowsBottomSection,
  RowsMainSection,
  RowsThreeSectionContainer,
  RowsTopSection
} from '../../components/jetonStyled'
import { breakpoints } from '../../constants'
import { useAppSelector } from '../../hooks'
import LimitsTable from './LimitsTable'
import RequestsTable from './RequestsTable'
import { Tab, TabsWrapper } from './styles/Requests.styled'

function Requests() {
  const customerRole = useAppSelector(selectCurrentUserRole)
  const [isRequestsOpen, setIsRequestsOpen] = useState(true)
  const [width] = useWindowSize()
  return (
    <RowsThreeSectionContainer size='lg'>
      {width >= breakpoints.xl && (
        <RowsTopSection>
          <CommonPageTitle centered>{tr('FRONTEND.USERS.EXPENSE_MANAGEMENT.TITLE', 'Requests')}</CommonPageTitle>
        </RowsTopSection>
      )}
      <RowsMainSection>
        {isOwnerOrAdministrator(customerRole) && (
          <TabsWrapper>
            <Tab active={isRequestsOpen} onClick={() => setIsRequestsOpen(true)} data-test='requests-tab'>
              {tr('FRONTEND.USERS.EXPENSE_MANAGEMENT.NAV.EXPENSE_REQUESTS', 'Expense Requests')}
            </Tab>
            <Tab active={!isRequestsOpen} onClick={() => setIsRequestsOpen(false)} data-test='limits-tab'>
              {tr('FRONTEND.USERS.EXPENSE_MANAGEMENT.NAV.LIMITS', 'Limits')}
            </Tab>
          </TabsWrapper>
        )}
      </RowsMainSection>
      <RowsBottomSection>{isRequestsOpen ? <RequestsTable /> : <LimitsTable />}</RowsBottomSection>
    </RowsThreeSectionContainer>
  )
}

export default Requests
