import { formatDate, formatFloat, formatMoney, isValidArray, tr, usePaginationQuery } from 'mmfintech-commons'
import { DataTable, NoElementsMessage, Pagination, Spinner } from 'mmfintech-portal-commons'
import { stakingPeriods, stakingStatuses, useCurrencies, useLazyGetInvestmentsQuery } from 'mmfintech-backend-api'

import { InvestmentsTableHistoryContainer } from './styled/investmentsTableHistory.styled'
import { breakpoints } from '../../constants'
import StatusBanner from '../../components/StatusBanner'
import { CurrencyIcon } from '../../components'

const InvestmentsTableHistory = () => {
  const { getCurrencyPrecision } = useCurrencies()

  const [fetchInvestments, { data: investmentHistory, isFetching }] = useLazyGetInvestmentsQuery()

  const pagination = usePaginationQuery({
    rowsPerPage: 6,
    reload: async (params, onSuccess = null) => {
      const filter = {
        ...params,
        sort: 'id-desc'
      }
      try {
        const response = await fetchInvestments(filter).unwrap()
        if (response) {
          onSuccess(response)
        }
      } catch (_err) {}
    }
  })

  return (
    <InvestmentsTableHistoryContainer>
      {isFetching ? (
        <Spinner />
      ) : isValidArray(investmentHistory?.content) ? (
        <>
          <DataTable.Table scale2={`${breakpoints.xl}px`} skipHover>
            <DataTable.Head>
              <DataTable.Row>
                <DataTable.HeadCell>{tr('FRONTEND.SAVINGS.COLUMNS.HISTORY.END_DATE', 'End Date')}</DataTable.HeadCell>
                <DataTable.HeadCell>{tr('FRONTEND.SAVINGS.COLUMNS.HISTORY.CURRENCY', 'Currency')}</DataTable.HeadCell>
                <DataTable.HeadCell>{tr('FRONTEND.SAVINGS.COLUMNS.HISTORY.AMOUNT', 'Amount')}</DataTable.HeadCell>
                <DataTable.HeadCell>{tr('FRONTEND.SAVINGS.COLUMNS.HISTORY.PERIOD', 'Period')}</DataTable.HeadCell>
                <DataTable.HeadCell>
                  {tr('FRONTEND.SAVINGS.COLUMNS.HISTORY.INTEREST_APY', 'Interest APY')}
                </DataTable.HeadCell>
                <DataTable.HeadCell>{tr('FRONTEND.SAVINGS.COLUMNS.HISTORY.STATUS', 'Status')}</DataTable.HeadCell>
                <DataTable.HeadCell>
                  {tr('FRONTEND.SAVINGS.COLUMNS.HISTORY.AMOUNT_EARNINGS', 'Amount Earnings')}
                </DataTable.HeadCell>
              </DataTable.Row>
            </DataTable.Head>

            <DataTable.Body>
              {investmentHistory?.content?.map(staking => {
                const { endDate, currency, reward, duration, amountStaked, status, amountDue, id } = staking
                const currencyDecimals = getCurrencyPrecision(currency)

                return (
                  <DataTable.Row data-test='table-row' key={id}>
                    <DataTable.Cell
                      caption={tr('FRONTEND.SAVINGS.TABLE.HISTORY.END_DATE_KEY', 'End Date')}
                      data-test='history-end-date'>
                      {formatDate(endDate)}
                    </DataTable.Cell>
                    <DataTable.Cell
                      caption={tr('FRONTEND.SAVINGS.TABLE.HISTORY.ASSET_KEY', 'Currency')}
                      className='no-wrap bold'
                      data-test='history-asset'>
                      <div className='currency-wrapper'>
                        <CurrencyIcon currency={currency} size='3rem' circle />
                        {currency}
                      </div>
                    </DataTable.Cell>
                    <DataTable.Cell
                      caption={tr('FRONTEND.SAVINGS.TABLE.HISTORY.AMOUNT_KEY', 'Amount')}
                      data-test='history-amount'>
                      {formatMoney(amountStaked, currency, currencyDecimals)}
                    </DataTable.Cell>
                    <DataTable.Cell
                      caption={tr('FRONTEND.SAVINGS.TABLE.HISTORY.PERIOD_KEY', 'Period')}
                      className='bold'
                      data-test='history-period'>
                      {`${stakingPeriods[duration]} days`}
                    </DataTable.Cell>
                    <DataTable.Cell
                      caption={tr('FRONTEND.SAVINGS.TABLE.HISTORY.INTEREST_KEY', 'Interest APY')}
                      className='alt-color'
                      data-test='history-interest-apy'>
                      <span className='stakings-apy'>{formatFloat(reward, 2) || 0} %</span>
                    </DataTable.Cell>
                    <DataTable.Cell
                      caption={tr('FRONTEND.SAVINGS.TABLE.HISTORY.STATUS_KEY', 'Status')}
                      className='investment-status'
                      data-test='history-status'>
                      <span>
                        <StatusBanner status={stakingStatuses.getName(status)} />
                      </span>
                    </DataTable.Cell>
                    <DataTable.Cell
                      caption={tr('FRONTEND.STAKING.TABLE.HISTORY.AMOUNT_EARNED_KEY', 'Amount Earnings')}
                      className='bold'
                      data-test='history-amount-earned'>
                      {formatFloat(amountDue - amountStaked, currencyDecimals, currencyDecimals > 1 ? 2 : 0)}
                    </DataTable.Cell>
                  </DataTable.Row>
                )
              })}
            </DataTable.Body>
          </DataTable.Table>
          {investmentHistory && investmentHistory.totalPages > 1 && <Pagination {...pagination.register()} />}
        </>
      ) : (
        <NoElementsMessage
          message={tr('FRONTEND.SAVINGS.TABLE.HISTORY.NO_INVESTMENTS', "You don't have any investments yet")}
        />
      )}
    </InvestmentsTableHistoryContainer>
  )
}

export default InvestmentsTableHistory
