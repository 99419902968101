import styled from 'styled-components'

export const NotOnboardedWrapper = styled.div`
  padding: 4rem;

  .title {
    font-weight: 600;
    font-size: 2.4rem;
    line-height: 150%;
    color: #131e3d;
    display: flex;
    justify-content: center;
  }

  .not-onboarded-text {
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 1.92rem;
    padding: 4rem 0 7.2rem;
  }
`
