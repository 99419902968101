import styled from 'styled-components'

export const SavingsWrapper = styled.div`
    width: 100%;
    max-width: 1400px;
    padding: 5rem 1.5rem 0;

    .title {
        text-align: center;
    }
`
