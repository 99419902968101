import styled from 'styled-components'

import { CommonFlexRow } from '../jetonStyled'

const HeaderIconsWrapper = styled.div`
  ${CommonFlexRow(1.5)}
  align-items: center;
  padding: 0 1rem;
  position: relative;
  border-left: 1px solid #e2e4e9;
  height: 100%;
  flex: 2;
  svg {
    cursor: pointer;
  }
  .settings-wrapper{
    position:relative;
  }
`

const ActivityButton = styled.div`
  flex: 0;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 2rem;

  gap: 1.5rem;

  color: #131e3d;

  border: 1px solid #e9e9e9;
  border-radius: 10px;

  text-align: center;
  font-size: 14px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;

  :hover {
    border: 1px solid #8f96a3;
  }
`

export { HeaderIconsWrapper, ActivityButton }
