import styled from 'styled-components'

export const PlansSectionWrapper = styled.div`
    .available-plans {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
    }
`

export const PlansSectionTitle = styled.h3`
  font-size: 20px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  color: #314787;
  text-transform: capitalize;
`