import { tr } from 'mmfintech-commons'

export const BULK_AMOUNTS = [5, 10, 15, 20, 30, 50, 75, 100, 150, 200]

export const PREPARED_AMOUNTS = [100, 250, 500, 1000]

export const voucherTypes = () => [
  {
    label: tr('FRONTEND.SECURITY.VOUCHER_TYPE_SINGLE_VOUCHER', 'Single Voucher'),
    value: 'single'
  },
  {
    label: tr('FRONTEND.SECURITY.VOUCHER_TYPE_MULTIPLE_VOUCHERS', 'Multiple Vouchers'),
    value: 'bulk'
  }
]
