import { CoreSwitch } from '../../'
import { CardSettingsItemWrapper } from './cardSettings.styled'

interface CardSettingsItemProps {
  icon?: JSX.Element
  title?: string
  subtitle?: string
  clickHandler?: () => void
  toggleValue?: boolean
  toggleHandler?: (checked: boolean) => void
}

const CardSettingsItem = ({
  icon,
  title,
  subtitle,
  toggleValue,
  clickHandler,
  toggleHandler
}: CardSettingsItemProps) => {
  return (
    <div className='card-settings-item-container'>
      <CardSettingsItemWrapper
        className='card-settings-item-wrapper'
        onClick={e => {
          e.stopPropagation()
          clickHandler && clickHandler()
        }}>
        <div className='card-setting-left-section'>
          <div className='card-setting-icon'>{icon}</div>
          <div className='card-setting-text'>
            <div className='card-setting-title'>{title}</div>
            <div className='card-setting-subtitle'>{subtitle}</div>
          </div>
        </div>
        {toggleHandler && (
          <CoreSwitch
            isActive={toggleValue}
            handleSwitch={e => {
              e.stopPropagation()
              toggleHandler(!toggleValue)
            }}
          />
        )}
      </CardSettingsItemWrapper>
    </div>
  )
}

export default CardSettingsItem
