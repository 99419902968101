import { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import cn from 'classnames'
import moment from 'moment/moment'

import settings from '../../settings'
import { breakpoints } from '../../constants'
import { paths, useLazyGetTransactionsQuery, useTransactionColumns } from 'mmfintech-backend-api'
import { fixDateOnly, GlobalContext, isValidArray, tr, usePaginationQuery, useWindowSize } from 'mmfintech-commons'

import Callout, { CalloutPropType } from '../../components/Callout'
import TransactionsTable from './elements/TransactionsTable'
import TransactionsFilter from './elements/TransactionsFilter'
import CustomizeTableModal from './elements/CustomizeTableModal'

import { Button, ErrorDisplay, NoElementsMessage, Pagination, Spinner } from 'mmfintech-portal-commons'
import { SelectionNumber, TransactionsHeader, TransactionsHeaderRight } from './styled/transactions.styled'
import { TransactionsExportButtons } from './elements/TransactionsExportButtons'
import { CommonPageTitle, RowsMainSection, RowsTopSection, RowsTwoSectionContainer } from '../../components/jetonStyled'

import FilterIcon from '../../images/icons/filter-icon.svg?react'
import CustomizeIcon from '../../images/icons/customize-table.svg?react'
import RecipientsIcon from '../../images/icons/recipients.svg?react'

function Transactions({ match }) {
  const accountId = parseInt(match.params.accountId)

  const columns = useTransactionColumns({
    cookieDomain: settings.cookieDomain,
    enableCustomerEmail: true,
    enableFailReason: true,
    defaultVisible: ['id', 'type', 'description', 'reference', 'status', 'customerEmail', 'customerName']
  })

  const { modalShow, modalHide, sidebarInnerRightShow, sidebarInnerRightHide } = useContext(GlobalContext)

  const [fetchTransactions, { transactions, transactionsError, transactionsFetching }] = useLazyGetTransactionsQuery({
    selectFromResult: ({ data, error, isFetching }) => ({
      transactions: isValidArray(data?.content) ? data.content : [],
      transactionsError: error,
      transactionsFetching: isFetching
    })
  })
  const history = useHistory()

  const [width] = useWindowSize()

  // const initialFilter = {
  //   ...(accountId > 0 ? { accountId } : null),
  //   from: moment().add(-1, 'month').toDate(),
  //   to: moment().toDate()
  // }
  const [filter, setFilter] = useState<any>({
    ...(accountId > 0 ? { accountId } : null),
    from: fixDateOnly(moment().add(-1, 'month').toDate()),
    to: fixDateOnly(moment().toDate())
  })

  const pagination = usePaginationQuery({
    rowsPerPage: 10,
    reload: async (params: any, onSuccess = null) => {
      try {
        const response = await fetchTransactions({
          sort: 'reqTime-desc,id-desc',
          ...filter,
          ...params
        }).unwrap()
        onSuccess(response)
      } catch {}
    }
  })

  const applyFilter = (data: any) => {
    setFilter(data)
  }

  const handleRecipientsClick = () => history.push(paths.recipients.list())

  const handleShowFilters = () => {
    modalShow({
      content: <TransactionsFilter initialFilter={filter} applyFilter={applyFilter} modalHide={modalHide} />
    })
  }

  const handleCustomizeClick = () => {
    modalShow({
      options: { closeOnClickOutside: false, size: 'auto' },
      content: <CustomizeTableModal columns={columns} onClose={modalHide} />
    })
  }

  useEffect(() => {
    pagination.reload(filter)
  }, [filter])

  useEffect(() => {
    if (width >= breakpoints.xl) {
      sidebarInnerRightShow({
        content: <TransactionsFilter initialFilter={filter} applyFilter={applyFilter} />,
        options: { size: width > breakpoints['2xl'] ? 'extra-large' : 'large' }
      })
    } else {
      sidebarInnerRightHide()
    }

    return () => {
      sidebarInnerRightHide()
    }
  }, [width])

  const calloutProps: CalloutPropType = {
    isOpen: true,
    position: 'top',
    alignment: 'right',
    arrowOptions: { showArrow: false },
    offset: { x: '3.5rem', y: '0rem' },
    content: columns.isModified() ? <SelectionNumber>{columns.visible.length}</SelectionNumber> : null
  }

  return (
    <>
      <RowsTwoSectionContainer size='lg'>
        <RowsTopSection>
          <TransactionsHeader>
            {width >= breakpoints.xl && (
              <CommonPageTitle className='title' centered>
                {tr('FRONTEND.TRANSACTIONS.TITLE', 'Transactions')}
              </CommonPageTitle>
            )}
            <TransactionsHeaderRight>
              <div className='buttons-container'>
                <Button
                  color='round-secondary'
                  icon={<RecipientsIcon />}
                  text={tr('FRONTEND.TRANSACTIONS.RECIPIENTS_BUTTON', 'Recipients')}
                  onClick={handleRecipientsClick}
                  data-test='button-deposit'
                />
                <Button
                  type='button'
                  color='round-secondary'
                  onClick={handleCustomizeClick}
                  className={cn({ modified: columns.isModified() })}
                  text={
                    <Callout {...calloutProps}>
                      {tr('FRONTEND.TRANSACTIONS.COLUMNS.CUSTOMIZE_BUTTON', 'Customize')}
                    </Callout>
                  }
                  data-test='customize-columns'
                  icon={<CustomizeIcon />}
                />
              </div>
              {width < breakpoints.xl && (
                <div className='filter-icon' onClick={handleShowFilters}>
                  <FilterIcon />
                </div>
              )}
            </TransactionsHeaderRight>
          </TransactionsHeader>
        </RowsTopSection>
        <RowsMainSection>
          {transactionsFetching ? (
            <Spinner />
          ) : transactionsError ? (
            <ErrorDisplay error={transactionsError} />
          ) : isValidArray(transactions) ? (
            <>
              <TransactionsTable columns={columns} transactions={transactions} />
              <div className='pagination-buttons-container'>
                <Pagination className='transactions-pagination' {...pagination.register()} />
                <TransactionsExportButtons
                  accountId={Number(filter.accountId)}
                  from={new Date(filter.from)}
                  to={new Date(filter.to)}
                />
              </div>
            </>
          ) : (
            <NoElementsMessage
              message={tr(
                'FRONTEND.TRANSACTIONS.NO_TRANSACTIONS',
                'No transactions for the selected period or search criteria'
              )}
            />
          )}
        </RowsMainSection>
      </RowsTwoSectionContainer>
    </>
  )
}

export default Transactions
