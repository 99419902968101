import styled from 'styled-components'

import { CommonFlexColumn, CommonFlexRow } from '../../../components/jetonStyled'
import { breakpoints, devices } from '../../../constants'

export const ReferralMainSection = styled.div`
  @media ${devices.xs} {
    ${CommonFlexRow(2)}
    flex-wrap: wrap;
  }

  @media ${devices.xl} {
    display: grid;
    grid-template-columns: 20rem 85rem 4rem;
    grid-column-gap: 2rem;
    max-height: 4rem;
  }
  @media ${devices['2xl']} {
    grid-template-columns: 20rem 110rem 4rem;
  }

  .mid-section {
    @media ${devices.xs} {
      ${CommonFlexColumn(2)}
    }
    @media (min-width: ${breakpoints.xs + 1}px) {
      ${CommonFlexRow(2)}
      align-items: center;
      justify-content: space-between;
    }

    button {
      max-height: 4rem;
      border: 1px solid #dcdfe5;
    }
    .qualify-button {
      background-color: rgba(255, 255, 255, 1);
      max-width: 14rem;
      color: rgba(0, 0, 0, 1);
      box-shadow: none;

      font-size: 1.2rem;
      font-weight: 400;

      padding: 1.6rem;
      svg {
        width: 1rem;
        height: 1rem;
      }
    }
    .key-button {
      height: 100%;
      width: 14rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-flow: row nowrap;

      cursor: pointer;
      padding: 0 1.4rem;

      background-color: #ffffff;
      border-radius: 1rem;
      border: 1px solid #dcdfe5;

      white-space: nowrap;

      span {
        color: #131e3d;
        font-size: 1.2rem;
        font-weight: 400;
        line-height: 1.5rem;
      }
    }
  }
`

export const ReferralBottomSection = styled.div`
  @media ${devices.xs} {
    ${CommonFlexColumn(4)}
  }
  @media ${devices.xl} {
    display: grid;
    grid-template-columns: 20rem 85rem 4rem;
    grid-column-gap: 2rem;
  }
  @media ${devices['2xl']} {
    grid-template-columns: 20rem 110rem 4rem;
  }
  .left-column {
    display: flex;
    flex-wrap: wrap;
    gap: 3rem;
    @media ${devices.xl} {
      ${CommonFlexColumn(3)}
    }
  }
  .tooltip-wrapper {
    padding: 2rem;
    border-radius: 1rem;
    h3 {
      margin: 0 0 2.5rem 0;
    }
    .tiers-container {
      ${CommonFlexColumn(1)}
      .tier {
        ${CommonFlexRow(1)}
        align-items: center;
        color: #fff;
        font-size: 1.2rem;
        .tier-number {
          border-radius: 5px;
          padding: 6px 10px;
          background-color: #3cd1a3;
        }
        .qualifier {
          padding: 0 0.5rem;
          border-right: 1px solid #fff;
        }
      }
    }
  }
`

export const BonusCurrencyWrapper = styled.div`
  ${CommonFlexColumn(2)}
  width: 100%;
  .inner {
    ${CommonFlexColumn(2)}
  }
  .section-heading {
    color: #131e3d;
    @media ${devices.xs} {
      font-size: 1.2rem;
      font-weight: 500;
    }
    @media ${devices.xl} {
      font-size: 1.6rem;
      font-weight: 500;
    }
  }
`

export const BonusCurrencyOption = styled.div`
  background-color: #ffffff;
  border-radius: 1rem;
  padding: 2rem;
  width: 100%;
  border: 1px solid #dcdfe5;

  @media ${devices.md} {
    width: 20rem;
  }

  cursor: pointer;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;

  color: #131e3d;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.5rem;

  .main {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    gap: 1rem;

    .cont {
      display: flex;
      flex-flow: column nowrap;
      gap: 3px;
      font-size: 1.2rem;
      font-weight: 700;

      .currency {
        color: #85a0ad;
        font-size: 1.4rem;
        font-weight: 400;
      }
    }
  }

  .radio {
    width: 20px;
    height: 20px;
    border: 1px solid rgba(133, 160, 173, 0.3);
    border-radius: 50%;
  }

  &.selected {
    border: 2px solid #3cd1a3;

    .radio {
      position: relative;
      border-color: rgba(220, 223, 229, 1);
      :after {
        content: ' ';
        width: 10px;
        height: 10px;
        background-color: #3cd1a3;
        border-radius: 50%;
        position: absolute;
        left: 50%;
        top: 50%;
        margin-top: -5px;
        margin-left: -5px;
      }
    }
  }

  &.disabled {
    opacity: 0.3;
    cursor: not-allowed;
  }
`

export const SocialMediaShare = styled.div`
  ${CommonFlexColumn(2)}
  @media ${devices.xs} {
    padding: 3rem;
    align-items: center;
  }

  @media ${devices.xl} {
    padding: 0;
    align-items: flex-start;
  }

  .section-heading {
    @media ${devices.xs} {
      font-size: 2.4rem;
      color: #131e3d;
      font-size: 600;
    }
    @media ${devices.xl} {
      color: rgba(143, 150, 163, 1);
      font-size: 1.2rem;
      font-weight: 400;
      white-space: nowrap;
    }
  }
  .inner {
    @media ${devices.xs} {
      ${CommonFlexRow(2)}
    }
    @media ${devices.xl} {
      ${CommonFlexColumn(2)}
    }

    a {
      @media ${devices.xs} {
        border: 1px solid rgba(191, 200, 217, 1);
        border-radius: 5px;
      }
      @media ${devices.xl} {
        border-radius: none;
        border: none;
      }
    }
  }
`
