import { tr, useWindowSize } from 'mmfintech-commons'

import InvestmentsSection from './InvestmentsSection'
import PlansSection from './PlansSection'
import { SavingsWrapper } from './styled/savings.styled'
import { CommonPageTitle } from '../../components/jetonStyled'
import { breakpoints } from '../../constants'

const Savings = () => {

    const [width] = useWindowSize()


    return (
        <SavingsWrapper>
            {width >= breakpoints.xl && (
                <CommonPageTitle className='title'>{tr('FRONTEND.SAVINGS.TITLE', 'Savings')}</CommonPageTitle>
            )}
            <PlansSection />
            <InvestmentsSection />
        </SavingsWrapper>
    )
}

export default Savings