import { extractCurrencyCode, formatMoney, isValidArray, tr } from 'mmfintech-commons'

import { AccountSectionWrapper, BalanceContainer } from './styled/accountSection.styled'

const AccountSection = ({ aggregatedBalance }) =>
  isValidArray(aggregatedBalance) ? (
    <AccountSectionWrapper>
      <BalanceContainer>
        <div className='label'>{tr('FRONTEND.DASHBOARD.ACCOUNT_SELECT.TOTAL_LABEL', 'Total account value')}</div>
        <div className='balance' data-test='balance'>
          {formatMoney(
            aggregatedBalance[aggregatedBalance.length - 1].amount,
            extractCurrencyCode(aggregatedBalance[aggregatedBalance.length - 1])
          )}
        </div>
      </BalanceContainer>
    </AccountSectionWrapper>
  ) : null

export default AccountSection
