import styled from 'styled-components'

export const SwitchWrapper = styled.div`
  width: 58px;
  height: 32px;
  padding: 0px 2px 0px 2px;
  gap: 10px;
  border-radius: 30px;
  transition: all 0.5s ease-in-out;
  position: relative;
  background-color: #d8d9dc;
  transition: background-color 0.5s ease-in-out;
  &.active {
    background-color: #3dd394;
  }

  .switch-ball {
    background-color: #f7f8fa;
    box-shadow: -1px -1px 1px 0px #ffffff1a, 2px 2px 2px -1px #1e293b1a, -1px -1px 2px 0px #ffffff80 inset,
      1px 1px 1px 0px #00000008 inset;
    width: 28px;
    height: 28px;
    border-radius: 50%;
    position: absolute;
    top: 2px;
    transition: transform 0.5s ease-in-out;
    &.active {
      transform: translateX(26px);
    }
  }
`
