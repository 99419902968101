import styled from 'styled-components'

export const GoogleAuthenticatorInfoModalStyled = styled.div`
  padding: 40px;

  .google-authenticator-modal-actions {
    margin: 0 auto;
    margin-top: 40px;
    display: grid;
    gap: 10px;
    max-width: 420px;
  }
`
