import styled from 'styled-components'

export const TableCustomizeWrapper = styled.div`
  width: 92vw;
  max-width: 50rem;

  background: #ebeeef;
  border-radius: 8px;
  box-shadow: 0 0 25px rgba(46, 53, 56, 0.1);

  padding: 3rem 2rem;
  @media (min-width: 640px) {
    padding: 4rem 5rem;
  }

  display: flex;
  flex-flow: column nowrap;
  gap: 3rem;
`

export const TableCustomizeButtons = styled.div`
  width: 100%;
  display: flex;
  flex-flow: column nowrap;

  .button {
    font-size: 1.6rem;
    font-weight: 600;
    line-height: 1.9rem;
  }
`
