import styled from 'styled-components'

export const GoogleAuthenticatorWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;

  .google-authenticator-container {
    max-width: 520px;
    display: grid;
    grid-gap: 32px;

    .description {
      font-weight: 400;
      font-size: 12px;
      color: #131e3d;
      display: grid;
      grid-gap: 16px;
    }

    .qr-code-wrapper {
      display: flex;
      justify-content: center;

      .qr-code {
        font-weight: 400;
        font-size: 12px;
        border: 1px solid #cacccf;
        padding: 24px 24px 16px 24px;
        border-radius: 16px;
        color: #131e3d;
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: 360px;

        svg {
          margin-bottom: 24px;
        }

        .secret {
          font-weight: 400;
          font-size: 14px;
          color: #131e3d;
          display: flex;
          gap: 8px;
          margin-top: 8px;

          .copy {
            cursor: pointer;
            svg:active {
              rect {
                fill: red;
                stroke: red;
              }
              filter: red;
            }
          }

          span {
            font-weight: 700;
            overflow-wrap: anywhere;
            text-align: justify;
          }
        }
      }
    }
    .code-input-container {
      font-weight: 400;
      font-size: 12px;
      text-align: center;
    }
  }
`
