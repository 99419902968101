import { devices } from '../../constants'

export const tablePadding = `
.data-table-cell,
.data-table-head-cell {
  //padding-left: 1rem;
  padding-right: 1rem;
}
@media ${devices.xs}{
  .data-table{
    .data-table-body{
      .data-table-row{
        padding:0
      }
    }
  }
}
@media ${devices.xl} {
    .data-table-container {
      background: transparent;
    }

    .data-table {
      .data-table-head {
        .data-table-row {
          border-bottom: none;
          .data-table-head-cell {
            :first-of-type {
              padding-left: 3rem;
            }
          }
        }
      }
    }

    .data-table-body {
      .data-table-row {
        padding: 2rem 3rem;
        .data-table-cell {
          &:first-of-type {
            padding-left: 3rem;
          }
          &:last-of-type {
            padding-right: 2rem;
          }
        }
      }
    }
  }`
