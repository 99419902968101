import styled from 'styled-components'

import { CommonFlexColumn, CommonPageTitle } from '../jetonStyled'

export const AccessDeniedContainer = styled.div`
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0 0 25px rgba(46, 53, 56, 0.1);

  max-height: 23rem;
  max-width: 35rem;

  margin: 6rem auto 0 auto;
  padding: 2rem;

  ${CommonFlexColumn()}
  justify-content: center;
  align-items: center;

  @media (min-width: 500px) {
    max-width: 50rem;
  }
`

export const AccessDeniedContent = styled.div`
  margin: 0 auto;
  text-align: center;

  padding: 0 2rem;

  .button-back {
    max-width: 40rem;
    margin-top: 2rem;

    font-size: 1.8rem;
  }
`

export const AccessDeniedTitle = styled(CommonPageTitle)``

export const AccessDeniedSubtitle = styled.h2`
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 2.2rem;
`
