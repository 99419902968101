import { useContext } from 'react'

import { configuration } from 'mmfintech-backend-api'
import { GlobalContext, tr } from 'mmfintech-commons'
import { Button } from 'mmfintech-portal-commons'

import settings from '../../settings'
import ManageCookies from './ManageCookies'
import { CookieConsentWrap } from './styled/cookieConsent.styled'

import CookieImage from '../../images/icons/cookie.svg'

function CookieConsent({ setCookie }) {
  const { modalShow } = useContext(GlobalContext)

  const handleManageCookieConsent = () => {
    modalShow({
      options: { size: 'medium' },
      content: <ManageCookies setCookie={setCookie} />
    })
  }

  return (
    <CookieConsentWrap>
      <div className='consent-container'>
        <div className='top-part'>
          <div className='top-content'>
            <img src={CookieImage} alt='Cookie consent' />
            <span>
              {tr(
                'FRONTEND.COOKIE_CONSENT.PERSONALISED_XP',
                'We use cookies to personalize your experience on Jetonbank portal. They are all about to personalize content and ads, provide social media features and to analyze our traffic.'
              )}
            </span>
          </div>

          <div className='top-buttons'>
            <Button
              type='button'
              color='primary'
              className='btn-manage'
              text={tr('FRONTEND.COOKIE_CONSENT.MANAGE_COOKIES', 'Manage Cookies')}
              onClick={handleManageCookieConsent}
              data-test='button-manage-cookies'
            />

            <Button
              type='button'
              color='primary'
              className='btn-accept'
              text={tr('FRONTEND.COOKIE_CONSENT.ACCEPT_ALL', 'Accept All')}
              data-test='button-accept-all'
              onClick={() => {
                setCookie(
                  'cookie.consent',
                  JSON.stringify({
                    necessaryCookies: true,
                    optionalCookies: true
                  }),
                  {
                    days: 365,
                    maxAge: 31536000,
                    domain: configuration.isLocal() ? null : settings.cookieDomain
                  }
                )
              }}
            />
          </div>
        </div>
      </div>
    </CookieConsentWrap>
  )
}

export default CookieConsent
