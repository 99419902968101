import styled from 'styled-components'

import { CommonFlexColumn, CommonFlexRow } from '../jetonStyled'

const ActivitiesMenuWrapper = styled.div`
  background: #ffffff;
  height: 100%;

  padding: 1.5rem 0;
  overflow-y: hidden;

  .top-section {
    padding: 0 2rem;
    ${CommonFlexRow()}
    align-items: center;
    justify-content: space-between;
    h3 {
      color: #131e3d;
      font-size: 1.8rem;
      font-family: Roboto;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      text-transform: capitalize;
    }
    svg {
      cursor: pointer;
    }
  }
  .react-datepicker-wrapper,
  .react-datepicker-popper {
    padding: 0 2rem;
  }

  .activity-list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    border: 0 solid rgba(226, 228, 233, 1);
    border-width: 1px 0 0 1px;
  }
  .no-activities {
    margin: 3rem 0;
    ${CommonFlexRow(2)}
    align-items: center;
    justify-content: center;

    color: #131e3d;
    font-weight: 500;
    font-size: 1.4rem;
    line-height: 1.7rem;
  }
`
const ActivityColumn = styled.div`
  ${CommonFlexColumn(2)}
  padding: 2rem;
  border: 0 solid rgba(226, 228, 233, 1);
  border-width: 0 1px 1px 0;

  .column-header {
    ${CommonFlexRow(1)}
    align-items: center;
  }
`

const CategoryTitle = styled.h3`
  font-size: 1.8rem;
  font-weight: 600;
  color: ${props => props.color};
`

const ActivityWrapper = styled.div`
  ${CommonFlexRow(1)}
  align-items: center;
  cursor: pointer;

  .activity-title {
    color: #131e3d;
    font-weight: 400;
    font-size: 1.4rem;
  }
  .timestamp {
    max-width: 5rem;
    text-align: center;
    border: 1px solid #d3d9de;
    border-radius: 4px;
    color: #8595a3;
    font-weight: 400;
    font-size: 1rem;
    padding: 0.5rem;
  }
`

export { ActivitiesMenuWrapper, ActivityWrapper, CategoryTitle, ActivityColumn }
