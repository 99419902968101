import { useEffect, useState } from 'react'

import { CardPodium } from '../../components'

import { useGetAllCardsQuery } from 'mmfintech-backend-api'
import { fixDateOnly, isValidArray, monthBackDate, tr } from 'mmfintech-commons'
import { Spinner } from 'mmfintech-portal-commons'

import InactiveCard from './inactiveCard'
import MissingCard from './missingCard'
import TransactionsSection from '../dashboard/elements/TransactionsSection'
import { CardsScreenWrapper } from './cards.styled'

const Card = () => {
  const { data: prepaidCards, isLoading } = useGetAllCardsQuery(null)
  const [selectedCardType, setSelectedCardType] = useState<'virtual' | 'physical'>('virtual')

  const selectedCardDetails = () => {
    return prepaidCards.find(card => card.isVirtual == (selectedCardType == 'virtual'))
  }

  useEffect(() => {
    if (isValidArray(prepaidCards)) {
      const hasVirtual = prepaidCards.find(card => !!card.isVirtual)
      const hasPhysical = prepaidCards.find(card => !card.isVirtual)

      if (hasVirtual && !hasPhysical) {
        setSelectedCardType('virtual')
      }
    }
  }, [prepaidCards])

  return (
    <CardsScreenWrapper>
      {isLoading ? (
        <Spinner />
      ) : isValidArray(prepaidCards) ? (
        <div className='cards-screen-container'>
          <div className='cards-screen-left-section'>
            <CardPodium
              prepaidCards={prepaidCards}
              selectedTab={selectedCardType}
              setSelectedTab={setSelectedCardType}
            />
            {!!selectedCardDetails() ? (
              <TransactionsSection
                filter={{
                  accountId: selectedCardDetails()?.accountId,
                  from: fixDateOnly(monthBackDate()),
                  to: fixDateOnly(new Date()),
                  page: 0,
                  size: 5,
                  sort: 'reqTime-desc,id-desc'
                }}
                title={tr('FRONTEND.ACCOUNTS.TRANSACTIONS.TITLE', 'Recent transactions')}
              />
            ) : (
              <InactiveCard cardType={selectedCardType} />
            )}
          </div>
        </div>
      ) : (
        <MissingCard />
      )}
    </CardsScreenWrapper>
  )
}

export default Card
