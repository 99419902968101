import { tr } from 'mmfintech-commons'

import TransactionsTable from '../../transactions/TransactionsTable'
import { TransactionsSectionTitle, TransactionsSectionWrapper } from './styled/transactionsSection.styled'

function TransactionsSection({ title = '', filter = {}, showPagination = false, forceReload = false }) {
  return (
    <TransactionsSectionWrapper>
      <TransactionsSectionTitle>
        {title || tr('FRONTEND.DASHBOARD.TRANSACTIONS_TITLE', 'Recent transactions')}
      </TransactionsSectionTitle>
      <TransactionsTable filter={filter} showPagination={showPagination} forceReload={forceReload} />
    </TransactionsSectionWrapper>
  )
}

export default TransactionsSection
