import { useContext } from 'react'
import toast from 'react-hot-toast'
import { useHistory } from 'react-router-dom'

import { paths, useTerminateCardMutation } from 'mmfintech-backend-api'
import { GlobalContext, OtpContext, tr, translateError } from 'mmfintech-commons'
import { Button, ErrorDisplay } from 'mmfintech-portal-commons'
import { TerminateCardWrapper } from './cardSettingsModals.styled'

interface TerminateCardProps {
  prepaidCardId?: string
}

const TerminateCard = ({ prepaidCardId }: TerminateCardProps) => {
  const history = useHistory()
  const { modalHide } = useContext(GlobalContext)

  const { setOtpOnSuccess } = useContext(OtpContext)

  const [terminateCard, { isLoading: isTerminating, error: terminationError }] = useTerminateCardMutation()

  const handleTerminateCard = async () => {
    if (prepaidCardId) {
      setOtpOnSuccess(() => result => {
        if (result) {
          toast.success(tr('FRONTEND.CARDS.SETTINGS.TERMINATE_CARD.MODAL.SUCCESS', 'Card terminated successfully!'))
          return history.push(paths.dashboard())
        }
      })
      try {
        const result = await terminateCard({
          prepaidCardId
        }).unwrap()
        if (!result.challenge) {
          toast.success(tr('FRONTEND.CARDS.SETTINGS.TERMINATE_CARD.MODAL.SUCCESS', 'Card terminated successfully!'))
          return history.push(paths.dashboard())
        }
      } catch (error) {
        const message = translateError(error)
        if (message) {
          toast.remove()
          toast.error(message)
        }
      }
    }
  }

  return (
    <TerminateCardWrapper>
      <span className='title'>{tr('FRONTEND.CARDS.SETTINGS.MODAL.TERMINATE_CARD.TITLE', 'Terminate card')}</span>
      <div className='terminate-card-text'>
        {tr(
          'FRONTEND.CARDS.SETTINGS.TERMINATE_CARD.MODAL.TEXT',
          'This action is irreversible and will prevent the future use of the card. If you have available balance, it will be automatically transferred to an account with the same currency. No fees will be applied for this transfer. Do you wish to proceed?'
        )}
      </div>
      <ErrorDisplay error={terminationError} />
      <Button
        type='button'
        loading={isTerminating}
        text={tr('FRONTEND.CARDS.SETTINGS.TERMINATE_CARD.MODAL.TERMINATE_BUTTON', 'Terminate card')}
        onClick={() => handleTerminateCard()}
      />
      <Button
        className='cancel-button'
        text={tr('FRONTEND.BUTTONS.CANCEL', 'Cancel')}
        onClick={() => modalHide()}
        color='secondary'
      />
    </TerminateCardWrapper>
  )
}

export default TerminateCard
