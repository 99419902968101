import { useContext, useEffect } from 'react'
import toast from 'react-hot-toast'

import { useAccountEditQuery } from 'mmfintech-backend-api'
import { GlobalContext, tr } from 'mmfintech-commons'
import { Button, ErrorDisplay, Input } from 'mmfintech-portal-commons'

import {
  AccountCreateButtons,
  AccountCreateForm,
  AccountCreateTitle,
  AccountCreateWrapper
} from './styled/accountCreateModal.styled'

function AccountEditModal({ account, onSuccess }) {
  const { modalHide } = useContext(GlobalContext)

  const { id: accountId, name: accountName } = account || {}

  const handleSuccessfulChange = () => {
    toast.remove()
    toast.success('Account successfully changed')
    onSuccess()
    modalHide()
  }

  const { formValues, error, loading, handleSubmit, resetMutation } = useAccountEditQuery({
    accountId,
    accountName,
    onSuccess: handleSuccessfulChange
  })

  useEffect(() => {
    formValues.handleErrors(error)
    // eslint-disable-next-line
  }, [error])

  return (
    <AccountCreateWrapper data-test='create-account-modal'>
      <AccountCreateTitle className='title mb-2'>{'Edit Account' /* todo */}</AccountCreateTitle>

      <AccountCreateForm noValidate>
        <Input
          type='text'
          data-test='account-name'
          label={tr('FRONTEND.ACCOUNTS.CREATE.NAME_LABEL', 'Account Name')}
          {...formValues.registerInput('name')}
          autoFocus
        />

        <ErrorDisplay error={error} />

        <AccountCreateButtons>
          <Button
            type='button'
            color='primary'
            text={tr('FRONTEND.BUTTONS.SAVE', 'Save')}
            loading={loading}
            data-test='button-submit'
            onClick={handleSubmit}
          />

          <Button
            type='button'
            color='secondary'
            text={tr('FRONTEND.BUTTONS.CANCEL', 'Cancel')}
            disabled={loading}
            data-test='button-cancel'
            onClick={() => {
              modalHide()
              resetMutation()
            }}
          />
        </AccountCreateButtons>
      </AccountCreateForm>
    </AccountCreateWrapper>
  )
}

export default AccountEditModal
