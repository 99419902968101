import styled from 'styled-components'

import { CommonFlexRow } from '../../../../components/jetonStyled'

const ConversationsBannerWrapper = styled.div`
  cursor: pointer;
  border-left: 1px solid #e2e4e9;

  padding: 1rem 2rem;

  display: flex;
  flex-flow: column nowrap;
  justify-content: center;

  .badge-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;
  }
  .badge {
    ${CommonFlexRow(0.5)}
    align-items: center;
    border-radius: 2rem;
    background-color: #fee7e7; // TODO: should have different colors
    color: rgba(247, 59, 59, 1); // TODO: should have different colors
    font-weight: 700;
    font-size: 1.4rem;
    padding: 0.6rem 1rem;
    white-space: nowrap;
  }

  .text {
    color: rgba(19, 30, 61, 1);
    font-size: 1.4rem;
    text-transform: capitalize;
    padding: 0.3rem 1rem;
  }

  @media (max-width: 1280px) {
    flex: 1;
  }
`

export { ConversationsBannerWrapper }
