import styled from 'styled-components'

export const StepperWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;

  div:not(:last-child) {
    .--step {
      ::after {
        position: absolute;
        content: '';
        width: 2px;
        height: 60px;
        transform: rotate(90deg);
        right: -32px;
        background-color: #c2c9d6;
      }
    }
  }

  div:last-child {
    .--step {
      margin-right: 0;
    }
  }

  .single-step-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    .--step {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 3.7rem;
      height: 3.7rem;
      border-radius: 50%;
      border: 1.5px solid #c2c9d6;
      margin-right: 60px;
      position: relative;
    }

    .--label {
      font-size: 11.8px;
      font-weight: 400;
      line-height: 13.82px;
      text-align: center;
      color: #c2c9d6;
    }
    &.active {
      .--step {
        border: 1.5px solid #0066ff;
        width: 4.6rem;
        height: 4.6rem;
        color: #0066ff;
      }
      .--label {
        color: #0066ff;
      }
    }
  }
`
